import Vue from 'vue';
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { $store } from "@/main";
import { DailyAllocation, ISimulation, ScreenshotDetail, SimulationControl } from "@/types";
import { get } from "lodash-es";

interface OverviewState {
  simulations: Record<string, null | ISimulation>;
  showAdvancedSimulation: boolean;
  showSimulationAssumptions: boolean;
  simulationAssumptionsContent: string;
  simulationQuery: Record<string, any>;
  isSimulationReactive: boolean;
  benefits: any[];
  summary: any[];
  summaryTopSection: any[];
  summaryBellCurveChart: any[];
  summarySimulationChart: any[];
  summaryFooter: any[];
  simulationControls: SimulationControl[];
  simulationSetIndex: number | string;
  simulationSetIndexDefault: number | string;
  ssDetails: ScreenshotDetail[];
  ssHeading: any;
  activeSsDetailIndex: null | number;
}

@Module({ namespaced: true, store: $store, name: 'overview' })
export default class OverviewStore extends VuexModule implements OverviewState{
  simulations: Record<string, null | ISimulation> = {
    good: null,
    bad: null,
    current: null
  };
  showAdvancedSimulation = false;
  showSimulationAssumptions = false;
  simulationAssumptionsContent = '';
  simulationQuery = {};
  isSimulationReactive = true;
  summary: any[] = [];
  summaryTopSection: any[] = [];
  summaryBellCurveChart: any[] = [];
  summarySimulationChart: any[] = [];
  summaryFooter: any[] = [];
  benefits: any[] = [];
  simulationControls: SimulationControl[] = [];
  simulationSetIndex = '';
  simulationSetIndexDefault = '';
  ssDetails: ScreenshotDetail[] = [];
  ssHeading: any;
  activeSsDetailIndex: null | number = null;

  get activeSsDetail(): ScreenshotDetail | null {
    return this.ssDetails.find((_, index) => index === this.activeSsDetailIndex) || null;
  }

  get dailyAllocations(): DailyAllocation[] {
    return this.simulations.current?.daily_allocations || [];
  }

  @Mutation
  setCurrentSimulation(value: ISimulation): void {
    this.simulations.current = value;
  }

  @Mutation
  setSimulations(data: Record<string, any>[]): void {
    this.simulations.good = get(data, [0, 'good_simulation']) || null;
    this.simulations.bad = get(data, [0, 'bad_simulation']) || null;
  }

  @Mutation
  setProp({ prop, value }: { prop: keyof OverviewState; value: any }): void {
    Vue.set(this, prop, value);
  }

  @Mutation
  setState(data: Partial<OverviewState>): void {
    Object.assign(this, data);
  }
}
