import { Action, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { $store, $http } from "@/main";
import { camelCase, get, pick } from 'lodash-es';
import { IGoogleUserData, IUser } from "@/types";
import Vue from "vue";
import { Flux, fromUserData, stateMapper, stateReducer, storeDefaults } from "@/helpers";
import { User } from "@/types/User";
import Crypto from 'crypto-js';
import { ls } from "@/store/index";

export interface Credentials {
  username: string;
  password: string;
}
const defaultAuth = {
  username: 'noreply@raventrading.io',
  password: 'NpE%2C0K&t4S4dQ'
};
const fetchAuth = async(auth: Credentials): Promise<{ token: string; auth: { username: string; password: string } }> => {
  const headers = new Headers();
  if (!auth) {
    return fetchAuth(defaultAuth);
  }
  headers.append('Authorization', 'Basic ' + btoa(auth.username + ':' + auth.password ));
  const response = fetch('api/token', { method: 'GET', headers })
    .then(r => r.json())
    .then(r => ({ ...r, auth }));

  return response;

  
};

const store = new Flux() as { commit: any; dispatch: any };

@Module({ namespaced: true, store: $store, name: 'auth' })
export default class AuthStore extends VuexModule {
  user: User | null = null;
  isReady = false;
  requirePassword = false;
  hasRenderedLogin = false;
  auth = null as null | Partial<Credentials>;
  token = '';
  users: any[] = [];
  userAccountMessage:any =  undefined;
  selectedUser = '';
  showSuccessModal = false;

  get isLoggedIn(): boolean {
    const username = get(this, ['auth', 'username']);
    return  (this.user && username && username !== 'noreply@raventrading.io') ? true: false;
  }

  get hasToken(): boolean {
    return !!this.token;
  }

  get isGoogleUser(): boolean {
    return this.user?.isGoogleUser || false;
  }

  get isAdmin(): boolean {
    return this.user?.isAdmin || false;
  }

  get username(): string {
    return get(this.user, 'username') || '';
  }

  get password(): string {
    return get(this.user, 'password') || '';
  }

  @Action
  async loadUserSettings(email = this.username): Promise<void> {
    return this.isLoggedIn
      ? $http.get(
        'api/user_settings',
        { params: { email } }
      ).then(r => {
        let state = get(r, 'data');
        if (state.data) {
          state = JSON.parse(Crypto.AES.decrypt(
            state.data,
            email
            ).toString(Crypto.enc.Utf8));
          }
          if (state?.trade) {
          this.context.dispatch('setState', stateMapper(pick(state, ['trade'])), { root: true }).then();
        }
      })
        .catch(() => Promise.resolve())
      : Promise.resolve();
  }

  @Mutation
  selectUser(username: string): void {
    this.selectedUser = username;
  }

  @Action
  async fetchUsers(): Promise<any> {
    return $http
      ? $http.get('api/users').then(({ data }) => {
        this.context.commit('setProp', {
          prop: 'users',
          value: data
        });
      }).catch(() => Promise.resolve())
      : this.context.dispatch('fetchUsers');
  }

  @Action
  async saveUserSettings({ settings, hasRecord }: {
    settings: string;
    hasRecord?: any;
  }): Promise<any> {
    if (hasRecord !== false) {
      hasRecord = true;
    }
    return this.user
      ? $http[hasRecord ? 'put' : 'post']( `api/user_settings/${this.selectedUser || this.username}`, {
          data: Crypto.AES.encrypt(settings, this.selectedUser || this.username).toString() })
      : Promise.resolve();
  }

  @Action
  async fetchUser(username = ''): Promise<any> {
    if (username || this.isLoggedIn) {
      return Promise.resolve($http.get(
        'api/users',
        { params: { email: username || this.username } }
      )
        .then(r => get(r, 'data[0]') || null)
        .catch(() => Promise.resolve()));
    }
    return Promise.resolve(null);
  }

  @Action
  async loadUser(): Promise<any> {
    return (this.auth?.username !== defaultAuth.username
      ? this.context.dispatch('fetchUser', this.auth?.username)
        .then(r => {
          if (r && r.email !== defaultAuth.username) {
            if (this.user?.isGoogleUser) {
              this.user.updateData(r);
            } else {
              this.context.commit('setUser', r);
            }
            this.context.dispatch('loadUserSettings').then();
          }
        })
      : Promise.resolve()
    )
      .then(() => setTimeout(
        () => this.context.commit('setProp', { prop: 'isReady', value: true }),
        1e3)
    );
  }
  @Action
  async checkGoogleProfile(): Promise<any> {
    // (get(Vue, 'GoogleAuth') as Promise<any>)
    //   .then(({ isSignedIn, currentUser }: any) => {
    //     let profile;
    //     if (isSignedIn.get()) {
    //       const user = currentUser.get();
    //       profile = user.getBasicProfile();
    //     }
    return this.context.dispatch('authenticate')
          .then(() => this.context.dispatch('loadUser')) ;

      //});
  }

  @Action
  async validateGoogleProfile(profile: Record<string, any>): Promise<any> {
    const existing = await $http.get(`api/users?email=${profile.getEmail()}`)
      .then(({ data }) => get(data, [0]))
      .catch(() => Promise.resolve());
    if (existing) {
      return store.dispatch(profile.getEmail())
        .then((r: any) => store.commit(r))
        .then((password: string) => {
          this.context.commit('setUser', Object.assign({},
            ...['Id', 'GivenName', 'FamilyName', 'ImageUrl', 'Email'].map(
              prop => ({ [camelCase(prop)]: profile[`get${prop}`]() })), { password,
              isGoogleUser: true
            }));
          return existing.google_password_set
            ? Promise.resolve()
            : $http.put(`api/admin_reset_password/${get(this, ['user', 'username'])}`, {
              google_password: get(this, ['user', 'password'])
            }).catch(() => Promise.resolve());
        })
        .then(() => this.context.dispatch('authenticate', {
            ...pick(this.user, ['username', 'password'])
          }))
        .then(() => this.context.dispatch('loadUser'))
        .catch(() => {
          this.context.commit('setProp', { prop: 'requirePassword', value: true });
          return Promise.resolve();
        });

    } else {
      const username: string = profile.getEmail();
      return store.dispatch(username)
        .then((r: any) => store.commit(r))
        .then((password: string) => {
          const user = new User(Object.assign({},
            ...['Id', 'GivenName', 'FamilyName', 'ImageUrl', 'Email'].map(
              prop => ({ [camelCase(prop)]: profile[`get${prop}`]() })), { password,
              isGoogleUser: true
            }));
          return this.context.dispatch('createUser', user);
      });
    }
  }

  @Action
  async createUser(user: User | null = this.user): Promise<any> {
    this.context.commit('setProp', { prop: 'isReady', value: false });
    const userValidation =  await $http.post(
      `api/users/${user!.username}`, user!.createRequest
    ).then((response) => {
      this.context.commit('setProp', { prop: 'userAccountMessage', value: response.data[0] });
      this.context.commit('setUser', user);
      this.context.rootState.layout.page = 'overview';
      return this.context.dispatch('saveUserState', false)
        .then(() => this.context.dispatch('authenticate', {
          ...pick(this.user, ['username', 'password'])
        }))
        .then(() => {
          this.context.commit('setProp', { prop: 'isReady', value: false });
        })
        .catch(() => this.context.commit('setProp', { prop: 'requirePassword', value: true }));
    })
      .catch((err) => {
        this.context.commit('setProp', { prop: 'userAccountMessage', value: err.response.data[0] });
        this.context.commit('setProp', { prop: 'requirePassword', value: true });
        return Promise.resolve();
      });
    return user ? userValidation
      : Promise.resolve(null);
  }

  @Action
  async setNewPassword({ newPass, oldPass }: Record<string, string>): Promise<any> {
    if (this.isLoggedIn && (oldPass !== newPass)) {
      return this.updateUser({ native_password: newPass })
        .then((response) => {
          this.context.commit('setProp', { prop: 'userAccountMessage', value: response.data[0] });
          this.context.commit('setProp', { prop: 'auth', value: {
              ...this.auth,
              password: newPass
            }
          });
        });
    }
  }

  @Action
  async updateUser(update: Partial<{
    first_name: string;
    second_name: string;
    password: string;
    native_password: string;
    google_password: string;
    google_registration: boolean;
    native_registration: boolean;
    phone: string;
    backup_email: string;
  }>): Promise<any> {
    const user = $http.put(`api/users/${this.username}`,update)
      .then((response) => {
        this.context.commit('setProp', { prop: 'userAccountMessage', value: response.data[0] });
      })
      .catch(() => {
          Promise.resolve();
      });
    return user ;
  }

  @MutationAction({ mutate: ['token', 'auth'] })
  async authenticate(auth: Credentials): Promise<{ token: string; auth: Partial<Credentials> }> {
    return fetchAuth(auth || get(this, 'state.auth'));
  }

  @Action
  async deleteUser(record: string): Promise<any> {
    return $http.delete(`api/user_settings/${record}`)
      .then(() => $http.delete(`api/users/${record}`)
        .catch(() => Promise.resolve())
      )
      .catch(() => $http.delete(`api/users/${record}`)
        .catch(() => Promise.resolve())
      );
  }

  @Action
  async saveUserState(hasRecord = true): Promise<any> {
    return this.user
      ? this.context.dispatch(
        'saveUserSettings',{
          settings: JSON.stringify(stateReducer(JSON.parse(ls.get('raven')))),
          hasRecord
        })
      : Promise.resolve();
  }

  @Action
  async logout(): Promise<any> {
    this.context.commit('trade/setProp', {
      prop: 'isLoadingData',
      value: true
    }, { root: true });
    return this.context.dispatch('saveUserState')
      .then(() => this.context.dispatch('forceLogout'))
      .catch(() => this.context.commit('trade/setProp', {
        prop: 'isLoadingData',
        value: false
      }, { root: true }));
  }


  @Action
  async forceLogout(): Promise<any> {
    return (this.isGoogleUser
        ? (get(Vue, 'GoogleAuth') as Promise<any>).then(
          ({ currentUser }) => {
            const user = currentUser.get();
            user.disconnect();
            this.context.commit('setUser', null);
            this.context.commit('setProp', {
              prop: 'auth',
              value: defaultAuth
            });
            return;
          }
        ).then(() => this.context.dispatch('authenticate'))
        : new Promise(resolve => {
          this.context.commit('setUser', null);
          this.context.commit('setProp', {
            prop: 'auth',
            value: defaultAuth
          });
          resolve(this.context.dispatch('authenticate'));
        })
    )
    .then(() => this.context.dispatch('setState', storeDefaults, { root: true }));
  }


  @Mutation
  setUser(val: null | IGoogleUserData | IUser): void {
    this.user = val
      ? val instanceof User
        ? val
        : new User(fromUserData(get(val, ['data']) || val))
      : null;
    if (!val) {
      this.auth = {};
    }
  }

  @Mutation
  setState(data: Partial<typeof AuthStore>): void {
    Object.assign(this, data);
    this.isReady = false;
    Vue.nextTick(() => {
      this.isReady = true;
    });
  }

  @Mutation
  setProp({ prop, value }: { prop: keyof Partial<typeof AuthStore>; value: any }): void {
    Vue.set(this, prop, value);
  }
}
