







import Vue from 'vue';
import { Power2, TweenMax } from "gsap";
export default Vue.extend({
  name: "AnimatedBar",
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String,
      default: 'var(--primary)'
    },
    max: {
      type: [Number, String],
      default: 100
    },
    config: {
      type: Object,
      default: () => ({
        duration: .6,
        ease: Power2.easeOut,
        rounding: .01
      })
    }
  },
  data() {
    return {
      localValue: +this.value * 100 / +this.max
    };
  },
  watch: {
    value: {
      handler(value) {
        const subject = { value: this.localValue };
        TweenMax.to(subject, this.config.duration, {
          value: +value * 100 / +this.max,
          ease: this.config.ease,
          roundProps: {
            value: this.config.rounding
          },
          onUpdate: () => {
            this.localValue = subject.value;
          }
        });
      }
    }
  }
});
