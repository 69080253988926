import { IEndpoint, IEndpointField } from "@/types";

export class Endpoint implements IEndpoint {
    private _data!: IEndpoint;

    constructor(data: Endpoint | IEndpoint) {
        if (data instanceof Endpoint) {
            return data;
        }
        this._data = data;
    }

    get name(): string {
        return this._data.name;
    }

    get fields(): IEndpointField[] {
        return this._data.fields;
    }

    get examples(): string[] | undefined {
        return this._data.examples;
    }
}
