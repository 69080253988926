import { AxiosRequestConfig, AxiosStatic, CancelTokenSource } from 'axios';
import { $store } from '@/main';
import { get, set } from 'lodash-es';
import { Getters, State } from "@/store";

interface CancelableRequest {
    config: AxiosRequestConfig;
    source: CancelTokenSource;
}

const pendingRequests = {} as any;
let authenticating = false;

const addBearer = async(config: AxiosRequestConfig, store: typeof $store): Promise<AxiosRequestConfig> => {
    if (config.url && config.url.indexOf('/token') > -1) {
        return Promise.resolve(config);
    }
    if (config.url && config.url.indexOf('api/') > -1) {
        const token = get(store, 'state.auth.token');
        if (token) {
            set(config, 'headers.Authorization', `Bearer ${token}`);
        } else {
            if (!authenticating) {
                $store.commit('auth/setProp', { prop: 'token', value: '' });
                authenticating = true;
                $store.dispatch('auth/authenticate');
            }
            return new Promise(resolve => {
                const unwatch = store.watch(
                  (state, getters) => getters['auth/hasToken'],
                  (val: boolean) => {
                      if (val) {
                          authenticating = false;
                          resolve(addBearer(config, store));
                          unwatch();
                      }
                  }
                );
            });
        }
    }
    return Promise.resolve(config);
};

export default function(axios: AxiosStatic, store: typeof $store, interceptor?: number): void {
    if (interceptor) {
        axios.interceptors.request.eject(interceptor);
    }
    axios.interceptors.request.use(async function(config) {
        if (config.url) {
            return addBearer(config, store);
        }
        return Promise.resolve(config);
    });

    axios.interceptors.response.use(r => {
        if ((r.status === 204) && r.config.url === 'api/user_settings') {
            store.commit('trade/setProp', { prop: 'isLoadingData', value: true });
            store.dispatch('auth/saveUserState', false)
              .then(() => store.commit('trade/setProp', { prop: 'isLoadingData', value: false }));
        }
        if (r.config.url && r.config.url.indexOf('api/') > -1 && r.config.url.indexOf('api/token') === -1) {
            const calls = (pendingRequests[r.config.url] || []) as CancelableRequest[];
            const callIndex = calls.findIndex(c => JSON.parse(c.config.data)?.timestamp === JSON.parse(r.config.data)?.timestamp);
            if (callIndex > -1) {
                calls
                    .filter((c, i) => i < callIndex)
                    .forEach(call => call.source.cancel('canceled'));
                calls.splice(0, callIndex + 1);
                pendingRequests[r.config.url] = calls;
            }
        }
        return r;
    }, function(err) {
        if (get(err, 'response.status') !== 403) {
            return Promise.reject(err);
        }
        if (!store.getters['auth/hasToken']) {
            return new Promise(resolve => {
                const unwatch = store.watch(
                  (state: State, getters: Getters) => getters['auth/hasToken'],
                  (val: boolean) => {
                      if (val) {
                          resolve(addBearer(err.config, store).then(r => axios.request(r)));
                          unwatch();
                      }
                  }
                );
            });
        } else {
            $store.commit('auth/setProp', { prop: 'token', value: '' });
            $store.dispatch('auth/authenticate');
            return new Promise(resolve => {
                const unwatch = store.watch(
                  (state: State, getters: Getters) => getters['auth/hasToken'],
                  (val: boolean) => {
                      if (val) {
                          resolve(addBearer(err.config, store).then(r => axios.request(r)));
                          unwatch();
                      }
                  }
                );
            });
        }
    });
}
