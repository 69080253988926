import Vue from 'vue';
import axios from 'axios';
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: '',
    fallbackLocale: 'en',
    messages: {},
    silentTranslationWarn: true
});

const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html')?.setAttribute('lang', lang);
    return lang;
}

export function loadLanguageAsync(lang: string): Promise<string> {

    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    return fetch(`/data/raven.${lang}.json`)
        .then(r => r.json())
        .then(messages => {
            i18n.setLocaleMessage(lang, messages);
            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        });
}
