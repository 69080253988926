






import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "LockRipple",
  computed: {
    ...mapState('trade', ['lockTicks'])
  }
});
