














































import Vue from 'vue';
import { HealthCheck } from "@/types";
import { tail } from 'lodash-es';
import { getWarningColor, severityLevels } from "@/helpers";

export default Vue.extend({
  name: "HealthCheckMenu",
  props: {
    closed: {
      type: Boolean,
      default: true
    },
    check: {
      type: Object as () => HealthCheck,
      required: true
    },
    severityThreshold: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    minSeverity: 0,
    severityLevels
  }),
  computed: {
    computedValue: {
      get(): number {
        return this.severityThreshold;
      },
      set(value: number) {
        this.$emit('update:severityThreshold', value);
      }
    },
  },
  methods: {
    tail,
    getWarningColor
  }
});
