






import Vue from 'vue';
import { Money } from 'v-money';
import { getCoin } from "@/helpers";
import { mapState } from "vuex";

export default Vue.extend({
  name: 'CurrencyInput',
  components: {
    Money
  },
  props: {
    value: {
      type: [Number,String],
      default: null,
      validator: (p) => {
        return ['number','string'].indexOf(typeof p) !== -1 || p === null;
      },
    },
    mask: {
      type: Object as () => Record<string, any> | null,
      default: () => null
    },
    displayOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('trade', ['accountCurrency']),
    computedValue: {
      get(): number | string {
        return this.value || 0;
      },
      set(value: number | string ) {
        this.$emit('update:value', value);
      }
    },
    computedMask() {
      return this.mask || {
        decimal: '.',
        thousands: ',',
        prefix: typeof this.accountCurrency === 'object' ? this.accountCurrency.symbol : getCoin(this.accountCurrency).symbol,
        precision: 0,
        masked: false
      };
    }
  }
});
