




















































































































































import Vue from 'vue';
import TradeCard from "@/components/trade/TradeCard.vue";
import RadarChart from "@/components/trade/RadarChart.vue";
import { mapState, mapGetters } from "vuex";
import ColumnScroller from "@/components/ColumnScroller.vue";
import { responsiveness } from "@/responsiveness";
import HealthCheckCard from "@/components/trade/HealthCheckCard.vue";
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Container, Draggable } from "vue-smooth-dnd";
import { CurrencyPair } from '@/types/CurrencyPair';
import { DragResult } from "@/store/trade.store";
import CapitalControls from "@/components/shared/CapitalControls.vue";
import { LockTexts } from "@/types";
import { get } from 'lodash-es';
import ModalContent from "@/components/shared/ModalContent.vue";
import { dynamicModal } from "@/helpers";
import EntryPriceSelector from "@/components/shared/EntryPriceSelector.vue";
import PairSelector from "@/components/shared/PairSelector.vue";
import TradeLocked from "@/components/shared/TradeLocked.vue";


gsap.registerPlugin(ScrollToPlugin);

export default dynamicModal.extend({
  name: 'Trading',
  components: {
    ModalContent,
    CapitalControls,
    ColumnScroller,
    RadarChart,
    TradeCard,
    HealthCheckCard,
    Container,
    Draggable,
    EntryPriceSelector,
    PairSelector,
    TradeLocked,
  },
  data: () => ({
    scores: [],
    warnings: [],
    disclaimer: '',
    showInactivePairs: false,
    showAnimation : false,
  }),
  computed: {
    responsiveness: () => responsiveness,
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('trade', ['accountCurrency', 'longTermCorrelations','isLeftSidebarOpen','isRightSidebarOpen','rightSidebarAnimation']),
    ...mapState('layout', ['focusedHC']),
    ...mapGetters('trade', ['allCurrencies', 'healthWarnings', 'orderedHealthChecks']),
    isLeftSidebarOpen: {
      get(): boolean {
        return this.$store.state.layout.isLeftSidebarOpen && this.responsiveness.lgDown;
      },
      set(value: boolean) {
        this.$store.commit('layout/setSidebarOpen', { value, isRightSidebar: false });
      }
    },
    isRightSidebarOpen: {
      get(): boolean {
        return this.$store.state.layout.isRightSidebarOpen && this.responsiveness.lgDown;
      },
      set(value: boolean) {
        this.$store.commit('layout/setSidebarOpen', { value, isRightSidebar: true });
      }
    },
    currentPairs: {
      get(): CurrencyPair[] { return this.$store.state.trade.currentPairs; },
      set(value: CurrencyPair[]) { 
        this.$store.commit('trade/setCurrentPairs', value);
        this.$store.dispatch('auth/saveUserState');  
      }
    },
    hiddenPairsCount(): number {
      return this.currentPairs.filter(p => !p.isVisible).length || 0;
    },
    isTradingLocked: {
      get(): boolean { return this.$store.state.trade.isTradingLocked; },
      set(value: boolean) { this.$store.commit('trade/setProp', { prop: 'isTradingLocked', value }); }
    },
    lockTexts: {
      get(): LockTexts { return this.$store.state.trade.lockTexts; },
      set(value: LockTexts) { 
        this.$store.commit('trade/setProp', { prop: 'lockTexts', value });
        this.$store.dispatch('auth/saveUserState'); 
      }
    }
  },
  watch: {
    focusedHC(id: string | null) {
      if (id) {
        setTimeout(
            () => {
              this.scrollToCard(id);
            }, 210
        );
      }
    },
    hiddenPairsCount(val) {
      if (!val) {
        this.showInactivePairs = false;
      }
    }
  },
  created(){
    this.$store.state.auth.userAccountMessage = [];
  },
  mounted() {
    this.$store.commit('auth/setProp', { prop: 'isReady', value: true });
    if (!this.lockTexts.locked) {
      this.$http.get('api/lock_text').then(
          ({ data }) => {
            this.lockTexts = {
              locked: get(data, [0, 'locked_state_tool_tip_text']),
              unlocked: get(data, [0, 'unlocked_state_tool_tip_text']),
              lockedTooltip: get(data, [0, 'locked_state_warning_text']),
              unlockedTooltip: get(data, [0, 'unlocked_state_warning_text'])
            };
          }
      ).catch(() => Promise.resolve());
    }
    if (!this.disclaimer) {
      this.$http.get('api/disclaimer_text').then(
          ({ data }) => {
            this.disclaimer = get(data, [0, 'text']);
          }
      ).catch(() => Promise.resolve());
    }
  },
  methods: {
    toggleLock(): void {
      this.openModal(this.isTradingLocked ? 'locked' : 'unlocked');
    },
    onDrop(dropResult: DragResult) {
      this.$store.commit('trade/applyDrag', dropResult);
    },
    scrollToCard(id: string) {
      const scroller = this.responsiveness.lgDown
          ? document.querySelector('#sidebar-right .b-sidebar-body')
          : (this.$refs.scroller as Vue).$el;
      if (scroller) {
        gsap.to(scroller, {
          duration: .42,
          scrollTo: {
            y: `#${id}-card`,
            offsetY: 95
          }
        });
      }
    },
    scrollUp(amount: number) {
      const scroller = this.responsiveness.lgDown
          ? document.querySelector('#sidebar-right .b-sidebar-body')
          : (this.$refs.scroller as Vue).$el;
      if (scroller) {
        gsap.to(scroller, {
          duration: .21,
          scrollTo: {
            y: Math.max(scroller.scrollTop - amount, 0)
          }
        });
      }
    },
    toggleSidebar(right = false) {
      if (right) {
        this.isRightSidebarOpen = !this.isRightSidebarOpen;
        if (this.isRightSidebarOpen) {
          this.isLeftSidebarOpen = false;
        }
        if(this.rightSidebarAnimation){
          this.$store.commit('trade/setProp', { prop: 'rightSidebarAnimation', value: false });
          this.$store.dispatch('auth/saveUserState');
        }
      } else {
        this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
        if (this.isLeftSidebarOpen) {
          this.isRightSidebarOpen = false;
        }
      }
    },
  }
});
