





















import Vue from 'vue';
import { mapState } from "vuex";
import { groupBy } from "lodash-es";
import { ICorrelationRange, ICorrelationRecord } from "@/types";

export default Vue.extend({
  name: "CorrelationMatrix",
  props: {
    interactive: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    tick: true,
    correlations: {} as Record<string, ICorrelationRecord[]>,
    correlationsTable: [] as { name: string; data: { x: string; y: null | number }[]}[]
  }),
  computed: {
    ...mapState('trade', ['currentPairs', 'longTermCorrelations', 'correlationRanges']),
    ...mapState('layout', ['darkMode']),
    options(): any {
      return {
        theme: {
          mode: this.darkMode ? 'dark' : 'light',
          palette: 'palette10'
        },
        chart: {
          toolbar: { show: false },
          background: 'transparent',
          events: {
            click: (event: MouseEvent, chartContext: any, config: any) => {
              const quote = this.correlationsTable[config.dataPointIndex]?.name;
              const base = this.correlationsTable[config.dataPointIndex]?.data[config.seriesIndex].x;
              if (quote && base) {
                this.$store.commit('trade/setCorrelationPair', { base, quote });
                this.$store.dispatch('auth/saveUserState'); 
              }
            }
          }
        },
        plotOptions: {
          heatmap: {
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: true,
            shadeIntensity: 0,
            colorScale: {
              ranges: this.correlationRanges.map((r: ICorrelationRange) => ({
                ...r,
                to: Math.round(Number(r.to) * 100) / 100,
                from: Math.round(Number(r.from) * 100) / 100
              }))
            }
          }
        },
        grid: {
          show: false
        },
        xaxis: {
          axisTicks: { show: false },
          axisBorder: { show: false },
          crossHairs: { show: false },
          labels: {
            style: {
              fontSize: '10px'
            }
          }
        },
        yaxis: {
          axisTicks: { show: false },
          axisBorder: { show: false },
          crossHairs: { show: false },
          labels: {
            style: {
              fontSize: '10px'
            }
          }
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          }
        }
      };
    },
    containerStyle(): Record<string, any> {
      return {
        maxWidth: `${(this.correlationsTable.length + 2) * 52}px`,
        minWidth: `${(this.correlationsTable.length + 2) * 52}px`,
        margin: '0 auto'
      };
    }
  },
  watch: {
    longTermCorrelations: {
      handler() {
        this.updateTable();
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    this.updateTable();
    this.$store.dispatch('trade/loadCorrelations');
  },
  methods: {
    updateTable() {

      const ltc = this.longTermCorrelations as ICorrelationRecord[];

      this.correlations = groupBy(ltc, 'first_currency_pair');

      const reduced = Object.entries(this.correlations).map(
          ([k, o]) => ({ o, k })
      ).reduce((acc, el) => el.o.length > acc.o.length ? el : acc, { o: [], k: '' });

      const entries = [reduced.k].concat(reduced.o.map(s => s.second_currency_pair));
      this.correlationsTable = entries.map(e0 => ({
        data: entries.map(e1 => ({
          x: e1,
          y: e0 === e1 ? null : Math.round((ltc.find(
            o => [e0, e1].every(
                v => [o.first_currency_pair, o.second_currency_pair].includes(v)
            )
          )?.correlation || 0) * 1e2) / 100
        })),
        name: e0
      }));
    },
  }
});
