



























































import Vue from "vue";
import { startCase, get } from "lodash-es";
import { mapState } from "vuex";

interface SimulationCoords {
  simulation_id: number;
  x: number;
  y: number;
}

interface BellChartData {
  balanced_account_currency_amount: SimulationCoords[];
  balanced_base_currency_amount: SimulationCoords[];
  balanced_expected_opportunity: SimulationCoords[];
  tooltip_text: string;
}

interface SimulationSeries {
  name: string;
  data: SimulationCoords[];
}

type BellChartType = 'sortino_ratio' | 'sharpe_ratio' | 'max_dd';

export default Vue.extend({
  name: "BellCurveChart",
  data: () => ({
    legendColors: [] as Array<string>,
    inactiveLegend: [false,false,false],
    selectedChartType: 'sortino_ratio' as BellChartType,
    selectOptions: [] as {text?: string; tooltip?: string; value?: string}[],
    bellCharts: {
      max_dd: {} as Record<string, BellChartData[]>,
      sharpe_ratio: {} as Record<string, BellChartData[]>,
      sortino_ratio: {} as Record<string, BellChartData[]>
    },
    seriesNames: {
      'balanced_expected_opportunity': 'Raven approach (Balanced Expected Opportunity)'
    }
  }),
  computed: {
    ...mapState('layout', ['darkMode']),
    ...mapState('overview', ['summaryBellCurveChart']),
    chartSeries(): SimulationSeries[] {
      return Object.entries(this.bellCharts[this.selectedChartType])
          .filter(([key]) => key !== 'tooltip_text').map(
          ([name, data]) => ({ name: get(this, ['seriesNames', name]) || startCase(name), data } as unknown as SimulationSeries)
      );
    },
    chartTooltip() {
      return (key: BellChartType) => this.bellCharts[key].tooltip_text;
    },
    chartOptions(): any {
      return {
        theme: {
          mode: this.darkMode ? 'dark' : 'light',
        },
        grid: {
          borderColor: "#66666611",
        },
        legend: {
          show:false,
        },
        colors: [this.darkMode ? '#4E4E4E' : '#B2B2B2', '#808080', '#FF5500'],
        chart: {
          background: 'transparent',
          type: 'line',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 400,
            animateGradually: {
              enabled: true,
              delay: 100
            },
            dynamicAnimation: {
              enabled: true,
              speed: 420
            }
          },
          foreColor: "var(--text)",
        },
        yaxis: {
          title: {
            style: {
              fontSize: '20px'
            },
            text: 'Probability density'
          },
          min: this.yMin,
          max: this.yMax,
          tickAmount: this.yTicks,
          labels: {
            formatter: (val: string) =>
                parseFloat(val).toFixed(2),
          }
        },
        xaxis: {
          title: {
            style: {
              fontSize: '20px'
            },
            text: this.selectOptions.find(({ value }) => value === this.selectedChartType)?.text
          },
          min: this.xMin,
          max: this.xMax,
          tickAmount: this.xTicks,
          tickPlacement: 'on',
          labels: {
            formatter: (val: number) =>
                (this.isDrawDown ? parseFloat(`${val * 100}`).toFixed(0) : val) + (this.selectedChartType === 'max_dd' ? '%' : ''),
            floating: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
          }
        },
        stroke: {
          width: 2
        },
        markers: {
          strokeColors: 'var(--minus)',
          size: 0
        }
      };
    },
    chartValues(): SimulationCoords[] {
      return this.chartSeries.map(
          s => s.data
      ).flat();
    },
    isDrawDown(): boolean {
      return this.selectedChartType === 'max_dd';
    },
    isSharpe(): boolean {
      return this.selectedChartType === 'sharpe_ratio';
    },
    xFactor(): number {
      return this.isDrawDown ? 20 : this.isSharpe ? 2 : 1;
    },
    xMin(): number {
      return this.chartValues.length
          ? Math.floor(Math.min(...this.chartValues.map(({ x }) => x)) * this.xFactor) / this.xFactor
          : 1;
    },
    xMax(): number {
      return this.chartValues.length
          ? Math.ceil(Math.max(...this.chartValues.map(({ x }) => x)) * this.xFactor) / this.xFactor
          : 0;
    },
    xTicks(): number {
      return Math.round((this.xMax - this.xMin) * this.xFactor);
    },
    yMin(): number {
      return this.chartValues.length
          ? Math.floor(Math.min(...this.chartValues.map(({ y }) => y)) * this.yFactor) / this.yFactor
          : 0;
    },
    yMax(): number {
      return this.chartValues.length
          ? (Math.floor(Math.max(...this.chartValues.map(({ y }) => y)) * this.yFactor) + 1) / this.yFactor
          : 1;
    },
    yFactor(): number {
      return this.isDrawDown ? 2 : 10;
    },
    yTicks(): number {
      return (this.yMax - this.yMin) * this.yFactor;
    }
  },
  watch: {
    chartSeries: {
      handler() {
        this.inactiveLegend = [false,false,false];
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.getOptions();
    this.$http.get('api/simulation_approach_comparison')
        .then(({ data }) => {
          this.bellCharts = data;
        })
        .catch(() => Promise.resolve());
    this.legendColors = [this.darkMode ? '#4E4E4E' : '#B2B2B2', '#808080', '#FF5500'];
  },
  methods:{
    getOptions(){
      const values: Array<string>  = ['sortino_ratio','sharpe_ratio','max_dd'];
      const optionsWitouthValue = [] as {text?: string; tooltip?: string; value?: string}[];
      for (let i = 0; i <= this.summaryBellCurveChart.items.length - 1; i++) {
        const item = this.summaryBellCurveChart.items[i];
        optionsWitouthValue.push(
          {
            text : item.item_text,
            tooltip: item.item_tooltip
          },
        );
    }
      for (let y = 0; y <= optionsWitouthValue.length - 1; y++) {
        optionsWitouthValue[y].value = values[y];
      }
      this.selectOptions = optionsWitouthValue;
    },
    toggleSeriesName(name: string )  {
      const seriesIndex = this.chartSeries.findIndex((item) => {
        return item.name === name;
      });
      this.inactiveLegend[seriesIndex] = !this.inactiveLegend[seriesIndex];
      (this.$refs.chart as any).toggleSeries(name);
    },
    toggleSeriesOpacityMouseEnter(name: string)  {
      const colorsArray = [] as any;
      const seriesArray = [] as any;
      this.chartSeries.forEach((item, index) => {
        if(item.name !== name){
            seriesArray.push(index);
        }   
        colorsArray.push(this.legendColors[index]);
      });
      seriesArray.forEach((item : number) => {
          colorsArray[item] = colorsArray[item].replace('#','');
          colorsArray[item] = this.hextoRGBA(colorsArray[item],0.25);
        }
      );
      (this.$refs.chart as any).updateOptions({ colors: colorsArray, });
    },
    toggleSeriesOpacityMouseLeave()  {
      this.legendColors = [this.darkMode ? '#4E4E4E' : '#B2B2B2', '#808080', '#FF5500'];
      (this.$refs.chart as any).updateOptions({ colors: [this.darkMode ? '#4E4E4E' : '#B2B2B2', '#808080', '#FF5500'], });
    },
    hextoRGBA(color: string, opacity: number): string {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return '#'+color + _opacity.toString(16).toUpperCase();
    }
  }
});
