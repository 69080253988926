















import Vue from 'vue';
export default Vue.extend({
  name: "OptionsSlider",
  props: {
    options: {
      type: Array as () => any[],
      default: () => []
    },
    value: {
      type: [String, Object, Number],
      default: null,
      validator: (p) => {
        return ['string', 'number', 'object'].indexOf(typeof p) !== -1 || p === null;
      },
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    computedValue: {
      get(): number {
        return this.value || 0;
      },
      set(value: number) {
        this.$emit('update:value', value);
      }
    },
    formattedValue(): string | number {
      return typeof this.config.formatter === 'function'
        ? this.config.formatter(this.value)
        : this.value;
    },
    sliderValue: {
      get(): number {
        return this.options.findIndex(({ value }) => value === this.value);
      },
      set(value: number) {
        this.computedValue = this.options[value].value;
      }
    },
    label(): string {
      return this.options.find(({ value }) => value === this.value).label;
    }
  }
});
