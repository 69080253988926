



















import { CurrencyPair , Currency } from "@/types";
import { mapGetters, mapState } from "vuex";
import CurrencySelector from "@/components/shared/CurrencySelector.vue";
import { dynamicModal } from "@/helpers";

export default dynamicModal.extend({
  name: "PairSelector",
  components: {
    CurrencySelector
  },
  props: {
    cancelButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currencyPair: {
      base: '',
      quote: ''
    },
  }),
  computed: {
    ...mapState('trade', ['currentPairs', 'apiCurrencies']),
    ...mapGetters('trade', ['allCurrencies']),

    base: {
      get(): string {
        return this.currencyPair.base || '';
      },
      set(base: any) {
        this.currencyPair.base = base ;
      }
    },
    quote: {
      get(): string {
        return this.currencyPair.quote || '';
      },
      set(quote: any) {
        this.currencyPair.quote = quote ;
      }
    }
  },
  methods: {
    filteredCurrencies(associate: Currency, position: number, current?: string): string[] {
      (this.base.code && this.quote.code) && this.addCurrencyPair();
      return associate
          ? (current ? [current] : []).concat(this.allCurrencies.filter(
              ({ code }: { code: string }) => code !== associate.code &&
                  !this.currentPairs.find(({ base, quote }: CurrencyPair) =>
                      base  === associate.code && quote === code ||
                      quote === associate.code && base === code
                  )
          ))
          : this.allCurrencies;
    },
    addCurrencyPair() {
      this.$store.commit('trade/addCurrencyPair', { quote: this.quote?.code, base: this.base?.code });
      this.$nextTick(() => {
        this.quote = '';
        this.base = '';
      });
      this.$store.dispatch('auth/saveUserState'); 
    }
  }
});
