



















import Vue from 'vue';
import InputCard from "@/components/shared/InputCard.vue";
import { IInputControl, SimulationControl } from "@/types";
import { formatValue } from "@/helpers";
import LoadingAnimation from "@/components/shared/LoadingAnimation.vue";
import { mapState } from "vuex";
import { isEqual } from "lodash-es";

const patchValues = (obj: { [s: string]: string } | ArrayLike<unknown>) => obj
    ? Object.assign({}, ...Object.entries(obj)
        .map(([key, value]) => ({
          [key]: [
            'simulation_set_id',
            'simulation_id',
            'starting_cash',
            'currency_pair_count',
            'leverage',
            'direction_prediction_accuracy',
            'portfolio_loss_tolerance_rate',
            'lot_size',
            'reactiveness_level'
          ].includes(key)
              ? +(value as string)
              : value
        })))
    : obj;

export default Vue.extend({
  name: "SimulationControls",
  components: {
    LoadingAnimation,
    InputCard
  },
  data: () => ({
    inputControls: [] as IInputControl[]
  }),
  computed: {
    ...mapState('overview', ['simulationControls', 'simulationSetIndex','simulationSetIndexDefault']),
    form(): Record<string, any> {
      return Object.assign({}, ...this.inputControls.map(c => ({
        [c.id as string]: c.value
      })), {
        simulation_set_id: this.simulationControls
            .find((c: SimulationControl) => c.parameter === 'simulation_set_id')
            ?.values[this.simulationSetIndexDefault] || this.simulationSetIndex
      });
    }
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        if (!isEqual(patchValues(newVal), patchValues(oldVal))) {
          this.$store.commit('overview/setProp', { prop: 'simulationQuery', value: patchValues(newVal) });
        }
      },
      immediate: true
    },
    simulationControls: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.inputControls = newVal
              .filter((c: SimulationControl) => c.visible)
              .map((c: SimulationControl) => this.getInputControl(c));
        }
      },
      immediate: true
    }
  },
  async created() {
    await this.$http.post('/api/simulation_parameters', {}).then(
        ({ data }) => {
          this.$store.commit('overview/setProp', {
            prop: 'simulationSetIndex',
            value: data[0].find((c: SimulationControl) => c.parameter === 'simulation_set_id').default_value
          });
          this.$store.commit('overview/setProp', {
            prop: 'simulationSetIndexDefault',
            value: data[0].find((c: SimulationControl) => c.parameter === 'simulation_set_id').default_value_index
          });
          this.$store.commit('overview/setProp', { prop: 'simulationControls', value: data.flat() || [] });
          this.$nextTick(() => {
            this.$store.commit('overview/setProp', { prop: 'isSimulationReactive', value: true });
          });
        }
    ).catch(() => Promise.resolve());
  },
  async destroyed(){
    await this.$http.post('/api/simulation_parameters', {}).then(
          ({ data }) => {
            const val = data[0].find((c: SimulationControl) => c.parameter === 'simulation_set_id');
            this.$store.commit('overview/setProp', {
              prop: 'simulationSetIndex',
              value: val.default_value
            });
            this.$store.commit('overview/setProp', {
              prop: 'simulationSetIndexDefault',
              value: val.default_value_index
            });
            this.$store.commit('overview/setProp', { prop: 'simulationControls', value: data.flat() || [] });
            this.$nextTick(() => {
              this.$store.commit('overview/setProp', { prop: 'isSimulationReactive', value: true });
            });
          }
      ).catch(() => Promise.resolve());
  },
  methods: {
    getInputControl(c: SimulationControl) {
      switch (c.parameter) {
        case 'portfolio_loss_tolerance_rate':
          return {
            id: c.parameter,
            type: 'select',
            label: c.title,
            tooltip: c.tool_tip,
            value: Number(c.default_value),
            debounce: 300,
            options: c.values.map(value => ({
              value,
              text: formatValue(Number(value), {
                style: 'percent',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              })
            })),
            append: '%'
          };
        case 'leverage':
          return {
            id: c.parameter,
            type: 'select',
            debounce: 300,
            prepend: '1:',
            value: Number(c.default_value),
            label: c.title,
            tooltip: c.tool_tip,
            options: c.values
          };
        case 'direction_prediction_accuracy':
          return {
            id: c.parameter,
            type: 'select',
            label: c.title,
            tooltip: c.tool_tip,
            value: c.default_value,
            debounce: 300,
            options: c.values.map(value => ({
              value,
              text: formatValue(value * 100, {
                style: 'decimal',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
              })
            })),
            append: '%'
          };
        default:
          return {
            type: 'select',
            label: c.title,
            id: c.parameter,
            tooltip: c.tool_tip,
            value: c.default_value,
            options: c.values
          };
      }
    }
  }
});
