<template>
  <div ref="root" class="account-currency-select">
    <slot
      :rootProps="rootProps"
      :inputProps="inputProps"
      :inputListeners="inputListeners"
      :resultListProps="resultListProps"
      :resultListListeners="resultListListeners"
      :results="results"
      :resultProps="resultProps">
      <div v-bind="rootProps" @click="showHideResults">
        <input
          ref="input"
          v-bind="inputProps"
          :placeholder="placeholder"
          @input="handleInput"
          @click="handleInput"
          @focus="handleShow"
          @blur="handleHide"
          v-on="$listeners">
        <b-list-group   v-if="noResults" 
                        ref="noResultList"
                        class="autocomplete-result-list currency-result-list"
                        role="listbox">
          <div  class="overflow-auto">
            <template>
              <slot name="result">
                <p class="autocomplete-result currency-result">
                  {{ 'No currency found' }}
                </p>
              </slot>
            </template>
          </div>
        </b-list-group>

        <b-list-group
          v-if="!noResults" 
          ref="resultList"
          v-bind="resultListProps"
          v-on="resultListListeners">
          <div  class="overflow-auto">
            <template v-for="(category) in categories">
              <slot name="category">
                <label  :key="category"
                        v-text="category" />
                <template v-for="(result, index) in results">
                  <slot name="result"
                        :result="result"
                        :props="resultProps[index]">
                    <b-list-group-item  v-if="result.category === category"
                                        :key="`${category}-${resultProps[index].id}`"
                                        v-bind="resultProps[index]">
                      {{ getResultValue(result) }}
                    </b-list-group-item>
                  </slot>
                </template>
              </slot>
            </template>
          </div>
        </b-list-group>
      </div>
    </slot>
  </div>
</template>

<script lang="js">
/* eslint-disable */
import AutocompleteCore from '@trevoreyre/autocomplete';
import uniqueId from '@trevoreyre/autocomplete/util/uniqueId.js';
import getRelativePosition from '@trevoreyre/autocomplete/util/getRelativePosition.js';
import debounce from '@trevoreyre/autocomplete/util/debounce.js';

export default {
  name: 'Autocomplete',
  inheritAttrs: false,

  props: {
    search: {
      type: Function,
      required: true,
    },
    baseClass: {
      type: String,
      default: 'autocomplete',
    },
    inputClass: {
      type: String,
      default: ''
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
    getResultValue: {
      type: Function,
      default: o => o,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    debounceTime: {
      type: Number,
      default: 0,
    },
    isEmpty: {
      type: Boolean,
      default: true
    },
    options: {
      type: [Array,Object],
      default:  [],
    },
  },
  data() {
    const core = new AutocompleteCore({
      search: this.search,
      autoSelect: this.autoSelect,
      setValue: this.setValue,
      onUpdate: this.handleUpdate,
      onSubmit: this.handleSubmit,
      onLoading: this.handleLoading,
      onLoaded: this.handleLoaded,
    });
    if (this.debounceTime > 0) {
      core.handleInput = debounce(core.handleInput, this.debounceTime);
    }

    return {
      core,
      toggleInput: false,
      value: this.defaultValue,
      resultListId: uniqueId(`${this.baseClass}-result-list-`),
      results: [],
      categories: [],
      selectedIndex: -1,
      expanded: false,
      loading: false,
      position: 'below',
      resetPosition: true,
    };
  },

  computed: {
    noResults() {
      const resultsLength = this.options
        .filter(
          (i) =>
            (i.name.toLowerCase().includes(this.value.toLowerCase()) ||
            i.code.toLocaleLowerCase().includes(this.value.toLowerCase()) ||
            i.symbol.toLowerCase().includes(this.value.toLowerCase()) ||
            i.code === this.value.substring(0,i.code.length))
        ).length
      return this.value && this.results.length === 0 && resultsLength === 0
    },
    rootProps() {
      return {
        class: this.baseClass,
        style: { position: 'relative' },
        'data-expanded': this.toggleInput,
        'data-loading': this.loading,
        'data-position': this.position,
      };
    },
    noResultClass(){
      return this.noResults ?  'autocomplete-input-no-results' : ''
    },
    focusedClass(){
      return this.expanded ?  'autocomplete-input-focused' : ''
    },
    inputProps() {
      return {
        class: `${this.baseClass}-input ${this.inputClass} autocomplete-input  ${this.noResultClass} ${this.focusedClass}`,
        value: this.value,
        role: 'combobox',
        autocomplete: 'off',
        autocapitalize: 'off',
        autocorrect: 'off',
        spellcheck: 'false',
        'aria-autocomplete': 'list',
        'aria-haspopup': 'listbox',
        'aria-owns': this.resultListId,
        'aria-expanded': this.toggleInput ? 'true' : 'false',
        'aria-activedescendant':
            this.selectedIndex > -1
                ? this.resultProps[this.selectedIndex].id
                : '',
        ...this.$attrs,
      };
    },
    inputListeners() {
      return {
        input: this.handleInput,
        keydown: this.core.handleKeyDown,
        focus: this.handleFocus,
        blur: this.handleBlur,
      };
    },
    resultListProps() {
      const yPosition = this.position === 'below' ? 'top' : 'bottom';
      const resultsLength = this.options
        .filter(
          (i) =>
           ( i.name.toLowerCase().includes(this.value.toLowerCase()) ||
            i.code.toLocaleLowerCase().includes(this.value.toLowerCase()) ||
            i.symbol.toLowerCase().includes(this.value.toLowerCase()) ||
            i.code === this.value.substring(0,i.code.length))
        ).length
      return {
        id: this.resultListId,
        class: `${this.baseClass}-result-list`,
        role: 'listbox',
        style: {
          position: 'absolute',
          zIndex: 999,
          width: '100%',
          visibility: this.toggleInput ? 'visible' : 'hidden',
          pointerEvents: this.toggleInput ? 'auto' : 'none',
          [yPosition]: '100%',
          height: !this.toggleInput
                    ? 0 + 'px' 
                    :this.results.length > 0 
                      ? this.results.filter((r, index) => !r.label || this.resultProps[index].isVisible).length == 1 
                        ? Math.min(300, this.results.filter((r, index) => !r.label || this.resultProps[index].isVisible).length  * 80) + 'px' 
                        : Math.min(300, this.results.filter((r, index) => !r.label || this.resultProps[index].isVisible).length  * 40) + 'px' 
                      : 0 + 'px' 
        },
      };
    },

    resultListListeners() {
      return {
        mousedown: this.core.handleResultMouseDown,
        click: this.core.handleResultClick,
      };
    
    },
    resultProps() {
      return this.results.map((result, index) => result.label
          ? {  role: 'label',isVisible: this.results.some(o => o.type === result.label.toLowerCase()),'disabled':'true','aria-readonly': 'true' ,'aria-disabled': 'true' }
          : {
            id: `${this.baseClass}-result-${index}`,
            class: `${this.baseClass}-result`,
            'data-result-index': index,
            role: 'option',
            ...(this.selectedIndex === index ? { 'aria-selected': 'true' } : {}),
          });
    },
  },

  watch: {
    isEmpty(val) {
      if (val) {
        this.value = '';
      }
    },
    value(val){
      if (val) {
        this.toggleInput = true
      }
    },
    expanded(val){
      this.toggleInput = !val
    }
  },

  mounted() {
    document.body.addEventListener('click', this.handleDocumentClick);

  },

  beforeDestroy() {
    document.body.removeEventListener('click', this.handleDocumentClick);
  },

  updated() {
    if (!this.$refs.input || !this.$refs.resultList || this.$refs.noResultList) {
      return;
    }
    if (this.resetPosition && this.results.length > 0) {
      this.resetPosition = false;
      this.position = getRelativePosition(
          this.$refs.input,
          this.$refs.resultList
      );
    }
  },

  methods: {
    setValue(result) {
      if(result){
        this.value = result  ? this.getResultValue(result) : '';
      }
    },
    handleUpdate(results, selectedIndex) {
      this.results = results
      this.selectedIndex = selectedIndex ;
      if (this.results) {
        this.$emit('update', results, selectedIndex);
      }
      const categories = this.results.map((cat) => cat.category);
      this.categories = categories.filter(
        (x, y) => categories.indexOf(x) == y
      );
    },

    handleShow() {
      this.expanded = true;
    },

    handleHide() {
      this.expanded = false;
      this.resetPosition = true;
    },

    handleLoading() {
      this.loading = true;
    },

    handleLoaded() {
      this.loading = false;
    },

    handleInput(event) {
      if (this.results) {
        this.value = event.target.value;
        this.core.handleInput(event);
      }
    },

    handleSubmit(selectedResult) {
      if (selectedResult && !selectedResult.label) {
        this.$emit('submit', selectedResult);
      }
    },

    handleDocumentClick({ target }) {
      if (this.$refs.root.contains(target)) {
        return;
      }
      this.core.hideResults();
    },
    handleKeyDown(event) {
        if (event.keyCode === 13 && this.selectedIndex === -1 ) {
            if(this.results.length === 1){
              this.setValue(this.results);
              this.handleSubmit(this.results);
          }
        }
          this.core.handleKeyDown(event);
    },
    showHideResults(){
      
      this.toggleInput = !this.toggleInput;
      if(!this.toggleInput){
        this.$refs.input.blur();
      }
      this.resetPosition = true;
    }
  },
};
</script>
<style>
.account-currency-select .form-control[readonly] {
  opacity: 1;
  color: var(--text)!important;
  background-color: var(--input-bg)!important;
  border-color: var(--input-bg)!important;
}
.account-currency-select .form-control[readonly]:focus {
  background-color: var(--input-focus-bg)!important;
  color: var(--text)!important;
}

.account-currency-select .autocomplete-input-no-results:not(.autocomplete-input-focused) ~ .autocomplete-result-list {
  display: none;
}


</style>
