







import Vue from "vue";
export default Vue.extend({
  name: "SvgClickHandler",
  props: {
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    }
  }
});
