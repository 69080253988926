



































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { BDropdown } from "bootstrap-vue";
export default Vue.extend({
  name: "UserSelector",
  computed: {
    ...mapState('auth', ['users']),
    ...mapGetters('auth', ['username']),
    usersList(): string[] {
      return (this.users as { email: string; role: string }[])
          .filter(({ role }) => role !== 'system')
          .map(({ email }) => email);
    },
    selectedUser: {
      get(): string {
        return this.$store.state.auth.selectedUser === this.username
            ? ''
            : this.$store.state.auth.selectedUser;
      },
      set(value: string) {
        this.$store.commit('auth/setProp', {
          prop: 'selectedUser',
          value
        });
      }
    }
  },
  methods: {
    setUser(email: string) {
      this.selectedUser = email;
      const dd = this.$refs['users-dd'];
      if (dd instanceof BDropdown) {
        requestAnimationFrame(() => {
          dd.hide();
        });
      }
    },
    saveUser() {
      this.$store.dispatch('auth/saveUserState');
    }
  }
});
