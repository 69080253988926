



















import Vue from 'vue';
import { IInputControl } from "@/types";
import InputCard from "@/components/shared/InputCard.vue";
import GridBox from "@/components/shared/GridBox.vue";
export default Vue.extend({
  name: "AdvancedControls",
  components: {
    InputCard,
    GridBox
  },
  props: {
    controls: {
      type: Array as () => IInputControl[],
      default: () => []
    }
  },
  data: () => ({
    isOpen: false
  })
});
