

























import Vue from 'vue';
import $http from 'axios';
import { mapState } from "vuex";
import SelectInput from "@/components/shared/SelectInput.vue";
import { ICorrelationRange } from "@/types";

interface DataPoint {
  color: string;
  y: number;
  x?: string | Date;
  t?: number;
}
export default Vue.extend({
  name: "CorrelationsModal",
  components: { SelectInput },
  data: () => ({
    series: [] as { name: string; data: any[] }[],
    correlationPeriod: 5,
    isLoading: false,
    hasError: false,
    baseData: [] as DataPoint[]
  }),
  computed: {
    ...mapState('trade', ['correlationPair', 'correlationRanges', 'currentPairs']),
    ...mapState('layout', ['darkMode']),
    correlationTitle(): string {
      return this.correlationPair ? `Rolling correlation: ${Object.values(this.correlationPair).join(' - ')}`: '';
    },
    inputControls() {
      return [{
        type: 'date-range',
        value: 10,
        label: 'Date range',
        tooltip: 'Select date range'
      }];
    },
    correlationBreakpoints(): number[] {
      return [-.95, -.8, -.5, .5, .8, .95];
    },
    correlationOptions(): { text: string; value: number }[] {
      return [1, 3, 5].map(value => ({
        value,
        text: `${value} year${value > 1 ? 's' : ''}`
      }));
    },
    options(): any {
      return {
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          position: 'top',
          markers: {
            fillColors: this.correlationRanges.map((d: ICorrelationRange) => d.color)
          },
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          }
        },
        markers: {
          size: [0,0,0,0],
          radius: 0,
          strokeWidth: 0,
          showNullDataPoints: false,
          colors: 'var(--input-bg)',
          strokeColors: 'var(--plus)'
        },
        chart: {
          toolbar: { show: false },
          background: this.darkMode ? 'rgba(0, 0, 0, .21)': 'rgba(0, 0, 0, .07)',
          animations: {
            enabled: false
          },
        },
        stroke: {
          width: 2,
          dashArray: 0,
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: []
        },
        yaxis: {
          min: -1,
          max: 1,
          tickAmount: 8,
          labels: {
            formatter: (val: string) =>
                parseFloat(val).toFixed(2),
          }
        },
        theme: {
          mode: this.darkMode ? 'dark' : 'light',
          palette: 'palette2'
        },
        grid: {
          borderColor: 'var(--soft-muted)',
          strokeDashArray: 7,
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          marker: {
            show: false,
          },
          shared: false,
          followCursor: true
        },
        colors: this.correlationRanges.map((d: ICorrelationRange) => d.color),
      };
    },
    correlationsUrl() {
      switch (this.correlationPeriod) {
        case 1:
          return 'api/rolling_one_year_correlations';
        case 3:
          return 'api/rolling_three_year_correlations';
        default:
          return 'api/rolling_five_year_correlations';
      }
    }
  },
  watch: {
    correlationPair: {
      handler() {
        this.loadCorrelations();
      },
      immediate: true
    },
    correlationPeriod: {
      handler() {
        this.loadCorrelations();
      }
    }
  },
  methods: {
    linearInterpolation({ start, end, breakpoint }: { start: DataPoint; end: DataPoint; breakpoint: number }) {
      const t = (start.t || 0) + (breakpoint - start.y) * ((end.t || 0) - (start.t || 0)) / (end.y - start.y);
      return { t, x: new Date(t).toDateString() };
    },
    findBreakpoints(a: number, b: number): number[] {
      return this.correlationBreakpoints.filter(
          p => p > Math.min(a, b) && p < Math.max(a, b)
      );
    },
    breakPointColor(val: number) {
      return this.correlationRanges.find(({ to }: ICorrelationRange) => {
        return val < .001 ? to > val : to >= val;
      })?.color || 'transparent';
    },
    loadCorrelations() {
      if (this.currentPairs.length && Object.values(this.correlationPair).length) {
        this.isLoading = true;
        this.hasError = false;
        $http.get(this.correlationsUrl, {
          params: {
            currency_pairs: `[${
                Object.values(this.correlationPair) || []?.join(',')
            }]`
          }
        }).then(({ data }) => {
          this.baseData = (data.map((i: any) => ({
            x: i.end_trade_date,
            y: i.correlation,
            t: new Date(i.end_trade_date).valueOf(),
            color: this.breakPointColor(i.correlation)
          })) as DataPoint[]).reduce((acc, el) => {
            const color = this.breakPointColor(el.y);
            if (color && acc.length && acc[acc.length - 1].color !== color) {
              const breakpoints = this.findBreakpoints(acc[acc.length - 1].y, el.y);
              breakpoints.sort((a, b) => a > b && el.y > acc[acc.length - 1].y ? 1 : -1).forEach(breakpoint => {
                const interpolation = this.linearInterpolation({
                  start: acc[acc.length - 1],
                  end: { ...el, color: this.breakPointColor(el.y) },
                  breakpoint
                });
                [acc[acc.length - 1].color, this.breakPointColor(
                    breakpoint + (breakpoint > acc[acc.length - 1].y ? 0.001 : -0.001)
                )].forEach(c => {
                  acc.push({
                    ...interpolation,
                    y: breakpoint,
                    color: c
                  });
                });
              });
            }
            acc.push({ ...el, color: this.breakPointColor(el.y) });
            return acc;
          }, [] as DataPoint[]);

          this.series = this.correlationRanges.slice(0, 4)
              .map(({ name, color }: ICorrelationRange) => ({
                name,
                data: this.baseData.map(i => ({
                  ...i,
                  y: this.breakPointColor(i.y) === color ||
                  this.breakPointColor(i.y + 0.001) === color ||
                  this.breakPointColor(i.y - 0.001) === color ? i.y : null
                }))
              }));

          setTimeout(() => {
            this.isLoading = false;
          });
        }).catch(() => {
          this.hasError = true;
          return Promise.resolve();
        });
      } else {
        this.series = [];
      }
    }
  }
});
