





























































import Vue from 'vue';
import { HealthCheck } from "@/types";
import { mapGetters, mapState } from "vuex";
import { getWarningColor } from "@/helpers";
import { TranslateResult } from "vue-i18n";
import AnimatedScore from "@/components/overview/AnimatedScore.vue";
import CardScroller from "@/components/shared/CardScroller.vue";
import { responsiveness } from "@/responsiveness";
import LiquidityTable from "@/components/overview/LiquidityTable.vue";
import CorrelationsList from "@/components/shared/CorrelationsList.vue";
import { get } from 'lodash-es';

export default Vue.extend({
  name: "OverviewHealthCheck",
  components: { CorrelationsList, LiquidityTable, AnimatedScore, CardScroller },
  props: {
    healthCheck: {
      type: HealthCheck,
      required: true
    },
    showAction: {
      type: Boolean,
      default: true
    },
    isModalContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('trade', ['severityColor', 'orderedHealthChecks']),
    ...mapState('trade', ['accountCurrency']),
    ...mapState('layout', ['darkMode']),
    selectedStopLoss(): TranslateResult {
      return this.$store.state.trade.stopLossApproach
          ? this.$t(`questionnaire.steps[2].questions[0].options[${this.$store.state.trade.stopLossApproach - 1}]`)
          : '--';
    },
    responsiveness: () => responsiveness,
    selectedPositionSizing(): TranslateResult {
      return this.$store.state.trade.positionSizingApproach
          ? this.$t(`questionnaire.steps[1].questions[1].options[${this.$store.state.trade.positionSizingApproach - 1}]`)
          : '--';
    },
    selectedLeverageApproach(): TranslateResult {
      return this.$store.state.trade.leverageApproach
        ? this.$t(`questionnaire.steps[1].questions[0].options[${this.$store.state.trade.leverageApproach - 1}]`)
        : '--';
    },
    scrollerStyle(): Record<string, unknown> {
      return {
        height: '100%',
        width: 'calc(100% + 15px)',
        paddingRight: '15px',
        marginRight: '-15px'
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateScroll();
    });
  },
  methods: {
    getWarningColor,
    is(id: string): boolean {
      return this.healthCheck.id === id;
    },
    formatValue(value: number) {
      return new Intl.NumberFormat('en-IE', { style: 'currency', currency: typeof this.accountCurrency == 'object' ? this.accountCurrency.code : this.accountCurrency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    },
    overviewScore(hc: HealthCheck | string): number {
      return typeof hc === 'string' ? 0 : Number(hc.score) * 100;
    },
    overviewTitle(hc: HealthCheck | string): string {
      return typeof hc === 'string' ? hc : hc.title;
    },
    updateScroll() {
      setTimeout(() => {
        const ps = get(this, '$refs.scroller.$refs.scroll');
        if (typeof ps?.update === 'function') {
          ps.update();
        }
      }, 333);
    }
  }
});
