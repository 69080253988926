import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { $store } from "@/main";
import { ActionContext } from "vuex";
import { State } from "@/store/index";
import Vue from "vue";

interface LayoutState {
    page: string;
    darkMode: boolean;
    stepIndex: number;
    isRightSidebarOpen: boolean;
    isLeftSidebarOpen: boolean;
    focusedHC: null | string;
    dynamicModalSize: string;
    dynamicModalTarget: null | string;
    usingDashboardContent: string;
}

@Module({ namespaced: true, store: $store, name: 'layout' })
export default class LayoutStore extends VuexModule implements LayoutState {
    page = '';
    darkMode = true;
    stepIndex = 0;
    isRightSidebarOpen = false;
    isLeftSidebarOpen = false;
    focusedHC: null | string = null;
    dynamicModalSize = '';
    dynamicModalTarget: null | string = null;
    usingDashboardContent = '';

    get activeSteps(): boolean[] {
        const { rootState } = this.context as ActionContext<LayoutStore, State>;
        return [true,
            Boolean(rootState.trade.currentPairs.length),
            typeof rootState.trade.positionSizingApproach === 'number',
            typeof rootState.trade.stopLossApproach === 'number'
        ];
    }

    @Mutation
    setPage(page: string): void {
        this.page = page;
    }

    @Mutation
    setDarkMode(mode: boolean): void {
        this.darkMode = mode;
    }

    @Mutation
    setStepIndex(index: number): void {
        this.stepIndex = index;
    }

    @Mutation
    setSidebarOpen({ value, isRightSidebar }: { value: boolean; isRightSidebar: boolean }): void {
        if (isRightSidebar) {
            this.isRightSidebarOpen = value;
        } else {
            this.isLeftSidebarOpen = value;
        }
    }

    @Mutation
    setFocusedHC(id: string | null): void {
        this.focusedHC = id;
    }

    @Mutation
    setState(data: Partial<LayoutState>): void {
        Object.assign(this, data);
    }

    @Mutation
    setProp({ prop, value }: { prop: keyof LayoutState; value: any }): void {
        Vue.set(this, prop, value);
    }
}
