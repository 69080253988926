






















































import { get } from 'lodash-es';
import { dynamicModal, getRouteName } from "@/helpers";
import { mapGetters, mapState } from "vuex";
import ModalContent from "@/components/shared/ModalContent.vue";
export default dynamicModal.extend({
  name: 'OverviewFooter',
  components: {
    ModalContent,
  },
  data: () => ({
    content: '',
    disclaimer: '',
  }),
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('overview', ['summaryFooter']),
  },
  mounted() {
    if (!this.content) {
      this.$http.get('api/overview_footer_text')
          .then(({ data }) => {
            this.content = get(data, [0, 'text']);
          })
          .catch(() => Promise.resolve());
    }
    if (!this.disclaimer) {
      this.$http.get('api/disclaimer_text').then(
          ({ data }) => {
            this.disclaimer = get(data, [0, 'text']);
          }
      ).catch(() => Promise.resolve());
    }
  },
  methods: {
    goTo(page: string) {
      this.$store.commit('layout/setPage', getRouteName(page));
    }
  }
});
