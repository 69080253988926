

















import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "PromoBox",
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'code-slash'
    }
  },
  computed: {
    ...mapState('layout', ['darkMode'])
  }
});
