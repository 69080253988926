





































import CurrencyInput from "./CurrencyInput.vue";
import { dynamicModal } from "@/helpers";
import { mapState } from "vuex";
import { get, isFinite } from 'lodash-es';
import { CurrencyPair } from "@/types";
export default dynamicModal.extend({
  name: "EntryPriceSelector",
  components: { CurrencyInput },
  data: () => ({
    value: 0
  }),
  computed: {
    isLong: {
      get(): boolean {
        return get(this.pair, ['isSelling']) || false;
      },
      set(value: boolean) {
        this.updatePair({ isSelling: value }); 
      }
    },
    pair(): CurrencyPair | null {
      return this.currentPairs.find((cp: CurrencyPair) => cp.id === this.editingPairId);
    },
    ...mapState('trade', ['editingPairId', 'currentPairs']),
    currentPrice(): number | null {
      return get(this.currentPairs.find((cp: CurrencyPair) => cp.id === this.editingPairId), ['entryPrice']);
    }
  },
  mounted() {
    if (!this.currentPrice) {
      this.$http.get(`api/prices?currency_pairs=[${this.editingPairId}]`)
          .then(({ data }) => this.value = get(data, [0, 'close']))
          .catch(() => Promise.resolve());
    } else {
      this.value = this.currentPrice;
    }
  },
  methods: {
    updatePair(update: Partial<Record<keyof CurrencyPair, any>>) {
      if (this.pair) {
        Object.assign(this.pair, update);
        const pairs = this.currentPairs.slice(0);
        const index = pairs.findIndex((cp: CurrencyPair) => cp.id === this.pair?.id);
        if (index > -1) {
          pairs.splice(index, 1, this.pair);
          this.$store.commit('trade/setProp', {
            prop: 'currentPairs',
            value: pairs
          });
          this.$store.dispatch('auth/saveUserState'); 
        }
      }
    },
    clearPrice() {
      this.updatePair({ entryPrice: null });
      this.closeModal();
    },
    setEntryPrice() {
      if (isFinite(+this.value)) {
        this.updatePair({
          entryPrice: +this.value
        });
      }
      this.closeModal();
    }
  }
});
