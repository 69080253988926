




























































































































import RavenLogo from '@/components/RavenLogo.vue';
import LockIcon from '@/components/LockIcon.vue';
import { mapGetters, mapState } from "vuex";
import { responsiveness } from "@/responsiveness";
import PairSelector from "@/components/shared/PairSelector.vue";
import TradingDateSelector from "@/components/shared/TradingDateSelector.vue";
import { dynamicModal } from "@/helpers";
import TradeLocked from "@/components/shared/TradeLocked.vue";
import LockRipple from "@/components/shared/LockRipple.vue";
import UserSelector from "@/components/shared/UserSelector.vue";

export default dynamicModal.extend({
  name: "NavBar",
  components: {
    UserSelector,
    LockRipple,
    TradeLocked,
    RavenLogo,
    LockIcon,
    // eslint-disable-next-line vue/no-unused-components
    PairSelector,
    TradingDateSelector
  },
  computed: {
    ...mapState('layout', ['page','isLeftSidebarOpen', 'isRightSidebarOpen']),
    ...mapState('auth', ['auth', 'isLoadingUser', 'user', 'selectedUser']),
    ...mapGetters('auth', ['isLoggedIn', 'isGoogleUser', 'isAdmin']),
    isTradingLocked: {
      get(): boolean { return this.$store.state.trade.isTradingLocked; },
      set(value: boolean) { this.$store.commit('trade/setProp', { prop: 'isTradingLocked', value }); }
    },
    userMenu() {
      return (this.isGoogleUser
              ? [{
                name: 'Show Profile',
                action: () => this.openModal('user-profile')
              }]
              : [{
                name: 'Edit Profile',
                action: () => this.openModal('user-profile')
              }, {
                name: 'Change Password',
                action: () => this.openModal('change-password')
              }]
      ).concat({
        name: 'Logout',
        action: () => this.$store.dispatch('auth/logout')
      });
    },
    responsiveness: () => responsiveness,
    isTradePage(): boolean {
      return this.page === 'trade';
    },
    darkMode: {
      get(): boolean {
        return this.$store.state.layout.darkMode;
      },
      set(mode: boolean) {
        this.$store.commit('layout/setDarkMode', mode);
        this.$store.dispatch('auth/saveUserState'); 
      }
    }
  },
  methods: {
    toggleLock(): void {
      this.openModal(this.isTradingLocked ? 'locked' : 'unlocked');
    },
    goHomeDefault() {
      this.$router.push('/');
      this.$store.commit('layout/setPage', 'home');
    },
    goHome() {
      this.$router.push('/');
      this.$store.state.layout.page = 'home';
      this.$store.commit('auth/setProp', { prop: 'userAccountMessage', value: [] });
      this.$store.commit('trade/setProp', { prop: 'isLoadingData', value: true }, { root: true });
    }
  }
});
