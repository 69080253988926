





import { dynamicModal } from "@/helpers";
import { mapGetters, mapState } from "vuex";

export default dynamicModal.extend({
  name: "CloseModal",
  props: {
    target: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('auth', ['showSuccessModal']),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    async close() {
      if(this.target) {
        this.$bvModal.hide(this.target);
      } else {
        this.closeModal();
      }
      if(await this.isLoggedIn){
        this.$store.commit('auth/setProp', { prop: 'isReady', value: true });
        this.$store.commit('auth/setProp', { prop: 'userAccountMessage', value: this.isLoggedIn ?  [] : this.showSuccessModal === true ? {} : [] });
      }
    }
  }
});
