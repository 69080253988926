



















import Vue from "vue";
import { mapState } from "vuex";
import { v4 } from "uuid";
export default Vue.extend({
  name: "TradeLocked",
  props: {
    condition: {
      type: Boolean as () => boolean | null,
      default: () => null
    },
    lockedTitle: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    uuid: v4()
  }),
  computed: {
    ...mapState('trade', ['isTradingLocked', 'lockTexts']),
    localCondition(): boolean {
      return this.condition === null
          ? this.isTradingLocked
          : this.condition;
    },
    localTitle(): string {
      return this.lockedTitle || (this.isTradingLocked
          ? this.lockTexts.lockedTooltip
          : this.lockTexts.unlockedTooltip);
    }
  }
});
