














































































































import GridBox from '@/components/shared/GridBox.vue';
import SimulationControls from "@/components/shared/SimulationControls.vue";
import { isEqual, merge } from "lodash-es";
import { mapGetters, mapState } from "vuex";
import { Allocation, DailyAllocation, ISimulation, SimulationControl, SimulationRecord } from "@/types";
import { get } from "lodash";
import { dynamicModal, formatValue } from "@/helpers";
import LoadingAnimation from "@/components/shared/LoadingAnimation.vue";
import DynamicModalContent from "@/components/shared/ModalContent.vue";

export default dynamicModal.extend({
  name: "SimulationChart",
  components: {
    DynamicModalContent,
    LoadingAnimation,
    GridBox,
    SimulationControls,
  },
  props: {
    isSimple: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array as () => Record<string, any>[],
      default: () => []
    }
  },
  data: function(){ return {
    legendColors: ['#FF5500', '#808080'],
    legendColorsPalette: ['#2B908F','#F9A3A4','#90EE7E','#FA4443','#69D2E7'],
    inactiveLegend: [false,false],
    showCurrencyPairs: false,
    showControls: false,
    isBottomChartVisible: false,
    selectionStart: new Date('26 Aug 2019').getTime(),
    selectionEnd: new Date('30 Jul 2020').getTime(),
    loading: false,
    simulationChartText: ''
  }; },
  computed: {
    ...mapState('layout', ['darkMode']),
    ...mapState('trade', ['accountCurrency']),
    ...mapState('overview', ['simulationQuery', 'isSimulationReactive', 'simulations', 'simulationControls', 'simulationSetIndexDefault']),
    ...mapGetters('overview', ['dailyAllocations']),
    tableOptions(): Record<string, any> {
      return {
        bordered: false,
        dark: this.darkMode,
        borderLess: true,
        items: Object.values(this.simulations as Record<string, ISimulation>)
            .filter((o: ISimulation) => o && (this.isSimple ? o?.legend: !o?.legend))
            .map((o: ISimulation, index: number) => ({
              ...o.results,
              name: this.isSimple ? o.legend : undefined,
              color: this.isSimple ? index ? 'text-danger' : 'text-success' : ''
            })),
        fields: [
          {
            key: 'color',
            label: ''
          },
          {
            key: 'name',
            label: ''
          },
            'closing_cash',
          {
            label: 'CAGR',
            key: 'cagr'
          },
          'sharpe_ratio',
          'sortino_ratio',
          {
            key: 'max_drawdown_rate',
            label: 'Max drawdown %'
          },
          'average_profit_factor',
          'stop_loss_fill_rate'
        ].filter(
            (o: any) => this.isSimple
                ? !['average_profit_factor', 'stop_loss_fill_rate'].includes(o)
                : get(o, 'key') !== 'name'
        )
      };
    },
    chartOptions(): Record<string, any> {
      return {
        theme: {
          mode: this.darkMode ? "dark" : 'light',
        },
        chart: {
          type: "line",
          background: 'transparent',
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          foreColor: this.darkMode ? 'var(--muted)' : 'var(--text)',
        },
        grid: {
          show: true,
          strokeDashArray: 3,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined
          },
          column: {
            colors: undefined
          },
        },
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
          }
        }
      };
    },
    topChartOptions(): Record<string, any> {
      return merge({}, this.chartOptions, {
        legend: {
          show:false,
          fontSize: '15px',
          itemMargin: {
            horizontal: 20,
            vertical: 10
          },
          markers: {
            offsetX: '-8px'
          }
        },
        chart: {
          id: (this.isSimple ? '' : 'modal-') + 'chartyear',
          toolbar: {
            autoSelected: 'pan'
          },
          events: {
            mounted: ({ w }: any) => {
              if (w.globals.minX && this.selectionStart !== w.globals.minX) {
                this.selectionStart = w.globals.minX;
              }
              if (w.globals.maxX && this.selectionEnd !== w.globals.maxX) {
                this.selectionEnd = w.globals.maxX;
              }
            },
            updated: ({ w }: any) => {
              if (w.globals.minX && this.selectionStart !== w.globals.minX) {
                this.selectionStart = w.globals.minX;
              }
              if (w.globals.maxX && this.selectionEnd !== w.globals.maxX) {
                this.selectionEnd = w.globals.maxX;
              }
            }
          }
        },
        grid: {
          borderColor: "#ff550022",
        },
        xaxis: {
          min: this.showControls ? this.selectionStart : undefined,
          max: this.showControls ? this.selectionEnd : undefined,
          tickAmount: 1,
          axisTicks: {
            color: "var(--muted)"
          },
          title: {
            text: 'Trading date',
            offsetY: 5,
            style: {
              fontSize: '20px',
              color: 'var(--text)'
            }
          }
        },
        yaxis: {
          title: {
            text: this.showCurrencyPairs ? 'Base currency amount' : 'Closing cash',
            style: {
              fontSize: '20px',
              color: 'var(--text)'
            }
          }
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
          y: {
            title: {
              formatter: (name: string) => this.showCurrencyPairs ? name : 'Closing cash:'
            }
          }
        },
      }, this.chartColors);
    },
    bottomChartOptions(): Record<string, any> {
      return merge({}, this.chartOptions, {
        legend: {
          show: false
        },
        chart: {
          toolbar: {
            autoSelected: 'selection',
          },
          brush: {
            enabled: true,
            target: 'modal-chartyear',
            autoScaleYaxis: false
          },
          selection: {
            enabled: true,
            fill: {
              color: '#f50',
              opacity: .12
            },
            xaxis: {
              min: this.selectionStart,
              max: this.selectionEnd
            }
          },
        },
        grid: {
          borderColor: "rgba(255, 42, 0, .21)",
          strokeDashArray: 1,
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            opacity: 0.35,
          }
        },
        stroke: {
          width: 1,
          curve: 'smooth'
        },
        xaxis: {
          axisTicks: {
            color: "rgba(255, 80, 0, .42)"
          }
        },
        yaxis: {
          show: false
        }
      }, this.chartColors);
    },
    series(): any[] {
      return this.showCurrencyPairs ? this.allocationSeries : this.cashSeries;
    },
    cashSeries(): any[] {
      return Object.values(this.simulations)
          .filter((s: any) => s && (this.isSimple ? s?.legend : !s?.legend))
          .map((s: any) => ({
            name: this.isSimple ? s.legend : 'Closing cash',
            data: s.results.daily_results.map((o: SimulationRecord) => ({
              x: o.trade_date,
              y: o.closing_cash,
            })),
            xaxis: {
              type: "datetime",
            }
          }));
    },
    allocationSeries(): any[] {
      return this.dailyAllocations.length
          ? this.dailyAllocations[0].allocations.map((a: Allocation, k: number) => ({
            name: a.currency_pair,
            xaxis: {
              type: "datetime"
            },
            data: this.dailyAllocations.map((o: DailyAllocation) => ({
              x: o.trade_date,
              y: o.allocations[k].base_currency_amount
            }))
          }))
          : [];
    },
    chartColors:{
      get(): any {
        return this.showCurrencyPairs ? {
          theme: {
            palette: 'palette5'
          },
          chart: {
            type: 'line'
          },
          fill: {
            opacity: 1,
            type: 'solid'
          },
          colors: undefined
        } : {
          colors: ['#FF5500', '#808080'],
          fill: {
            opacity: 1,
            type: 'solid'
          },
          stroke: {
            width: 2
        }
      };
      },
      set(value: any) {
        (this.$refs.simulationChartTop as any).updateOptions({ colors: value, });
      }
    }
  },
  watch: {
    showControls(val) {
      setTimeout(() => {
        this.isBottomChartVisible = val;
      }, val ? 123 : 0);
    },
    simulationQuery(newVal, oldVal) {
      if (!isEqual(newVal, oldVal) && this.isSimulationReactive && !this.isSimple && (oldVal !== {})) {
        this.fetchSimulation(newVal);
      }
    },
    series: {
      handler() {
        this.series.forEach((item, index) => {
          this.inactiveLegend[index] = false;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (!this.isSimple) {
      this.$http.get('api/simulation_chart_text')
          .then(({ data }) => {
                this.simulationChartText = get(data, [0, 'text']);
              }
          )
          .catch(() => Promise.resolve());
    }
  },
  methods: {
    changeSimulationSetIndex(n: number) {
      const values = this.simulationControls.find((c: SimulationControl) => c.parameter === 'simulation_set_id')?.values;
      this.$store.commit('overview/setProp', {
        prop: 'simulationSetIndexDefault',
        value: (this.simulationSetIndexDefault + n + values.length) % values.length
      });
    },
    getCellClasses({ item, field, index }: any) {
      return {
        'text-center': Boolean(field.label),
        [item.color]: Boolean(field.label),
        'value-item': Boolean(field.label),
        'text-primary': !(index || field.label)
      };
    },
    getCellValue({ field, value }: any) {
      switch (field.key) {
        case 'color':
          return '';
        case 'name':
          return value;
        case 'closing_cash':
          return formatValue(value, {
            style: 'currency',
            currency: typeof this.accountCurrency == 'object' ? this.accountCurrency.code : this.accountCurrency,
            maximumFractionDigits: 0
          });
        case 'stop_loss_fill_rate':
        case 'cagr':
        case 'max_drawdown_rate':
          return formatValue(value, {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          });
        default:
          return formatValue(value);
      }
    },
    fetchSimulation(params: Record<string, any>) {
      this.loading = true;
      this.$http.post('api/simulations', params)
          .then(({ data }) => {
            this.loading = false;
            if (get(data, 'length')) {
              this.$store.commit('overview/setCurrentSimulation', data[0]);
            }
          })
          .catch(() => Promise.resolve());
    },
    toggleSeriesName(name: Record<string, any>)  {
      const seriesIndex = this.series.findIndex((item) => {
        return item.name === name;
      });
      this.inactiveLegend[seriesIndex] = !this.inactiveLegend[seriesIndex];
      (this.$refs.simulationChartTop as any).toggleSeries(name);
    },
    toggleSeriesOpacityMouseEnter(name: Record<string, any>)  {
      const colorsArray = [] as any;
      const seriesArray = [] as any;
      this.series.forEach((item, index) => {
        if(item.name !== name){
            seriesArray.push(index);
        }
        if(this.showCurrencyPairs){
          colorsArray.push(this.legendColorsPalette[index % 5]);
        }else{
          colorsArray.push(this.legendColors[index]);
        }

      });
      seriesArray.forEach((item : any) => {
          colorsArray[item] = colorsArray[item].replace('#','');
          colorsArray[item] = this.hextoRGBA(colorsArray[item],this.showCurrencyPairs ? 0.15 :0.45);
          this.chartColors = colorsArray;
        }
      );
    },
    toggleSeriesOpacityMouseLeave()  {
      this.legendColors = ['#FF5500', '#808080'];
      this.chartColors = this.showCurrencyPairs ? ['#2B908F','#F9A3A4','#90EE7E','#FA4443','#69D2E7'] :  ['#FF5500', '#808080'];
      this.legendColorsPalette = ['#2B908F','#F9A3A4','#90EE7E','#FA4443','#69D2E7'];
    },
    hextoRGBA(color: string, opacity: number): string {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return '#'+color + _opacity.toString(16).toUpperCase();
    }
  }
});
