













































import { mapState } from "vuex";
import { dynamicModal } from "@/helpers";
export default dynamicModal.extend({
  name: "ForgotPasswordForm",

  data: () => ({
    form: {
      username: '',
    },
    errors:'',
    success: '',
  }),
  computed: {
    ...mapState('auth', ['hasRenderedLogin'])
  },
  methods: {
    sendRequestResetPassword() {
      this.errors = "";
      this.$http.get(`/api/reset_password_request?email=${this.form.username}&reset_password_url=${window.location.origin}/reset_password`)
          .then(({ data }) => {
              this.success = data[0].message;
          })
          .catch((error) => this.errors = error.response.data[0].message);
    },
    goTo() {
      this.$router.push('/');
      this.$store.commit('layout/setPage','home');
    }
  }
});
