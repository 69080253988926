















import Vue from "vue";
import { Coin, getCoin } from "@/helpers";
import { get } from "lodash-es";
import { mapState } from "vuex";
import AutoComplete from "@/components/shared/AutoComplete.vue";
export default Vue.extend({
  name: "CurrencySelector",
  components: {
    AutoComplete,
  },
  props: {
    options: {
      type: Array as () => {
        label: string;
        category: string;
        code: string;
        decimal_digits: number;
        name: string;
        name_plural: string;
        rounding: number;
        symbol: string;
        symbol_native: string;
      }[],
      default: () => [],
    },
    value: {
      type: [String, Number, Array, Object],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    selector: "",
    refValue: undefined,
  }),
  computed: {
    ...mapState("trade", [
      "currencyPairs",
      "allPairs",
      "apiCurrencies",
      "isLoadingData",
      "lockTexts",
      "defaultValues",
      "selectedValue",
    ]),
    isEmpty(): boolean {
      return !this.value;
    },
  },
  watch: {
    value: {
      handler(val) {
        const  value = val.length ? val[0]: val;
          this.$nextTick(() => {
            if (
              value  &&
              this.getResult(value) !== get(this, ["$refs", "auto", "value"])
            ) {
              (get(this, ["$refs", "auto"]) as any)?.setValue(value);
            }
          });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    search(input: string): (Coin | { label: string })[] {
      return this.options.map((c: any) => c)
        .filter(
          (i: Record<string, any>) =>
        i.name.toLowerCase().includes(input.toLowerCase()) ||
            i.code.toLocaleLowerCase().includes(input.toLowerCase()) ||
            i.symbol.toLowerCase().includes(input.toLowerCase()) ||
            this.value.code === input.substring(0,input.indexOf(' ')) 
        );
    },
    getResult(coin: Coin) {
      if (coin) {
        return typeof coin === "object"
          ? `${coin.code} - ${coin.name}`
          : `${getCoin(coin).code} - ${getCoin(coin).name}`;
      }
    },
    submit(coin: Coin) {
      this.$emit("update:value", coin);
    },
  },
});
