
































































import Vue from 'vue';
import { ISignupStep } from "@/types";
import { Power2, TweenMax } from "gsap";
import { mapGetters, mapState } from "vuex";
import { TranslateResult } from "vue-i18n";
import GridBox from '@/components/shared/GridBox.vue';

const checkmarkPoints = [[-3, 4.2], [-7.2, 0], [-8.4, 1.4], [-3, 7], [9, -5], [7.6, -6.4], [-3, 4.2]];
export default Vue.extend({
  name: "StepIndicator",
  components: {
    GridBox
  },
  props: {
    steps: {
      type: Array as () => ISignupStep[],
      default: () => []
    },
    stepIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    fillPosition: 0
  }),
  computed: {
    ...mapState('layout', ['darkMode']),
    ...mapGetters('layout', ['activeSteps']),
    stepNames(): (string | TranslateResult)[] {
      return this.steps.map(s => s.title);
    },
    stepPositions(): { x: number; y: number }[] {
      const l = this.steps.length;
      const w = 380;
      return this.steps.map((c: ISignupStep, i: number) => ({
        x: (l > 1 ? w * i / (l - 1) : w / 2) + 60,
        y: 35
      }));
    }
  },
  watch: {
    stepIndex: {
      handler(index) {
        setTimeout(() => {
          this.animateTo(this.stepPositions[Math.min(index, this.stepPositions.length - 1)].x);
        }, 123);
      },
      immediate: true
    }
  },
  methods: {
    drawCirclePath(x: number, y: number, r: number): string {
      return [
        `M${x - r},${y}`,
        `a${r},${r} 0 1,1 ${2 * r},0`,
        `${r},${r} 0 1,1 ${-2 * r},0`
      ].join(' ');
    },
    drawAxis() {
      const sp = this.stepPositions;
      return sp.filter((d, i) => i).map((d, i) =>
          `M${sp[i].x + 50} 34 h ${sp[i + 1].x - sp[i].x - 100} v 2 h -${sp[i + 1].x - sp[i].x - 100}`).join(' ') + 'Z';
    },
    drawCircles() {
      return this.stepPositions
              .map(c => this.drawCirclePath(c.x, c.y, 16))
              .join('')
          + this.drawAxis();
    },
    drawContours() {
      return this.stepPositions
          .map(c => this.drawCirclePath(c.x, c.y, 16))
          .join('');
    },
    checkMark(x: number, y = 35) {
      return checkmarkPoints.map(
          p => [x + p[0], y + p[1]].join(',')
      ).join(' ');
    },
    stepTitleColor(key: number): Record<string, any> {
      const hard = this.darkMode ? 'var(--text)': 'var(--text)';
      const soft = this.darkMode ? 'var(--muted)': 'var(--text)';
      const curr = 'var(--primary)';
      return {
        transition: 'color .42s cubic-bezier(.4,0,.2,1), border-color .42s cubic-bezier(.4,0,.2,1)',
        color: key <= this.stepIndex
            ? key === this.stepIndex ? curr : hard
            : soft
      };
    },
    animateTo(to: number) {
      const subject = {
        position: this.fillPosition
      };
      TweenMax.to(subject, this.fillPosition ? .65 : .21, {
        position: to,
        ease: Power2.easeOut,
        onUpdate: () => {
          this.fillPosition = subject.position;
        }
      });
    },
    goToStep(index: number) {
      if (this.activeSteps[index]) {
        this.$store.commit('layout/setStepIndex', index);
      }
    }
  }
});
