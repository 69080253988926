

































import Vue from "vue";
import { IInputControl } from "@/types";
import { mapState } from "vuex";
import InputCard from "@/components/shared/InputCard.vue";
import AdvancedControls from "@/components/trade/AdvancedControls.vue";

export default Vue.extend({
  name: "CapitalControls",
  components: {
    InputCard,
    AdvancedControls
  },
  props: {
    showAdvanced: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('trade', ['accountCurrency', 'apiCurrencies','defaultValues']),
    inputControls(): Promise<IInputControl[]> {
      return this.defaultValues.filter((i:IInputControl) => !i.advanced && !i.exclude);
    },
    advancedControls(): IInputControl[] {
      return this.defaultValues.filter((i: IInputControl) => i.advanced && !i.exclude);
    },
  },
});
