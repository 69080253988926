import { IUserData } from "@/types/index";
import { get, omit, pick } from 'lodash-es';

let password = '';

export class User {
  private data: IUserData;

  constructor(data: IUserData) {
    this.data = omit(data, ['password']);
    password = data.password || '';
  }

  get isGoogleUser(): boolean {
    return this.data.google_registration;
  }

  get givenName(): string {
    return this.getProp('givenName') || this.getProp('first_name');
  }

  get first_name(): string {
    return this.givenName;
  }

  get familyName(): string {
    return this.getProp('familyName') || this.getProp('second_name');
  }

  get second_name(): string {
    return this.familyName;
  }

  get username(): string {
    return this.getProp('email');
  }

  get email(): string {
    return this.username;
  }

  get password(): string {
    return password;
  }

  get backup_email(): string {
    return this.getProp('backup_email');
  }

  get phone(): string {
    return this.getProp('phone');
  }

  get imageUrl(): string {
    return this.getProp('imageUrl');
  }

  getProp(prop: string): string {
    return get(this.data, [prop]) || '';
  }

  updateData(val: Partial<IUserData>): void {
    this.data = {
      ...this.data,
      ...pick(val, ['phone', 'backup_email', 'role'])
    };
  }

  get isAdmin(): boolean {
    return this.data.role === 'admin';
  }

  get createRequest():Record<string, any> {
    return {
      ...pick(this, [
        'first_name',
        'second_name',
        'phone',
      ]),
      native_password: this.isGoogleUser
        ? undefined
        : this.password,
      google_password: this.isGoogleUser
        ? this.password
        : undefined
    };
  }

  get updateRequest(): Record<string, any> {
    return pick(this, [
    'first_name',
    'second_name',
    'google_registration',
    'native_registration',
    'phone',
    'backup_email'
    ]);
  }
}
