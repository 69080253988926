













import Vue from 'vue';

export default Vue.extend({
  name: "NumericSlider",
  props: {
    append: {
      type: String,
      default: ''
    },
    prepend: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 50
    },
    slider: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    computedValue: {
      get(): number {
        return this.value || 0;
      },
      set(value: number) {
        this.$emit('update:value', value);
      }
    },
    formattedValue(): string {
      return typeof this.slider.tooltipFormatter === 'function'
          ? this.slider.tooltipFormatter(this.value)
          : `${this.slider.prepend || ''}${this.value}${this.slider.append || ''}`;
    }
  }
});
