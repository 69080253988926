







import Vue from "vue";
export default Vue.extend({
  name: "LoadingAnimation",
  data: () => ({
    duration: 2.4,
    steps: [
        { r: 0 },
        { r: 0 },
        { r: 0 },
        { r: 10 },
        { r: 40 },
        { r: 62 },
        { r: 72 }
    ]
  })
});
