import { render, staticRenderFns } from "./SsDashboard.vue?vue&type=template&id=724b3002&scoped=true&"
import script from "./SsDashboard.vue?vue&type=script&lang=ts&"
export * from "./SsDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./SsDashboard.vue?vue&type=style&index=0&id=724b3002&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724b3002",
  null
  
)

export default component.exports