































































import LoginForm from "@/components/shared/LoginForm.vue";
import { mapGetters, mapState } from "vuex";
import { dynamicModal, getRouteName } from "@/helpers";
import RavenDivider from "@/components/shared/RavenDivider.vue";
export default dynamicModal.extend({
  name: "Home",
  components: {
    RavenDivider,
    LoginForm,
  },
  data: () => ({
    email: ''
  }),
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isAdmin']),
    ...mapState('trade', ['isLoadingData'])
  },

  methods: {
    deleteUser() {
      this.$store.dispatch('auth/deleteUser', this.email);
    },
    goTo(page: string) {
      this.$store.commit('layout/setPage',getRouteName(page));
    },
    goToPage(page: string) {
       this.$router.push(page);
    }
  }
});
