




























































































































































































import { mapGetters, mapState } from "vuex";
import { dynamicModal } from "@/helpers";

export default dynamicModal.extend({
  name: "ChangePassword",
  data: () => ({
    form: {
      oldPass: '',
      newPass: '',
      repeatPass: ''
    },
    showCurrentPass: false,
    showNewPass: false,
    showSuccess: false,
    success: '',
    errors:'',
    invalidToken: '',
    email: ''
  }),
  computed: {
    
    ...mapGetters('auth', ['username','isLoggedIn']),
    ...mapState('auth', ['userAccountMessage','showSuccessModal']),
  },
  watch:{
    userAccountMessage: {
      handler(val): void {
        this.changePassword();
      },
    },
  },
  async destroyed(){
    if(await this.isLoggedIn){
      this.$store.commit('auth/setProp', { prop: 'showSuccessModal', value: false });
      this.$store.commit('auth/setProp', { prop: 'userAccountMessage', value: this.isLoggedIn ?  [] : this.showSuccessModal === true ? {} : [] });
    }
  },
  mounted(){
    if(this.$route.query.reset_password_token && !this.isLoggedIn &&  this.invalidToken === ''){
      this.$http.get(`/api/reset_password_validation?reset_password_token=${this.$route.query.reset_password_token}`)
        .then(({ data }) => {
          this.email = data[0].email;
          this.invalidToken = 'valid';
        })
        .catch((error ) => { 
          this.invalidToken = error.response.data[0].message;
        });
      }
  },
  methods: {
    goTo() {
      this.$router.push('/');
      this.$store.commit('layout/setPage','home');
    },
    async changePassword() {
      if(await this.isLoggedIn){
        this.$store.dispatch('auth/setNewPassword', this.form);
        const userMessage = await this.userAccountMessage;
        if(typeof userMessage === 'object' && Object.keys(userMessage).length ){
          this.showSuccess = true;
        }
      }else{
          if(this.$route.query.reset_password_token && this.email){
              this.$http.put(`api/users/${this.email}`,{ native_password: this.form.newPass })
            .then(({ data }) => {
                this.success = data[0].message;
            })
            .catch((error) => {
              this.errors = error.response.data[0].message;
            });
          }
        
      }
    }
  }
});
