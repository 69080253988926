































































































































import { mapGetters, mapState } from "vuex";
import AnimatedScore from "@/components/overview/AnimatedScore.vue";
import SimulationChart from "@/components/shared/SimulationChart.vue";
import RadarChart from "@/components/trade/RadarChart.vue";
import OverviewHealthCheck from "@/components/overview/OverviewHealthCheck.vue";
import BellCurveChart from "@/components/shared/BellCurveChart.vue";
import ColumnScroller from "@/components/ColumnScroller.vue";
import { responsiveness } from "@/responsiveness";
import Benefits from "@/components/overview/Benefits.vue";
import { get, omit } from 'lodash-es';
import ModalContent from "@/components/shared/ModalContent.vue";
import OverviewFooter from "@/components/overview/OverviewFooter.vue";
import DashboardPreview from "@/components/overview/DashboardPreview.vue";
import { dynamicModal, getRouteName } from "@/helpers";
export default dynamicModal.extend({
  name: 'Overview',
  components: {
    OverviewFooter,
    ModalContent,
    Benefits,
    ColumnScroller,
    BellCurveChart,
    RadarChart,
    AnimatedScore,
    SimulationChart,
    OverviewHealthCheck,
    DashboardPreview
  },
  data: () => ({
    loadingSimulation: false,
    showContent: false,
  }),
  computed: {
    ...mapState('trade', ['currentPairs', 'stopLosses', 'positionSizing']),
    ...mapGetters('trade', ['overallLiquidityScore', 'overviewHealthChecks', 'weightedScore', 'severityColor']),
    ...mapState('layout', ['stepIndex', 'page']),
    ...mapState('overview', ['summary', 'summaryTopSection', 'summaryBellCurveChart', 'summarySimulationChart', 'benefits', 'simulations', 'ssDetails']),
    ...mapGetters('auth', ['isLoggedIn','username']),
    simulationAssumptionsContent: {
      get(): string { return this.$store.state.overview.simulationAssumptionsContent; },
      set(value: string) { this.$store.commit('overview/setProp', { prop: 'simulationAssumptionsContent', value }); }
    },
    showAdvancedSimulation: {
      get(): boolean { return this.$store.state.overview.showAdvancedSimulation; },
      set(value: boolean) { this.$store.commit('overview/setProp', { prop: 'showAdvancedSimulation', value }); }
    },
    responsiveness: () => responsiveness
  },
  async mounted() {
    await this.getDataFromApi();
    if (!this.benefits.length) {
      this.$http.get('api/benefits_text')
          .then(({ data }) => {
                this.$store.commit('overview/setProp', { prop: 'benefits', value: data });
              }
          ).catch(() => Promise.resolve());
    }
    if (!(this.simulations.good && this.simulations.bad)) {
      this.$http.get('api/simulation_performance_comparison')
          .then(({ data }) => {
            this.$store.commit('overview/setSimulations', data);
          }).catch(() => Promise.resolve());
    }
    if (!this.simulationAssumptionsContent) {
      this.$http.get('api/simulation_performance_comparison_text')
          .then(({ data }) => {
            this.simulationAssumptionsContent = get(data, [0, 'text']);
          }).catch(() => Promise.resolve());
    }
    if (!this.ssDetails.length) {
      this.$http.get('api/features_text')
          .then(({ data }) => {
            this.$store.commit('overview/setProp', {
              prop: 'ssHeading',
              value: get(data, [0])
            });
            this.$store.commit('overview/setProp', {
              prop: 'ssDetails',
              value: get(data, [1]).map((el: any, i: number) => ({
                ...omit(el, ['feature_text']),
                position: i + 1,
                text: el.feature_text
              }))
            });
            return Promise.resolve();
          }).catch(() => Promise.resolve());
    }
  },
  methods: {
    async getDataFromApi(){
      this.showContent = false;
       if (!this.summaryTopSection.length) {
        await this.$http.get('api/summary_page_boiler_plate_text')
          .then(({ data }) => {
            const sections = ['summaryTopSection','summaryBenefits' , 'summaryProduct', 'summaryBellCurveChart','summarySimulationChart','summaryFooter'];
              const sectionsWithItems: Array<string | number>= [];
              this.$store.commit('overview/setProp', { prop: 'summary', value: data[0].sections });
              for (let i = 0; i <= data[0].sections.length -1; i++) {
                if(Object.keys(data[0].sections[i]).length > 0){
                  sectionsWithItems.push(data[0].sections[i]);
                }
              }     
              sectionsWithItems.forEach((row,i:number) => {
                this.$store.commit('overview/setProp', { prop: sections[i], value: row });
              });
              this.showContent = true;
            }
          ).catch(() => Promise.resolve());
       }
    },
    goTo(page: string) {
      this.$store.commit('layout/setPage',getRouteName(page));
    }
  }
});
