import { startCase } from 'lodash-es';
import { payloads } from "@/helpers";
import { $store } from "@/main";

export interface IHealthCheck extends IWarning {
  id: string;
  title?: string;
  score: number | null;
  position: number;
  weight: number;
  weighted_score: number;
  currency_pair?: IWarning[];
  currency_pair_combination?: IWarning[];
}

export interface IWarning {
  prefix?: string;
  heading: string;
  impact: string | null;
  remedial_action: string | null;
  severity: number;
  severity_label: string;
}

export class HealthCheck implements IHealthCheck {
  private readonly _data: IHealthCheck;

  constructor(data: IHealthCheck) {
    this._data = data;
  }

  get id(): string {
    return this._data.id;
  }

  get impact(): string {
    return this._data.impact || '';
  }

  get heading(): string {
    return this._data.heading || startCase(this.id);
  }

  get title(): string {
    return this._data.title || this.heading;
  }

  get index(): number {
      return payloads.findIndex(({ prefix }) => prefix === this.id);
  }

  get position(): number {
    return this._data.position;
  }

  get score(): number | null {
    return this.storeCondition ? this._data.score : null;
  }

  get storeCondition(): any {
    return $store.getters[`trade/${this._data.id}_condition`];
  }

  get weight(): number {
    return this._data.weight;
  }

  get weightedScore(): number {
    return this._data.weighted_score;
  }

  get severity(): number {
    return this._data.severity;
  }

  get records(): IWarning[] {
    return this._data.currency_pair_combination || this._data.currency_pair || [];
  }

  get severity_label(): string {
    return this._data.severity_label;
  }

  get remedial_action(): string {
    return this._data.remedial_action || '';
  }

  get weighted_score(): number {
    return this._data.weighted_score;
  }

  get warnings(): IWarning[] {
        return [this._data as IWarning].concat(this.records)
            .filter((el: any) => (el.remedial_action || el.impact))
            .map((el: any) => ({
                ...el,
                prefix: this.id
            }));
  }
}
