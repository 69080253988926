












import Vue from 'vue';
import { Power2,gsap } from "gsap";
export default Vue.extend({
  name: "ColumnScroller",
  props: {
    hasScrollbar: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$bus.$on('scroll:top', () => {
      const s = this.$refs.scroll as any;
      if (s instanceof Vue && s.$el.id === 'main-scroll') {
        const $el = s.$el as HTMLElement;
        const st = $el.scrollTop;
        if (st) {
          const subject = { st };
          gsap.to(subject, {
            st: 0,
            ease: Power2.easeOut,
            duration:.2,
            onUpdate: () => {
              $el.scrollTop = subject.st;
            }
          });
          $el.scrollTop = 0;
          this.$nextTick(() => {
            s.ps.update();
          });
        }
      } else {
        const main = document.querySelector('main');
        if (main) {
          main.scrollTop = 0;
        }
      }
    });
  }
});
