






































import Vue from 'vue';
import { camelCase } from 'lodash-es';
import { HealthCheck, IWarning } from "@/types/HealthCheck";
import GridBox from '@/components/shared/GridBox.vue';
import WarningBox from '@/components/trade/WarningBox.vue';
import { severityLevels } from "@/helpers";
import { mapGetters, mapState } from "vuex";
import HealthCheckMenu from "@/components/trade/HealthCheckMenu.vue";
import { get, initial, last } from 'lodash-es';

export default Vue.extend({
  name: "HealthCheckCard",
  components: {
    GridBox,
    WarningBox,
    HealthCheckMenu
  },
  props: {
    check: {
      type: Object as () => HealthCheck,
      required: true
    },
    hasHeader: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    closed: true,
    isFocused: false,
    showHiddenWarnings: false
  }),
  computed: {
    ...mapState('layout', ['focusedHC']),
    ...mapGetters('trade', ['severityColor']),
    formattedScore(): string {
      return new Intl.NumberFormat('en-US', { style: 'percent' }).format(Number(this.check.score));
    },
    visibleWarnings(): IWarning[] {
      return this.check.warnings.filter(w => this.isWarningVisible(w));
    },
    hiddenWarnings(): IWarning[] {
      return this.check.warnings.filter(w => !this.isWarningVisible(w));
    },
    hiddenWarningsLabels(): string {
      const labels = [...new Set(this.hiddenWarnings.map(w => w.severity))]
          .map(n => severityLevels[n].label.toLowerCase());
      return `${
          initial(labels).length ? initial(labels).join(', ') + ' and' : ''
      } ${
        labels.length ? last(labels) + ' risk' : ''
      }`;
    },

    isWarningsToggleVisible(): boolean {
      return !this.closed && !!this.check.records.length && (this.visibleWarnings.length < this.check.warnings.length);
    },
    warningToggleText(): string {
      return `${
        this.showHiddenWarnings ? 'Hide' : 'Show'
      } ${
        this.check.warnings.length - this.visibleWarnings.length
      } ${
        this.hiddenWarningsLabels
      } ${
        this.showHiddenWarnings ? '' : 'hidden '
      }warnings`;
    },
    severityThreshold: {
      get(): number{
        return this.$store.state.trade[camelCase(`severity_${this.check.id}`)]  ? this.$store.state.trade[camelCase(`severity_${this.check.id}`)] : 1 ;
      },
      set(value: number) {
        if(this.$store.state.trade[camelCase(`severity_${this.check.id}`)]){
          this.$store.commit(`trade/setProp`, {
            prop: camelCase(`severity_${this.check.id}`),
            value: value
          });
          this.$store.dispatch('auth/saveUserState');
        }
      }
    },
  },
  watch: {
    focusedHC(val) {
      if (val === this.check.id) {
        this.closed = false;
        setTimeout(() => {
          this.isFocused = true;
        }, 700);
      } else {
        this.isFocused = false;
      }
    },
    severityThreshold() {
      this.showHiddenWarnings = false;
    }
  },
  methods: {
    toggle() {
      this.closed = !this.closed;
      if (!closed) {
        this.$store.commit('layout/setFocusedHC', null);
      }
    },
    isWarningVisible(warning: IWarning) {
      return !warning.severity || warning.severity >= this.severityThreshold;
    },
    toggleHiddenWarnings() {
      this.showHiddenWarnings = !this.showHiddenWarnings;
      if (!this.showHiddenWarnings) {
        this.scrollUp();
      }
    },
    scrollUp() {
      this.$emit('scrollUp', this.check.warnings
          .map((warning, index) => ({ warning, index }))
          .filter(el => !this.isWarningVisible(el.warning))
          .map(el => get(this.$refs.warns, [el.index])?.offsetHeight || 0)
          .reduce((a, b) => a + b));
    }
  }
});
