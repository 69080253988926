



























import Vue from 'vue';
import { get } from 'lodash-es';
import { InputOption } from "@/types";
import { BDropdown } from "bootstrap-vue";

export default Vue.extend({
  name: 'SelectInput',
  props: {
    options: {
      type: Array as () => InputOption[],
      default: () => []
    },
    value: {
      type: [String, Object, Number],
      default: null,
      validator: (p) => {
        return ['string', 'number', 'object'].indexOf(typeof p) !== -1 || p === null;
      },
    }
  },
  methods: {
    text(val: string | number | Record<string, any>) {
      const value = get(val, 'value') || val;
      const option = this.options.find(o => value === (get(o, 'value') || o));
      return get(option, 'text') || get(option, 'value') || option;
    },
    setOptionValue(option: InputOption) {
      this.$emit('update:value', get(option, 'value') || option);
      const dd = this.$refs.select;
      if (dd instanceof BDropdown) {
        requestAnimationFrame(() => {
          dd.hide();
        });
      }
    },
  }
});
