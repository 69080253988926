























import Vue from "vue";
import { HealthCheck, IWarning } from "@/types";
import { groupBy } from "lodash-es";
import { getWarningColor } from "@/helpers";
import WarningBox from "@/components/trade/WarningBox.vue";

export default Vue.extend({
  name: "CorrelationsList",
  components: {
    WarningBox
  },
  props: {
    healthCheck: {
      type: Object as () => HealthCheck,
      required: true
    }
  },
  data: () => ({
    openRisks: [] as string[]
  }),
  computed: {
    riskWarnings(): Record<string, IWarning[]> {
      return groupBy(this.healthCheck.warnings, 'severity_label');
    }
  },
  methods: {
    getWarningColor,
    openRisk(risk: string) {
      if (this.isRiskOpen(risk)) {
        this.openRisks = this.openRisks.filter(r => r !== risk);
      } else {
        this.openRisks.push(risk);
      }
      this.$emit('update-scroll');
    },
    isRiskOpen(risk: string) {
      return this.openRisks.includes(risk);
    }
  }
});
