import Vue from 'vue';

const intervals = {
    xs: [0, 576],
    sm: [576, 768],
    md: [768, 992],
    lg: [992, 1200],
    lgD:[1200, Infinity],
    lgT:[1200, 1370],
    xl: [1200, 1500],
    xxl: [1500, Infinity]
};

export const responsiveness: Record<string, boolean> = Vue.observable({
    xs: false,
    smDown: false,
    sm: false,
    smUp: false,
    mdDown: false,
    md: false,
    mdUp: false,
    lgDown: false,
    lg: false,
    lgUp: false,
    lgDDown: false,
    lgD: false,
    lgTUp: false,
    lgTDown: false,
    lgT: false,
    lgDUp: false,
    xlDown: false,
    xl: false,
    xlUp: false
});

export const updateIntervals = (width: number):  Record<string, boolean> & {[p: string]: boolean} => Object.assign(
    responsiveness,
    ...Object.entries(intervals).map(([key, value]) => ({
        [key + 'Up']: width >= value[0],
        [key]: width >= value[0] && width < value[1],
        [key + 'Down']: width < value[1]
})));
