<template>
  <b-container :fluid="true" class="home h-100">
    <b-row class="h-100 h-md-auto">
      <b-col class="h-100 h-md-auto"
             :xl="6"
             :offset-xl="3">
        <div class="h-100 ps h-md-auto">
          <div class="right-side py-5">
            <template v-if="!isLoadingData">
              <div class="home-box">
                <template v-if="!isLoggedIn">
                  <change-password />
                </template>
              </div>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script >
import ChangePassword from "@/components/shared/ChangePassword.vue";
import { mapGetters, mapState } from "vuex";
export default ({
  name: "ResetPassword",
  components: {
    ChangePassword,
  },
  data: () => ({
    email: ''
  }),
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isAdmin']),
    ...mapState('trade', ['isLoadingData'])
  },
  created(){
    if(this.isLoggedIn){
      this.$router.push("/dashboard");
    }
    if(!this.isLoggedIn && !this.$route.query.reset_password_token){
      this.$router.push("/");
    }
  },
});
</script>

