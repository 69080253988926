
















import Vue from 'vue';
import { mapState } from "vuex";
import CurrencyPairCard from "@/components/landing/CurrencyPairCard.vue";
import PairSelector from "@/components/shared/PairSelector.vue";

export default Vue.extend({
  name: "CurrencyPairs",
  components: {
    PairSelector,
    CurrencyPairCard
  },
  data: () => ({
    currencyPair: {
      base: '',
      quote: ''
    }
  }),
  computed: {
    ...mapState('trade', ['currentPairs']),
  },
  methods: {
    removeCurrencyPair(id: string) {
      this.$store.commit('trade/removeCurrencyPair', id);
      this.$store.dispatch('auth/saveUserState'); 
    }
  }
});
