




import Vue from 'vue';
import { Power2, TweenMax } from "gsap";
export default Vue.extend({
  name: "AnimatedNumber",
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    config: {
      type: Object,
      default: () => ({
        duration: .6,
        ease: Power2.easeOut,
        rounding: 1
      })
    }
  },
  data() {
    return {
      localValue: +this.value
    };
  },
  watch: {
    value: {
      handler(value) {
        const subject = { value: this.localValue };
        TweenMax.to(subject, this.config.duration, {
          value: +value,
          ease: this.config.ease,
          roundProps: {
            value: this.config.rounding
          },
          onUpdate: () => {
            this.localValue = subject.value;
          }
        });
      }
    }
  }
});
