






import { dynamicModal } from "@/helpers";
export default dynamicModal.extend({
  name: "ModalContent",
  props: {
    size: {
      type: String,
      default: 'md'
    }
  },
  mounted() {
    this.$store.commit('layout/setProp', {
      prop: 'dynamicModalSize',
      value: this.size
    });
  }
});
