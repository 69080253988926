














































import { dynamicModal, getWarningColor } from "@/helpers";
import { IWarning } from "@/types";
import { v4 } from "uuid";
import MarkdownIt from "markdown-it";
import MarkdownItAttrs from "markdown-it-attrs";
export default dynamicModal.extend({
  name: "WarningBox",
  props: {
    warning: {
      type: Object as () => IWarning,
      required: true
    },
    isVerbose: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    uuid: ''
  }),
  computed: {
    isHoverable(): boolean {
      return !!(this.warning.prefix && ['long_term_correlation', 'liquidity'].includes(this.warning.prefix));
    },
    isCorrelation(): boolean {
      return this.warning.prefix === 'long_term_correlation';
    }
  },
  created() {
    this.uuid = v4();
  },
  mounted() {
    this.$nextTick(() => {
      Object.entries(this.$refs as Record<string, any>).filter(
          ([key]) => key.startsWith('md-')
      ).forEach(([, el]) => {
        if (typeof (el as MarkdownIt).use === 'function') {
          el.use(MarkdownItAttrs);
        }
      });
    });
  },
  methods: {
    getWarningColor,
    highlightPairs() {
      if (this.isCorrelation) {
        const pairs = this.warning.heading.split(' - ');
        if (pairs.length === 2) {
          this.$store.commit('trade/setHighlightedPairs', { pairs, severity: this.warning.severity });
        }
      } else if (this.warning.prefix === 'liquidity') {
        if (this.warning.heading.length === 6) {
          this.$store.commit('trade/setHighlightedPairs', { pairs: [this.warning.heading], severity: this.warning.severity });
        }
      }
    },
    openCorrelationsModal() {
      const [base, quote] = this.warning.heading.split(' - ');
      if (quote && base) {
        this.$store.commit('trade/setCorrelationPair', { base, quote });
        this.openModal('correlations-modal');
      }
    }
  }
});
