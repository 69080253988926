
















































import Vue from 'vue';
import { ISignupStep, IStepAction } from "@/types";
import CurrencyPairs from './CurrencyPairs.vue';
import { mapState } from "vuex";
import StepIndicator from "@/components/landing/StepIndicator.vue";
import ChoiceSelector from "@/components/landing/ChoiceSelector.vue";
import CapitalControls from "@/components/shared/CapitalControls.vue";
import { times } from 'lodash-es';
import { v4 } from "uuid";
interface Question {
    question_text?: string;
    question_tool_tip_text?: string
    answers? :{
      [key:string] : string
    }
}
export default Vue.extend({
  name: "QuestionsForm",
  components: { StepIndicator },
  data:() =>({
    questions: [] as Question[] ,
  }),
  computed: {
    ...mapState('trade', ['currentPairs', 'positionSizingApproach', 'stopLossApproach', 'leverageApproach']),
    stepIndex: {
      get(): number {
        return this.$store.state.layout.stepIndex;
      },
      set(index: number) {
        this.$store.commit('layout/setStepIndex', index);
      }
    },
    currentStep(): ISignupStep {
      return this.steps[this.stepIndex];
    },
    stepActions(): IStepAction[] {
      return this.currentStep?.actions || this.defaultActions;
    },
    defaultActions(): IStepAction[] {
      return [{
        text: this.$t('landing.90'),
        variant: 'primary-outline',
        context: this,
        action: () => this.advance(-1)
      }, {
        text: this.$t('landing.92'),
        variant: 'primary',
        action: () => {
          this.$store.commit('layout/setStepIndex', 0);
          this.$store.commit('layout/setPage', 'trade');
        }
      }];
    },
    steps(): ISignupStep[] {
      return [{
        component: CurrencyPairs,
        actions: [{
          context: this,
          variant: 'primary',
          text: 'Next',
          get disabled() {
            return !this.context.hasCurrencyPairs;
          },
          action: () => this.advance(1),
          errorMessage: ''
        }]
      }, {
        component: ChoiceSelector,
        actions: [{
          variant: 'outline-primary',
          action: () => this.advance(-1)
        }, {
          variant: 'primary',
          action: () => this.advance(1),
          context: this,
          get disabled() {
            return typeof this.context.positionSizingApproach !== 'number';
          },
        }],
        context: {
          questions: [
            this.getQuestion(0,'questionnaire.steps.1.questions.0', 4),
            this.getQuestion(1,'questionnaire.steps.1.questions.1', 4),
          ]
        }
      }, {
        component: ChoiceSelector,
        actions: [{
          variant: 'outline-primary',
          action: () => this.advance(-1)
        }, {
          variant: 'primary',
          action: () => this.advance(1),
          context: this,
          get disabled() {
            return typeof this.context.stopLossApproach !== 'number';
          },
        }],
        context: {
          questions: [
            this.getQuestion(2,'questionnaire.steps.2.questions.0', 4)
          ]
        }
      }, {
        component: CapitalControls,
        context: {
          showAdvanced: false,
          showTitle: true
        },
        actions: [{
          variant: 'outline-primary',
          action: () => this.advance(-1)
        }, {
          variant: 'primary',
          action: () => this.$store.commit('layout/setPage', 'overview')
        }]
      }].map((step: any, index: number) => ({
        ...step,
        title: this.translate(`questionnaire.steps.${index}.title`),
        heading: this.translate(`questionnaire.steps.${index}.heading`),
        actions: step.actions.map((action: IStepAction, key: number) => ({
          ...action,
          text: action.text ?? this.translate(`questionnaire.steps.${index}.actions.${key}.text`),
          errorMessage: this.translate(`questionnaire.steps.${index}.actions.${key}.errorMessage`)
        }))
      }));
    },
    hasCurrencyPairs(): boolean {
      return !!this.currentPairs.length;
    },
    validationMessages(): string[] {
      return this.currentStep.actions.filter(
          ({ disabled, errorMessage }) => disabled && errorMessage
      ).map(({ errorMessage }) => errorMessage) as string[];
    }
  },
  async mounted(){
     this.questions = await this.getQuestionsArray() as Question[];
  },
  methods: {
    advance(value: number) {
      this.stepIndex += value;
      this.$bus.$emit('scroll:top');
    },
    updateValue(value: any) {
      this.currentStep.context = {
        ...this.currentStep.context,
        value
      };
    },
    async getQuestionsArray() {

      let questions: Array<string | number> = [];

      await this.$http.get('/api/onboarding_question_text')
        .then(({ data }) => {
          questions = data;
        })
      .catch(() => Promise.resolve());

      return questions;
    },

    getQuestion(step: number, path: string, optionsCount: number) {
      if(this.questions[step]){
        return {
          heading: this.questions[step].question_text,
          options: times(optionsCount, i => this.questions[step].answers?.[i])
            .filter(o => o) as Array<string>,
          state: this.translate(path + '.state'),
          tooltip: this.questions[step].question_tool_tip_text,
          mutation: this.translate(path + '.mutation'),
          id: v4()
        };
      }
    },
    translate(path: string) {
      return this.$t(path) === path
        ? ''
        : this.$t(path);
    }
  }
});
