


















import Vue from 'vue';
import { mapState } from "vuex";
import { get } from 'lodash-es';
import PromoBoxes from "@/components/overview/PromoBoxes.vue";
import MarkdownItAttrs from "markdown-it-attrs";
import MarkdownIt from "markdown-it";

export default Vue.extend({
  name: 'Benefits',
  components: { PromoBoxes },
  computed: {
    ...mapState('overview', ['benefits']),
    title(): string {
      return get(this, 'benefits[0].section_heading') || '';
    },
    subTitle(): string {
      return (get(this, 'benefits[0].section_text') || '') + '{.lead}';
    }
  },
  mounted() {
    ((this.$refs.md || []) as unknown as MarkdownIt[]).forEach(el => {
      if (typeof el.use === 'function') {
        el.use(MarkdownItAttrs);
      }
    });
  }
});
