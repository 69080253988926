
























import Questionnaire from "@/components/landing/Questionaire.vue";
import { mapState, mapGetters } from "vuex";
import ColumnScroller from "@/components/ColumnScroller.vue";
import RadarChart from "@/components/trade/RadarChart.vue";
import LoginForm from "@/components/shared/LoginForm.vue";
import { dynamicModal } from "@/helpers";

export default dynamicModal.extend({
  name: "Home",
  components: {
    LoginForm,
    ColumnScroller,
    Questionnaire,
    RadarChart,
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    ...mapState("trade", [
      "currentPairs",
      "stopLosses",
      "positionSizing",
      "healthChecks",
      "severityRanges",
    ]),
    ...mapState("layout", ["stepIndex", "page"]),
  },
  created() {
    this.$store.state.auth.userAccountMessage = [];
  },
});
