

































































































































































































import Vue from "vue";
import { Power2, gsap } from "gsap";
import NavBar from "@/components/NavBar.vue";
import { mapGetters, mapState } from "vuex";
import { CurrencyPair } from "@/types";
import { responsiveness, updateIntervals } from "@/responsiveness";
import PairSelector from "@/components/shared/PairSelector.vue";
import EntryPriceSelector from "@/components/shared/EntryPriceSelector.vue";
import UploadRecommendations from "@/components/shared/UploadRecommendations.vue";
import LoginForm from "@/components/shared/LoginForm.vue";
import ChangePassword from "@/components/shared/ChangePassword.vue";
import UserProfile from "@/components/shared/UserProfile.vue";
import SimulationChart from "@/components/shared/SimulationChart.vue";
import { get, kebabCase } from "lodash-es";
import ModalContent from "@/components/shared/ModalContent.vue";
import LoadingAnimation from "@/components/shared/LoadingAnimation.vue";
import CorrelationsModal from "@/components/shared/CorrelationsModal.vue";
import { dynamicModal } from "@/helpers";
import CloseModal from "@/components/shared/CloseModal.vue";
import TradeLocked from "@/components/shared/TradeLocked.vue";
import LockRipple from "@/components/shared/LockRipple.vue";
import TradingDateSelector from "@/components/shared/TradingDateSelector.vue";
export default dynamicModal.extend({
  name: "App",
  components: {
    CloseModal,
    CorrelationsModal,
    LoadingAnimation,
    ModalContent,
    SimulationChart,
    UserProfile,
    ChangePassword,
    NavBar,
    PairSelector,
    EntryPriceSelector,
    UploadRecommendations,
    LoginForm,
    TradeLocked,
    LockRipple,
    TradingDateSelector,
  },
  data: () => ({
    bellCurve: "",
    isLoadingUser: false,
    lockStates: ["locked", "unlocked", "warning"],
    showAnimation: false,
  }),
  computed: {
    ...mapState("layout", [
      "darkMode",
      "page",
      "dynamicModalSize",
      "usingDashboardContent",
      "isLeftSidebarOpen",
      "isRightSidebarOpen",
    ]),
    ...mapState("trade", [
      "currencyPairs",
      "allPairs",
      "apiCurrencies",
      "isLoadingData",
      "lockTexts",
      "defaultValues",
      "rightSidebarAnimation",
    ]),
    ...mapGetters("auth", ["isLoggedIn", "isAdmin", "username"]),
    ...mapState("auth", [
      "requirePassword",
      "users",
      "userAccountMessage",
      "showSuccessModal",
    ]),
    ...mapState("overview", ["simulationAssumptionsContent"]),
    ...mapGetters("trade", ["weightedScore"]),
    showRightSidebarAnimation(): boolean {
      return this.rightSidebarAnimation;
    },
    isLeftSidebarOpen: {
      get(): boolean {
        return (
          this.$store.state.layout.isLeftSidebarOpen &&
          this.responsiveness.lgDown
        );
      },
      set(value: boolean) {
        this.$store.commit("layout/setSidebarOpen", {
          value,
          isRightSidebar: false,
        });
      },
    },
    isRightSidebarOpen: {
      get(): boolean {
        return (
          this.$store.state.layout.isRightSidebarOpen &&
          this.responsiveness.lgDown
        );
      },
      set(value: boolean) {
        this.$store.commit("layout/setSidebarOpen", {
          value,
          isRightSidebar: true,
        });
      },
    },
    isLoading: {
      get(): boolean {
        return  this.$store.state.trade.isLoadingData;
      },
      set(value: boolean) {
        this.$store.commit('trade/setProp', { prop: 'isLoadingData', value: value });
      },
    },
    isTradingLocked: {
      get(): boolean {
        return this.$store.state.trade.isTradingLocked;
      },
      set(value: boolean) {
        this.$store.commit("trade/toggleLock", value);
      },
    },
    isDynamicModalOpen: {
      get(): boolean {
        return Boolean(this.dynamicModalTarget);
      },
      set(value: boolean) {
        if (!value) {
          this.$store.commit("layout/setProp", {
            prop: "dynamicModalSize",
            value: "",
          });
          this.closeModal();
        }
      },
    },
    usingDashboardContent: {
      get(): string {
        return this.$store.state.layout.usingDashboardContent;
      },
      set(value: string) {
        this.$store.commit("layout/setProp", {
          prop: "usingDashboardContent",
          value,
        });
      },
    },
    showAdvancedSimulation: {
      get(): boolean {
        return this.$store.state.overview.showAdvancedSimulation;
      },
      set(value: boolean) {
        this.$store.commit("overview/setProp", {
          prop: "showAdvancedSimulation",
          value,
        });
      },
    },
    showSimulationAssumptions: {
      get(): boolean {
        return this.$store.state.overview.showSimulationAssumptions;
      },
      set(value: boolean) {
        this.$store.commit("overview/setProp", {
          prop: "showSimulationAssumptions",
          value,
        });
      },
    },
    showModalSuccessClass() {
      return (
        this.showSuccessModal &&
        this.username &&
        this.userAccountMessage &&
        this.userAccountMessage.message &&
        this.userAccountMessage.cta_message
      );
    },
    responsiveness: () => responsiveness,
    anySidebarOpen(): boolean {
      return this.isLeftSidebarOpen || this.isRightSidebarOpen;
    },
    overallScore(): string {
      return isNaN(this.weightedScore) ? "--" : this.weightedScore + "";
    },
  },
  watch: {
    page: {
      handler(val: string) {
        if (val === "overview" && !this.bellCurve) {
          this.fetchBellCurve();
        }
        if (val === "overview") {
          this.onScrollTopAnimation();
        }
      },
      immediate: true,
    },
    isLoading(val: boolean) {
      this.isLoadingUser = val;
    },
    darkMode: {
      handler(val: boolean) {
        document.body.classList[val ? "add" : "remove"]("dark-bg");
      },
      immediate: true,
    },
    anySidebarOpen: {
      handler(val: boolean) {
        document.body.classList[val ? "add" : "remove"]("sidebar-open");
      },
      immediate: true,
    },
    dynamicModalTarget(value: string) {
      if (!value) {
        this.closeModal();
      }
    },
    isAdmin: {
      handler(val: boolean) {
        if (val) {
          this.$store.dispatch("auth/fetchUsers");
        } else {
          this.$store.commit("auth/setProp", { prop: "users", value: [] });
        }
      },
    },
  },
  updated() {
    if (this.page !== "overview") {
      this.onScrollTopAnimation();
    }
  },
  async mounted() {
    this.onScrollTopAnimation();
    setInterval(() => {
      this.showAnimation = !this.showAnimation;
    }, 5000);
    this.$store.dispatch("auth/checkGoogleProfile");
    if (!this.apiCurrencies.length) {
      await this.$http
        .get("/api/currencies")
        .then(({ data }) => {
          this.$store.commit("trade/setAllCurrencies", data);
          return Promise.resolve();
        })
        .catch(() => Promise.resolve());
    }
    if (!this.defaultValues.length) {
      await this.$http
        .get("api/parameter_text")
        .then(({ data }) => {
          this.$store.commit("trade/setDefaultValues", data[0]);
          if (!this.$store.state.trade.accountCurrency) {
            this.$store.state.trade.accountCurrency = this.apiCurrencies
              .filter(
                (o: any) => o.code === data[0].parameters[0].parameter_default
              )
              .map((o: any) => o)[0];
          }
          return Promise.resolve();
        })
        .catch(() => Promise.resolve());
    }
    this.$http
      .get("/api/correlation_band_parameters")
      .then(({ data }) => {
        this.$store.commit(
          "trade/setCorrelationRanges",
          data.map((i: any) => ({
            from: i.greater_than_or_equal_to,
            to: Math.min(i.less_than || 1, 1),
            name: i.label,
          }))
        );
      })
      .catch(() => Promise.resolve());
    this.$http
      .get("/api/severity_band_parameters")
      .then(({ data }) => {
        this.$store.commit(
          "trade/setSeverityRanges",
          data.map((i: any) => ({
            from: i.greater_than_or_equal_to || 0,
            to: i.less_than || 1,
            name: i.label,
          }))
        );
        return Promise.resolve();
      })
      .catch(() => Promise.resolve());

    if (!this.allPairs.length) {
      this.$http
        .get("/api/currency_pairs")
        .then(({ data }) => {
          this.$store.commit(
            "trade/setAllPairs",
            data.map(
              (p: any) =>{
                return new CurrencyPair({
                  ...p,
                  base: p["currency_pair"].substring(0, p["base_currency_code"].length),
                  quote: p["currency_pair"].substring(p["base_currency_code"].length, p["base_currency_code"].length + p["quote_currency_code"].length),
                });
              }
            )
          );
          return Promise.resolve();
        })
        .catch(() => Promise.resolve());
    }
    this.$http
      .get("api/dashboard_help_text")
      .then(({ data }) => {
        this.usingDashboardContent = get(data, [0, "text"]);
      })
      .catch(() => Promise.resolve());
    this.$nextTick(() => {
      updateIntervals((this.$el as HTMLElement).offsetWidth);
    });
  },
  methods: {
    kebabCase,
    toggleLock(): void {
      this.openModal(this.isTradingLocked ? "locked" : "unlocked");
    },
    onScrollTopAnimation() {
      if (this.page !== "trade" || this.page !== "home") {
        const main = document.querySelector("main");
        this.$bus.$on("scroll:top", () => {
          const s = this.$refs.scroll as any;
          if (s instanceof Vue) {
            const $el = s.$el as HTMLElement;
            const st = $el.scrollTop;
            if (st) {
              const subject = { st };
              gsap.to(subject, {
                st: 0,
                ease: Power2.easeOut,
                duration: 0.05,
                onUpdate: () => {
                  $el.scrollTop = subject.st;
                },
              });
              $el.scrollTop = 0;
              this.$nextTick(() => {
                s.ps.update();
              });
            }
          } else {
            if (main) {
              main.scrollTop = 0;
            }
          }
        });
        if (main) {
          main.scrollTop = 0;
        }
      }
    },
    toggleTradingLock() {
      this.isTradingLocked = !this.isTradingLocked;
      this.closeModal();
    },
    onResize({ width }: { width: number } = { width: 0 }) {
      updateIntervals(width);
    },
    fetchBellCurve() {
      this.$http
        .get("api/bell_curve_chart_text")
        .then(({ data }) => {
          this.bellCurve = get(data, [0, "text"]);
        })
        .catch(() => Promise.resolve());
    },
    toggleSidebar(right = false) {
      if (right) {
        this.isRightSidebarOpen = !this.isRightSidebarOpen;
        if (this.isRightSidebarOpen) {
          this.isLeftSidebarOpen = false;
        }
        if (this.rightSidebarAnimation) {
          this.$store.commit("trade/setProp", {
            prop: "rightSidebarAnimation",
            value: false,
          });
          this.$store.dispatch("auth/saveUserState");
        }
      } else {
        this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
        if (this.isLeftSidebarOpen) {
          this.isRightSidebarOpen = false;
        }
      }
    },
  },
});
