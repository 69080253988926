
















































































import Vue from "vue";
import { mapState } from "vuex";
import { v4 } from 'uuid';
import SvgClickHandler from "@/components/overview/SvgClickHandler.vue";
import { ScreenshotDetail } from "@/types";
export default Vue.extend({
  name: 'DashboardScreenshot',
  components: { SvgClickHandler },
  data: () => ({
    pattern0: v4(),
    image0: v4()
  }),
  computed: {
    ...mapState('overview', ['activeSsDetailIndex']),
    ssDetails(): ScreenshotDetail[] {
      return this.$store.state.overview.ssDetails;
    },
    imageDots(): ScreenshotDetail[] {
      return this.ssDetails.filter(({ x, y }) => x && y);
    }
  },
  methods: {
    setActiveSsDetailIndex(value: number | null) {
      this.$store.commit('overview/setProp', {
        prop: 'activeSsDetailIndex',
        value
      });
    }
  }
});
