















































































































import { mapGetters, mapState } from "vuex";
import { isEqual, pick } from "lodash-es";
import { dynamicModal } from "@/helpers";
import { User } from "@/types/User";
import { IUserData } from "@/types";

export default dynamicModal.extend({
  name: "UserProfile",
  data: () => ({
    local: {} as Record<string, any>,
    retypedPassword: '',
    isPasswordVisible: false,
    errorMessage: '',
    showSuccess: false
  }),
  computed: {
    ...mapState('auth', ['user','userAccountMessage','isLoadingData','showSuccessModal']),
    ...mapGetters('auth', ['username', 'isGoogleUser', 'isLoggedIn']),
    hasChanges(): boolean {
      return !isEqual(this.local, this.pick(this.user));
    },
    isEmailValid(): boolean {
      return this.local.email && this.local.email.search(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== -1;
    },
    isNameValid(): boolean {
      return this.local.first_name && this.local.first_name.length > 0 
        && this.local.second_name && this.local.second_name.length > 0;
    },
    disabled():boolean { return Boolean(this.errorMessage); },
    isRetypedPasswordMatching(): boolean {
      return this.retypedPassword === this.local.password;
    },
  },
  watch: {
    userAccountMessage: {
      handler(val: any) {
         this.errorMessage = val.message;
      },
    },
  },
  async mounted() {
    this.local = await this.pick(this.user);
  },
  async destroyed(){
    if(await this.isLoggedIn){
      this.$store.commit('auth/setProp', { prop: 'showSuccessModal', value: false });
      this.$store.commit('auth/setProp', { prop: 'userAccountMessage', value: this.isLoggedIn ?  [] : this.showSuccessModal === true ? {} : [] });
    }
  },
  methods: {
    onFocus(){
      this.errorMessage = '';
    },
    async saveUser() {
      if (!this.isLoggedIn) {
        const user = new User(this.local as IUserData);
        return this.$store.dispatch(
            'auth/createUser',
            user
        )
        .then(() => {
            if(this.userAccountMessage.cta_message !== null){
              this.showSuccess = true;
              this.$store.commit('auth/setProp', { prop: 'showSuccessModal', value: true });
            }
          });
      }
      return this.$store.dispatch('auth/updateUser', this.pick(this.local) )
          .then(() => this.$store.commit('auth/setUser', {
                ...this.user.data,
                ...this.pick(this.local)
          }))
          .then(() => {
            this.showSuccess = true;
          });
      
    },
    goTo(){
      this.closeModal();
      this.$store.commit('trade/setProp', { prop: 'rightSidebarAnimation', value: true });
      this.$store.dispatch('auth/saveUserState');
      this.$router.push('/dashboard');
      this.$store.commit('auth/setProp', { prop: 'userAccountMessage', value: [] });
      this.$store.commit('auth/setProp', { prop: 'showSuccessModal', value: false });
      this.$store.commit('trade/setProp', { prop: 'isLoadingData', value: true }, { root: true });
    },
    pick(o: Record<string, any>): Record<string, any> {
      return pick(o, ['email', 'first_name', 'second_name', 'phone']);
    }
  }
});
