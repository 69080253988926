

























































// import GoogleLogin from 'vue-google-login';
import { mapGetters, mapState } from "vuex";
import { dynamicModal } from "@/helpers";
import GridBox from '@/components/shared/GridBox.vue';
import RavenDivider from "@/components/shared/RavenDivider.vue";

export default dynamicModal.extend({
  name: "LoginForm",
  components: {
    RavenDivider,
    // GoogleLogin,
    GridBox
  },
  data: () => ({
    form: {
      username: '',
      password: ''
    },
    googleParams: {
      client_id: '338216475062-2860eg5f3d5955psjkdclta24mv8eh8r'
    },
    renderParams: {
      width: 'auto',
      height: 42,
      longtitle: true
    },
    hasLoaded: false,
    showPass: false
  }),
  computed: {
    ...mapGetters('auth', ['isGoogleUser']),
    ...mapState('auth', ['hasRenderedLogin'])
  },
  beforeMount() {
    if (this.hasRenderedLogin) {
      this.hasLoaded = true;
    }
  },
  mounted() {
    if (!this.hasLoaded) {
      setTimeout(() => {
        this.hasLoaded = true;
        this.$store.commit('auth/setProp', {
          prop: 'hasRenderedLogin',
          value: true
        });
      }, 1e3);
    }
  },
  methods: {
    login() {
      // this.$store.state.layout.page = 'trade';
      this.$store.commit('auth/setProp', { prop: 'isReady', value: false });
      this.$store.dispatch('auth/authenticate', this.form)
          .then(() => {
            this.$store.dispatch('auth/loadUser');
            this.$router.push('/dashboard');
            this.closeModal();
          })
          .catch(() => {
            this.$store.commit('auth/setProp', { prop: 'isReady', value: true });
            return Promise.resolve();
          });
    },
    onSuccess(googleUser: any) {
      const profile = googleUser.getBasicProfile();
      this.$store.commit('auth/setProp', { prop: 'isReady', value: false });
      this.$store.dispatch('auth/validateGoogleProfile', profile)
          .then(() => this.closeModal());
    },
    onFailure() {
      // console.log(err);
    }
  }
});
