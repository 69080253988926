











import Vue from 'vue';
import { get } from 'lodash-es';
import PromoBox from '@/components/shared/PromoBox.vue';
import { mapState } from "vuex";

export default Vue.extend({
  name: "PromoBoxes",
  components: {
    PromoBox
  },
  computed: {
    ...mapState('overview', ['benefits']),
    promos(): { title: string; description: string; icon: string }[] {
      return (get(this, ['benefits', 1]) || [])
          .map((item: any) => ({
            title: item.benefit_heading,
            icon: item.benefit_icon_name,
            description: item.benefit_text
          }));
    }
  }
});
