












import Vue from "vue";
import GridBox from "@/components/shared/GridBox.vue";
import { mapGetters } from "vuex";
import moment from 'moment';
import { datePickerOptions } from "@/helpers";

const toUtcString = (date: any) => moment(date).add(moment(date).utcOffset(), 'minutes').utc().toISOString();

export default Vue.extend({
  name: "TradingDateSelector",
  components: {
    GridBox
  },
  data: () => ({
    datePickerOptions,
    allowedDates: [] as string[]
  }),
  computed: {
    ...mapGetters('trade', ['trade_date']),
    currentDate: {
      get(): string {
        return this.trade_date;
      },
      set(date: string) {
        this.$store.commit('trade/setProp', {
          prop: 'tradeDate',
          value: toUtcString(date)?.substring(0, 10) || ''
        });
        this.$store.dispatch('auth/saveUserState'); 
      }
    },
    formattedDate(): string {
      return this.currentDate
          ? new Date(this.currentDate).toLocaleDateString('en-IE', { day: '2-digit', month: 'short', year: 'numeric' })
          : 'Today\'s ';
    }
  },
  mounted() {
    this.$http('api/trade_dates').then(
        ({ data }) => {
          this.allowedDates = data.map((d: { trade_date: string }) => d.trade_date);
          this.currentDate = data[0].trade_date;
        }
    );
  },
  methods: {
    disabledDates(date: string) {
      const dateString = toUtcString(date);
      return !this.allowedDates.includes(dateString);
    },
  }
});
