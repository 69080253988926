import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { $store } from "@/main";
import { IEndpoint, Endpoint } from "@/types";

interface DocsState {
    endpoints: Endpoint[];
    currentEndpoint: string;
}

@Module({ namespaced: true, store: $store, name: 'docs' })
export default class DocsStore extends VuexModule implements DocsState {
    endpoints: Endpoint[] = [];
    currentEndpoint = '';

    @Mutation
    setEndpoints(data: (Endpoint | IEndpoint)[]): void {
        this.endpoints = data.map(ep => new Endpoint(ep));
    }

    @Mutation
    setCurrentEndpoint(data: string): void {
        this.currentEndpoint = data;
    }

    @Mutation
    setState(data: Partial<DocsState>): void {
        Object.assign(this, data);
    }
}
