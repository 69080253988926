



































































import Vue from 'vue';
import { BDropdown, BFormInput } from "bootstrap-vue";
import { InputControlType, InputOption, InputValue } from "@/types";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import DateRangeInput from "@/components/shared/DateRangeInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import OptionsSlider from "@/components/shared/OptionsSlider.vue";
import NumericSlider from "@/components/shared/NumericSlider.vue";
import CurrencySelector from "@/components/shared/CurrencySelector.vue";
import { get, debounce } from 'lodash-es';
import { mapGetters, mapState } from "vuex";
import { v4 } from "uuid";
import TradeLocked from "@/components/shared/TradeLocked.vue";

export default Vue.extend({
  name: "InputCard",
  components: {
    TradeLocked,
    SelectInput,
    CurrencyInput,
    DateRangeInput,
    OptionsSlider,
    NumericSlider,
    CurrencySelector
  },
  props: {
    unlocked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String as () => InputControlType,
      default: 'numeric'
    },
    mask: {
      type: [Object, Array],
      default: () => {}
    },
    value: {
      type: [String, Object, Number],
      default: null,
      validator: (p) => {
        return ['string', 'number'].indexOf(typeof p) !== -1 || p === null;
      },
    },
    stateProp: {
      type: String,
      default: ''
    },
    stateGetter: {
      type: String,
      default: ''
    },
    options: {
      type: Array as () => InputOption[],
      default: () => []
    },
    tooltip: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    prepend: {
      type: String,
      default: ''
    },
    debounce: {
      type: Number,
      default: 0
    },
    defaultValues : {
      type: [String,Number, Object],
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data: function() {
    return {
      uuid: v4(),
      localValue: null as null | string | number,
      focusedInput: false as any,
      debounceValue: debounce((value: InputValue) => {
        if (['numeric', 'numeric-slider', 'currency'].includes(this.type)) {
          if (isFinite(+value) ) {
            value = +value;
          } else {
            return;
          }
        }
        if(value ===''){
            value = 0;
          }
        if (this.stateProp) {
            this.$store.commit(`trade/setProp`, {
              prop: this.stateProp,
              value: get(this, 'config.tokens.transform') ? this.config.tokens.transform(value): Array.isArray(value) ? value[0] : value
            });
            this.$store.dispatch('auth/saveUserState'); 
            if (get(this, 'config.tokens.transform')) {
              this.$nextTick(() => {
                if (this.$refs.mask instanceof BFormInput) {
                  this.$refs.mask.$el.dispatchEvent(new Event('input'));
                  // this.$refs.mask.$el.trigger('input');
                }
              });
            }
        } else {
            this.$emit('update:value', value);
            this.$store.dispatch('auth/saveUserState'); 

        }
      }, this.debounce)
    };
  },
  computed: {
    ...mapGetters('trade', ['allCurrencies']),
    ...mapState('trade', ['isTradingLocked']),
    isLocked(): boolean {
      return this.isTradingLocked && !this.unlocked;
    },
    computedValue: {
      get(): InputValue {
        return  this.stateGetter
          ? this.$store.getters[`trade/${this.stateGetter}`] || this.defaultValues 
          : this.stateProp
            ? typeof this.$store.state.trade[this.stateProp]  !== 'number' ? this.$store.state.trade[this.stateProp] || this.defaultValues : this.$store.state.trade[this.stateProp] >= 0 ? this.$store.state.trade[this.stateProp] : this.defaultValues
            : '';

      },
      set(value: InputValue) {
          this.debounceValue(value);
      }
    },
    tooltipConfig(): Record<string, any> {
      return {
        html: true
      };
    },
    valueState(): boolean | null {
      return this.mask?.validation
          ? this.mask.validation(this.localValue)
          : null;
    }
  },
  watch: {
     localValue: {
      handler(val) {
        this.computedValue = val;
        if (['numeric', 'numeric-slider', 'currency'].includes(this.type)) {
          if (typeof val === 'string' &&
              !isNaN(+val) &&
              isFinite(+val) &&
              val.length === ('' + (+val)).length
          ) {
            this.localValue = +val;
          }
          if(this.focusedInput === false && val===''){
            this.localValue = 0;
          }
        }
        this.debounceValue(val);
      },
    },
    computedValue: {
      handler(val) {
        this.localValue = val;
      },
      immediate: true,
      deep: true,
    }
  },
  created(){
    this.localValue = this.stateGetter
          ? this.$store.getters[`trade/${this.stateGetter}`] || this.defaultValues 
          : this.stateProp
            ? typeof this.$store.state.trade[this.stateProp]  !== 'number' ? this.$store.state.trade[this.stateProp] || this.defaultValues : this.$store.state.trade[this.stateProp] >= 0 ? this.$store.state.trade[this.stateProp] : this.defaultValues
            : '';

  },
  mounted() {
    if (this.options.length) {
      if(this.type !== 'select'){
        const option = this.options.findIndex(( val:any ) => val.value  === this.localValue) ;
        this.localValue = get(this.options[option], 'value') || this.options[option];
      }else{
         this.localValue = get(this.value, 'value') || this.value;
      }
    }
    
  },
  methods: {
    onFocusInput(){
      this.focusedInput = true;
      if(this.localValue === '0' || this.localValue === 0){
        this.localValue = '';
      } 
    },
    onBlurInput(){
      this.focusedInput = false;
      if(!this.focusedInput && this.localValue ===''){
        this.localValue = 0;
      } 
    },

    focusFormControl() {
      if (!this.stateGetter && !this.isLocked) {
        const control = this.$el.querySelector('.form-control, .dropdown');
        if (control && control instanceof HTMLInputElement) {
          control.focus();
        } else {
          const dd = get(this.$refs, 'select.$children[0]');
          if (dd && dd instanceof BDropdown) {
            dd.show();
          }
        }
      }
    }
  }
});
