







import Vue from "vue";
export default Vue.extend({
  name: "SsDetail",
  props: {
    text: {
      type: String,
      default: ''
    },
    position: {
      type: Number,
      default: 0
    },
    alignment: {
      type: String,
      default: 'left'
    }
  }
});
