import Vue from "vue";
import coinify from "coinify";
import {
    CurrencyPair, HealthCheck,
    ICurrencyPair, IHealthCheck,
    IHealthCheckPayload,
    IUserData
} from "@/types";
import C from "crypto-js";
import { pick, omit, get } from "lodash-es";
import { State } from "@/store";

/* @ts-ignore */// eslint-disable-next-line
const _0x49b7=['NpE%2C0K&t4S4dQ','290531syzAJx','609401ytiAxA','1694562xqnXpq','25vfHNJT','20903MkkxJF','1259815sxiYSR','HmacSHA256','17lygabC','60316VWWLJS','join','reverse','713865bgQiAl','split','5lCYsIw'];const _0x2d4578=_0xd954;(function(_0x395ecc,_0x5d2689){const _0x238b31=_0xd954;while(!![]){try{const _0x48ba2b=-parseInt(_0x238b31(0xfb))*-parseInt(_0x238b31(0xf9))+-parseInt(_0x238b31(0xf1))+parseInt(_0x238b31(0xef))*parseInt(_0x238b31(0xf4))+parseInt(_0x238b31(0xf7))+-parseInt(_0x238b31(0xfd))+parseInt(_0x238b31(0xfc))+-parseInt(_0x238b31(0xf0))*parseInt(_0x238b31(0xf3));if(_0x48ba2b===_0x5d2689)break;else _0x395ecc['push'](_0x395ecc['shift']());}catch(_0x349bb7){_0x395ecc['push'](_0x395ecc['shift']());}}}(_0x49b7,0xedd0d));function _0xd954(_0x253459,_0x264fbc){return _0xd954=function(_0x49b77d,_0xd954db){_0x49b77d=_0x49b77d-0xef;let _0x2af135=_0x49b7[_0x49b77d];return _0x2af135;},_0xd954(_0x253459,_0x264fbc);}const O=_0x52688f=>C[_0x2d4578(0xf2)](_0x52688f[_0x2d4578(0xf8)]('')['reverse']()[_0x2d4578(0xf5)](''),_0x2d4578(0xfa)[_0x2d4578(0xf8)]('')[_0x2d4578(0xf6)]()[_0x2d4578(0xf5)](''));

export interface Coin {
    label?: string,
    code: string;
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: string;
    rounding: number;
    name_plural: string;
}

export const getCoin: (key: string) => Coin = (key: string) => coinify.get(key);

export const fromUserData = (data: IUserData & Record<string, any>): IUserData => data.givenName
  ? {
    email: data.email,
    first_name: data.givenName,
    second_name: data.familyName,
    google_registration: true,
    native_registration: false,
    password: data.password,
    ...pick(data, ['id', 'imageUrl'])
  }
  : data;

export const payloads: IHealthCheckPayload[] = [{
    prefix: 'over_trading',
    title: 'Over-trading health',
    position: 1,
    overviewIndex: 5
}, {
    prefix: 'liquidity',
    title: 'Liquidity health',
    position: 3,
    overviewIndex: 0
}, {
    prefix: 'stop_loss_approach',
    title: 'Stop loss approach',
    position: 5,
    overviewIndex: 4
}, {
    prefix: 'position_sizing_approach',
    title: 'Position sizing approach',
    position: 6,
    overviewIndex: 3
}, {
    prefix: 'diversification',
    title: 'Diversification health',
    position: 4,
    overviewIndex: 2
}, {
    prefix: 'long_term_correlation',
    title: 'Correlation health',
    position: 2,
    overviewIndex: 1
}];

export const correlationColors = ["#67BD23", "#FFFF00", "#FF7C03", "#FF0000"];

export const getWarningColor = (severity: number): string => {
    switch (severity) {
        case 0:
        case 1:
        case 2:
            return correlationColors[0]; // #67BD23 green
        case 3:
            return correlationColors[1]; // #FFFF00 yellow
        case 4:
            return correlationColors[2]; // #FF7C03 orange
        case 5:
            return correlationColors[3]; // #FF0000 red
        default:
            return 'var(--muted)'; // ~50% gray
    }
};

export const severityLevels = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Negligible' },
    { value: 2, label: 'Low' },
    { value: 3, label: 'Moderate' },
    { value: 4, label: 'High' },
    { value: 5, label: 'Critical' }
];

export const storeDefaults = {
    layout: {
        darkMode: true,
        page: '',
        stepIndex: 0,
        dynamicModalTarget: null,
    },
    trade: {
        positionSizingApproach: null as null | number,
        leverageApproach: null as null | number,
        stopLossApproach: null as null | number,
        tradeDate: '',
        startTradeDate: '2020-01-01',
        endTradeDate: '2020-12-31',
        accountCurrency: undefined,
        leverage: undefined,
        accountCash: undefined,
        portfolioTradeAmount: undefined,
        portfolioLossTolerancePercentage: undefined,
        reactivenessLevel: undefined,
        lotSize: undefined,
        currentPairs: [] as CurrencyPair[],
        isTradingLocked: false
    },
    auth: {
        isReady: false,
        hasRenderedLogin: false
    },
    overview: {}
};

export const datePickerOptions = {
    inputClass: 'rdp-input',
    prefixClass: 'rdp'
};

export const storeReducer = (state: any): any => ({
    layout: state.layout,
    trade: pick(state.trade, [
        'currentPairs',
        'positionSizingApproach',
        'leverageApproach',
        'stopLossApproach',
        'accountCurrency',
        'accountCash',
        'leverage',
        'portfolioTradeAmount',
        'portfolioLossTolerancePercentage',
        'reactivenessLevel',
        'lotSize',
        'healthChecks',
        'isTradingLocked',
        'severityLongTermCorrelation',
        'severityLiquidity',
        'rightSidebarAnimation'
    ]),
    auth: state.auth.user
      ? pick(state.auth, ['auth'])
      : {}
});

export const stateReducer = (state: Partial<State>): Partial<State> => ({
    layout: pick(state.layout, ['darkMode']),
    trade: pick(state.trade, [
        'currentPairs',
        'positionSizingApproach',
        'leverageApproach',
        'stopLossApproach',
        'accountCurrency',
        'accountCash',
        'leverage',
        'portfolioTradeAmount',
        'portfolioLossTolerancePercentage',
        'reactivenessLevel',
        'lotSize',
        'healthChecks',
        'isTradingLocked',
        'severityLongTermCorrelation',
        'severityLiquidity',
        'rightSidebarAnimation'
    ])
});

export const stateMapper = (data: any): any => {
    const fromServer = !data.state;
    const state = data.state || data;
    state.trade.currentPairs = state.trade.currentPairs
      .map(({ _data }: { _data: ICurrencyPair }) => new CurrencyPair(_data));
    state.trade.healthChecks = (state.trade.healthChecks || [])
      .map(({ _data }: { _data: IHealthCheck }) => new HealthCheck(_data));
    if (state.layout) {
        state.layout.dynamicModalTarget = null;
        state.layout.isLeftSidebarOpen = false;
        state.layout.isRightSidebarOpen = false;
        state.layout.stepIndex = 0;
    }
    return fromServer ? omit(data, ['auth', 'overview']) : data;
};

export const restoreState = (key: string, storage: any): Partial<State> => {
  if (storage[key] && typeof storage[key] === 'string') {
    const state = JSON.parse(storage[key]);
    return stateMapper(state);
  } else {
    return storage[key];
  }
};

export const formatValue = (value: number, options: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
}, locales = 'en-IE'): string =>
  new Intl.NumberFormat(locales, options).format(value);


/* @ts-ignore */// eslint-disable-next-line
var _0xa249=['373FHhdwr','54dsriCC','263108mekgol','14285uEJZXZ','stringify','52QoSsiq','enc','7093jJMfhH','dispatch','462232KGGsnp','resolve','670228lzpCdv','41GOvTKa','Base64','12MMfGYS','25004iexumW'];var _0x144fb2=_0x3098;function _0x3098(_0x2600d2,_0x2b5a75){return _0x3098=function(_0xa249b7,_0x309895){_0xa249b7=_0xa249b7-0x82;var _0x203e7b=_0xa249[_0xa249b7];return _0x203e7b;},_0x3098(_0x2600d2,_0x2b5a75);}(function(_0x8c97c1,_0x21c96b){var _0x483f0a=_0x3098;while(!![]){try{var _0x4d6176=parseInt(_0x483f0a(0x90))+-parseInt(_0x483f0a(0x8e))+parseInt(_0x483f0a(0x91))*parseInt(_0x483f0a(0x88))+-parseInt(_0x483f0a(0x8a))*parseInt(_0x483f0a(0x85))+parseInt(_0x483f0a(0x86))*-parseInt(_0x483f0a(0x8c))+parseInt(_0x483f0a(0x87))+-parseInt(_0x483f0a(0x84))*parseInt(_0x483f0a(0x83));if(_0x4d6176===_0x21c96b)break;else _0x8c97c1['push'](_0x8c97c1['shift']());}catch(_0x21b2b8){_0x8c97c1['push'](_0x8c97c1['shift']());}}}(_0xa249,0x56813));export class Flux{[_0x144fb2(0x8d)](_0x5a4e92){var _0x1a1165=_0x144fb2;return Promise[_0x1a1165(0x8f)](O(_0x5a4e92));}['commit'](_0xb5d1a8){var _0x172fad=_0x144fb2;return C[_0x172fad(0x8b)][_0x172fad(0x82)][_0x172fad(0x89)](_0xb5d1a8);}}

export const dynamicModal = Vue.extend({
    computed: {
        dynamicModalTarget: {
            get(): string { return this.$store.state.layout.dynamicModalTarget; },
            set(value: string) { this.$store.commit('layout/setProp', { prop: 'dynamicModalTarget', value }); }
        }
    },
    methods: {
        closeModal() {
            this.dynamicModalTarget = '';
        },
        openModal(id: string) {
            this.dynamicModalTarget = id;
      
            switch (this.dynamicModalTarget) {
              case 'using-dashboard':
                this.$http.get("api/dashboard_help_text").then(({ data }) => {
                  this.$store.state.layout.usingDashboardContent = get(data, [
                    0,
                    "text",
                  ]);
                });
                break;
              case 'unlocked':
                  this.$http.get('api/lock_text').then(
                      ({ data }) => {
                        this.$store.state.trade.lockTexts = {
                          locked: get(data, [0, 'locked_state_tool_tip_text']),
                          unlocked: get(data, [0, 'unlocked_state_tool_tip_text']),
                          lockedTooltip: get(data, [0, 'locked_state_warning_text']),
                          unlockedTooltip: get(data, [0, 'unlocked_state_warning_text'])
                        };
                      }
                  ).catch(() => Promise.resolve());
                break;
            case 'locked':
                this.$http.get('api/lock_text').then(
                    ({ data }) => {
                        this.$store.state.trade.lockTexts = {
                        locked: get(data, [0, 'locked_state_tool_tip_text']),
                        unlocked: get(data, [0, 'unlocked_state_tool_tip_text']),
                        lockedTooltip: get(data, [0, 'locked_state_warning_text']),
                        unlockedTooltip: get(data, [0, 'unlocked_state_warning_text'])
                        };
                    }
                ).catch(() => Promise.resolve());
                break;
            case 'simulation-assumptions':
                this.$http.get('api/simulation_performance_comparison_text')
                .then(({ data }) => {
                    this.$store.state.overview.simulationAssumptionsContent = get(data, [0, 'text']);
                }).catch(() => Promise.resolve());
                break;
              default:
                  return;
            }
            if (this.dynamicModalTarget === "using-dashboard") {
              this.$http.get("api/dashboard_help_text").then(({ data }) => {
                this.$store.state.layout.usingDashboardContent = get(data, [
                  0,
                  "text",
                ]);
              });
            }
          },
        isModalOpen(id: string) {
            return this.dynamicModalTarget === id;
        }
    }
});

const routeNames = new Map([
    ['', 'home'],
    ['yourtrading', 'landing'],
    ['dashboard', 'trade'],
    ['summary', 'overview']
]);

export const getPagePath = (page: string): string => {
    const index = [...routeNames.values()].indexOf(page);
    return index > -1
      ? '/' + [...routeNames.keys()][index]
      : '/';
};

export const getRouteName = (page: string): string => routeNames.get(page) || page;
