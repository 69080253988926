import { DirectiveBinding } from "vue/types/options";
import { $store } from "@/main";

const handleLock = (e: MouseEvent) => {
  if (e.target instanceof HTMLElement && e.target.classList.contains(('trade-locked'))) {
    $store.dispatch('trade/lockTick');
  }
};

export const Locked = {
  bind(el: HTMLElement): void {
    el.addEventListener('click', handleLock);
  },
  update(el: HTMLElement, binding: DirectiveBinding): void {
    const { value } = binding;
    el.classList[value ? 'add' : 'remove']('trade-locked');
  },
  unbind(el: HTMLElement): void {
    el.removeEventListener('click', handleLock);
  }
};
