






































import Vue from 'vue';
import { get } from 'lodash-es';
import Separator from "@/components/shared/Separator.vue";
interface UserChoice {
  id:number;
  heading: string;
  options: {
    [key:string] : string;
  }[];
  tooltip:string;
  state: string;
  mutation: string;
}
export default Vue.extend({
  name: "Question",
  components: { Separator },
  props: {
    store: {
      type: Object as () => { state: string; mutation: string },
      default: () => ({ state: '', mutation: '' })
    },
    questions: {
      type: Array as () => UserChoice[],
      default: () => []
    }
  },
  computed: {
    value(): (state: string) => number {
      return (state: string) => get(this.$store.state, state);
    }
  },
  methods: {
    get,
    updateValue(mutation: string, value: number) {
      this.$store.commit(mutation, value);
    },
    getQuestionVisibility(index: number) {
      return this.questions
        .filter((q, i) => i < index)
        .every(q => typeof get(this.$store.state, q.state) === 'number');
    }
  }
});
