































































































import { responsiveness } from "@/responsiveness";
import { CurrencyPair } from "@/types";
import { mapState } from "vuex";
import { dynamicModal } from "@/helpers";
import { getWarningColor } from '@/helpers';
import TradeLocked from "@/components/shared/TradeLocked.vue";
export default dynamicModal.extend({
  name: "TradeCard",
  components: { TradeLocked },
  props: {
    pair: {
      type: Object as () => CurrencyPair,
      required: true
    }
  },
  data: () => ({
    hideControls: true,
  }),
  computed: {
    ...mapState('trade', ['lotSize', 'highlightedSeverity', 'highlightedPairs', 'isTradingLocked']),
    positionSizeFooter() {
      return this.lotSize === 1 ? responsiveness.lgTDown ? 'Base currency amt' : 'Base currency amount' : 'Base currency lots';
    },
    getWarningColor(): string {
      return getWarningColor(this.highlightedSeverity);
    },
    isHighlighted(): boolean {
      return (this.highlightedPairs || []).includes(this.pair.base + this.pair.quote);
    },
    responsiveness: () => responsiveness,
  },
  watch: {
    pair: {
      async handler() {
        this.$store.dispatch('auth/saveUserState'); 
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    togglePairVisibility() {
      this.pair.isVisible = !this.pair.isVisible;
    },
    removeCurrencyPair() {
      this.$store.commit('trade/removeCurrencyPair', this.pair.id);
      this.$store.dispatch('auth/saveUserState'); 
    },
    addEntryPrice() {
      this.$store.commit('trade/setProp', {
        prop: 'editingPairId',
        value: this.pair.id
      });
      this.$store.dispatch('auth/saveUserState'); 
      this.openModal('set-entry-price');
    },
    toggleControls() {
      this.hideControls = !this.hideControls;
    }
  }
});
