










































































import Vue from "vue";
import SsDetail from "@/components/overview/SsDetail.vue";
import { mapState } from "vuex";
import SsDashboard from '@/components/overview/SsDashboard.vue';
import SsCorrelationTable from "@/components/overview/SsCorrelationTable.vue";
import SsOvertrading from "@/components/overview/SsOvertrading.vue";
import GridBox from "@/components/shared/GridBox.vue";
import { ScreenshotDetail } from "@/types";
import { get } from 'lodash-es';
import MarkdownIt from "markdown-it";
import MarkdownItAttrs from "markdown-it-attrs";

export default Vue.extend({
  name: "DashboardPreview",
  components: {
    GridBox,
    SsCorrelationTable,
    SsDetail,
    SsDashboard,
    SsOvertrading
  },
  data: () => ({
    positions: [{
      x: 110,
      y: 55
    }]
  }),
  computed: {
    ...mapState('overview', ['activeSsDetailIndex', 'ssHeading']),
    ssDetails(): ScreenshotDetail[] {
      return this.$store.state.overview.ssDetails;
    },
    leftCol(): ScreenshotDetail[] {
      return this.ssDetails
          .filter(({ align }) => align === 'right');
    },
    rightCol(): ScreenshotDetail[] {
      return this.ssDetails
          .filter(({ align }) => align === 'left');
    },
    bld(): ScreenshotDetail | null {
      return this.ssDetails.find(({ align }) => align === 'bottom-left') || null;
    },
    brd(): ScreenshotDetail | null {
      return this.ssDetails.find(({ align }) => align === 'bottom-right') || null;
    },
    title(): string {
      return '# ' + get(this.ssHeading, 'section_heading') || '';
    },
    subTitle(): string {
      return (get(this.ssHeading, 'section_text') || '') + '{.lead}';
    }
  },
  mounted() {
    ((this.$refs.md || []) as unknown as MarkdownIt[]).forEach(el => {
      if (typeof el.use === 'function') {
        el.use(MarkdownItAttrs);
      }
    });
  },
  methods: {
    setActiveDetail(value: number | null) {
      this.$store.commit('overview/setProp', {
        prop: 'activeSsDetailIndex',
        value
      });
    }
  }
});
