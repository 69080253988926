


















import Vue from "vue";
import { HealthCheck } from "@/types";
import { getWarningColor } from "@/helpers";
import { mapState } from "vuex";

export default Vue.extend({
  name: "LiquidityTable",
  props: {
    healthCheck: {
      type: Object as () => HealthCheck,
      required: true
    }
  },
  computed: {
    ...mapState('layout', ['darkMode'])
  },
  methods: {
    getWarningColor
  }
});
