





































import Vue from 'vue';
import CorrelationChart from "@/components/shared/CorrelationChart.vue";
import CorrelationMatrix from "@/components/shared/CorrelationMatrix.vue";

export default Vue.extend({
  name: 'CorrelationsModal',
  components: { CorrelationChart, CorrelationMatrix }
});
