export interface ICurrencyPair {
    quote: string;
    base: string;
    id?: string;
    liquidity_rank?: number;
    category?: string;
    currency_pair?: string;
    stopLossDistance: number | null;
    positionSize: number | null;
    isVisible: boolean;
    entryPrice: number | null;
    isSelling?: boolean;
}

export class CurrencyPair implements ICurrencyPair {
    public _data!: ICurrencyPair;

    constructor(data: CurrencyPair | ICurrencyPair) {
        if (data instanceof CurrencyPair) {
            return data;
        }
        Object.assign(this, {
            _data: {
                ...data,
                entryPrice: 'entryPrice' in data ? data.entryPrice: null,
                positionSize: data.positionSize || null,
                stopLossDistance: data.stopLossDistance || null,
                isVisible: 'isVisible' in data ? data.isVisible : true
            }
        });
    }

    get id(): string {
        return `${this.base}${this.quote}`;
    }

    get quote(): string {
        return this._data.quote;
    }

    set quote(val: string) {
        this._data.quote = val;
    }

    get base(): string {
        return this._data.base;
    }

    set base(val: string) {
        this._data.base = val;
    }

    get rank(): number {
        return Number(this._data.liquidity_rank) || 100;
    }

    get category(): string | undefined {
        return this._data.category;
    }

    get stopLossDistance(): null | number {
        return this.isVisible ? this._data.stopLossDistance ?? null : null;
    }

    set stopLossDistance(val: number | null) {
        this._data.stopLossDistance = val;
    }

    get positionSize(): null | number {
        return this.isVisible ? this._data.positionSize ?? null : null;
    }

    set positionSize(val: number | null) {
        this._data.positionSize = val;
    }

    get isVisible(): boolean {
        return this._data.isVisible;
    }

    set isVisible(val: boolean) {
        this._data.isVisible = val;
    }

    get data(): ICurrencyPair {
        return this._data;
    }

    get isSelling(): boolean {
        return this._data.isSelling || false;
    }

    set isSelling(val: boolean) {
        this._data.isSelling = val;
    }

    get entryPrice(): number | null {
        return this._data.entryPrice;
    }

    set entryPrice(val: number | null) {
        this._data.entryPrice = val;
    }

    get stopLossPrice(): number | null {
        return this.entryPrice
          ? this.entryPrice + (this.isSelling ? +(this.stopLossDistance || 0): -(this.stopLossDistance || 0))
          : null;
    }

    unlock(): void {
        this.entryPrice = null;
    }
}
