import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import BootstrapVue, { IconsPlugin } from "bootstrap-vue";
import EventBus from 'vue-bus-ts';
import VueResizeObserver from 'vue-resize-observer';
import VuePortal from 'portal-vue';
import VueApexCharts from "vue-apexcharts";
import VueSlider from 'vue-slider-component';
import VShowSlide from 'v-show-slide';
import { i18n } from "@/i18n-setup";
import VueTheMask from 'vue-the-mask';
import Axios from 'vue-axios';
import axios from 'axios';
import setupHttp from './httpConfig';
// import { LoaderPlugin } from 'vue-google-login';
import MarkdownLight from "markdown-it-vue/dist/markdown-it-vue-light.umd.min.js";
import DatePicker from 'vue2-datepicker';

import 'vue-slider-component/theme/default.css';
import { Locked } from './directives';

Vue.config.productionTip = false;

const bus = new EventBus.Bus();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(EventBus);
Vue.use(VuePortal);
Vue.use(VueResizeObserver);
Vue.use(VueApexCharts);
Vue.use(VShowSlide);
Vue.use(VueTheMask);
Vue.use(Axios, axios);
// Vue.use(LoaderPlugin);
Vue.use(DatePicker);

Vue.component('apex-chart', VueApexCharts);
Vue.component('vue-slider', VueSlider);
Vue.component('markdown-light', MarkdownLight);
Vue.filter('numeric', function(value: number)  {
  return value === null ? null : new Intl.NumberFormat('en-IE', {
    maximumFractionDigits: 20
  }).format(value);
});
Vue.directive('locked', Locked);

const Instance = new Vue({
  i18n,
  router,
  store,
  bus,
  created() {
    setupHttp(axios, this.$store);
  },
  render: h => h(App)
}).$mount('#app');

export const { $store, $http, $router } = Instance;

export default Instance;
