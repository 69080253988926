














import Vue from 'vue';
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import AnimatedBar from "@/components/shared/AnimatedBar.vue";
export default Vue.extend({
  name: "OverallScore",
  components: {
    AnimatedNumber,
    AnimatedBar
  },
  props: {
    score: {
      type: Number,
      default: 0
    },
    scoreColor: {
      type: String,
      default: 'inherit'
    },
    barColor: {
      type: String,
      default: 'var(--text)'
    },
    title: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    localScore: 0
  }),
  watch: {
    score: {
      handler(val) {
        setTimeout(() => {
          this.localScore = val;
        }, 300);
      },
      immediate: true
    }
  }
});
