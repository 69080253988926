









































































import Vue from "vue";
import { v4 } from "uuid";
import { mapState } from "vuex";

export default Vue.extend({
  name: "SsOvertrading",
  data: () => ({
    clip0: v4(),
    image0: v4(),
    mask0: v4(),
    pattern0: v4(),
    pattern1: v4()
  }),
  computed: {
    ...mapState('overview', ['activeSsDetailIndex']),
    isActive(): boolean {
      return this.activeSsDetailIndex === 7;
    }
  }
});
