import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { loadLanguageAsync } from "@/i18n-setup";
import { getRouteName } from "@/helpers";
import store from "@/store";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import ResetPassword from "@/views/Auth/ResetPassword.vue";
import Landing from "@/views/Landing.vue";
import Overview from "@/views/Overview.vue";
import Trade from "@/views/Trade.vue";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Safeguards against broken links from existing documentation
  {
    path: "/forgot-password",
    name: "Raven Trading - Forgot password",
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset_password",
    name: "Raven Trading - Reset password",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "Raven Trading",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Trade,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/summary",
    name: "Summary",
    component: Overview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/yourtrading",
    name: "Your trading",
    component: Landing,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/docs",
    name: "Documentation",
    component: () => import(/* webpackChunkName: "docs" */ "@/views/Docs.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const summary = from.path === "/yourtrading";
  const home = from.path === "/" && to.path === "/";
  const isLoggedIn =
    store?.state?.auth?.auth !== null &&
    store?.state?.auth?.auth?.username !== "noreply@raventrading.io";

  //@ts-ignore
  if(Object.keys(to.meta).length === 0){
    if (!isLoggedIn) {
      const lang = to.params.lang || "en";
      loadLanguageAsync(lang).then(() => {
        next({ path: "/" });
      });
    } else {
      const lang = to.params.lang || "en";
      loadLanguageAsync(lang).then(() => {
        next({ path: "/dashboard" });
      });
    }
    return;
  }
  if (home && isLoggedIn) {
    const lang = to.params.lang || "en";
    loadLanguageAsync(lang).then(() => {
      next({ path: "/dashboard" });
    });
    return;
  }
  //@ts-ignore
  if (to.meta.requiresAuth && !summary) {
    if (!isLoggedIn) {
      const lang = to.params.lang || "en";
      loadLanguageAsync(lang).then(() => {
        next({ path: "/" });
      });
    } else {
      const lang = to.params.lang || "en";
      loadLanguageAsync(lang).then(() => {
        next();
      });
    }
  } else {
    const lang = to.params.lang || "en";
    loadLanguageAsync(lang).then(() => {
      next();
    });
  }
});

export default router;
