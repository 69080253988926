






















import Vue from 'vue';
import { CurrencyPair } from "@/types";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
  name: "CurrencyPairCard",
  props: {
    pair: {
      type: Object as () => CurrencyPair,
      required: true
    }
  },
  data:() => ({
    pairName: undefined as any,
  }),
  computed:{
     ...mapState('trade', ['apiCurrencies']),
     ...mapGetters('auth', ['isLoggedIn']),
  },
  watch: {
    pair: {
      async handler(val) {
        const apiCurrencies = await this.apiCurrencies;
        if(apiCurrencies.length){
          this.pairName = {
            base : await apiCurrencies.filter((o:any )=> o.code === val.base).map((o: any)=> o)[0].name  ,
            quote : await apiCurrencies.filter((o:any )=> o.code === val.quote).map((o: any)=> o)[0].name 
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    removeCurrencyPair() {
      this.$store.commit('trade/removeCurrencyPair', this.pair.id);
      this.$store.dispatch('auth/saveUserState'); 
    }
  }
});
