

















































































import { Power2, TweenMax } from "gsap";
import { mapGetters } from "vuex";
import { debounce } from 'lodash-es';
import { payloads } from "@/helpers";
import { HealthCheck, IHealthCheckPayload } from "@/types";
import Vue from "vue";
import GridBox from "@/components/shared/GridBox.vue";

interface RadarAxis {
  name: string;
  prefix?: string;
  value: number;
  arrow: number;
  opacity: number;
}

export default Vue.extend({
  name: "RadarChart",
  components: { GridBox },
  props: {
    interactive: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      levels: [0, 2, 4, 6, 8, 10],
      axes: [] as RadarAxis[],
      cx: 300,
      cy: 270,
      chartScale: 1.3,
      hasValues: false,
      originOffset: 30,
      debounceUpdate: debounce(() => {
        (this as any).updateValues();
      }, 500)
    };
  },
  computed: {
    ...mapGetters('trade', ['payload', 'severityColor', 'radarHealthChecks', 'weightedScore', 'currentPairs']),
    healthCheckValues(): (number | null)[] {
      return (this.radarHealthChecks as HealthCheck[])
          .map(hc => hc.score);
    },
    axesValues(): RadarAxis[] {
      return payloads.map((p: IHealthCheckPayload, i) => ({
        ...(this.axes[i] || {
          value: 0, arrow: 0, opacity: 0
        }),
        name: p.title,
        id: p.prefix
      }));
    },
    scaledLevels(): number[] {
      return this.levels.map(l => l * this.levelRatio);
    },
    levelRatio(): number {
      return this.chartScale * 100 / this.levels[this.levels.length - 1];
    },
    pointValues(): string {
      return this.axes.map((axis, key) => ([
        this.getPointCoordinate(key, axis.value * this.chartScale),
        this.getPointCoordinate(key, axis.value * this.chartScale, false),
      ].join(','))).join(' ');
    },
    overallScore(): string {
      return isNaN(this.weightedScore) ? '--': this.weightedScore + '';
    }
  },
  watch: {
    healthCheckValues() {
      this.debounceUpdate();
    }
  },
  mounted() {
    setTimeout(() => {
      this.axes = payloads.map((p: IHealthCheckPayload) => ({
        value: 0, arrow: 0, opacity: 0, name: p.title, id: p.prefix
      }));
      setTimeout(() => {
        this.animateArrows();
      }, 456);
    }, 1234);
  },
  methods: {
    getHealthCheck(name: string): HealthCheck {
      return this.radarHealthChecks.find((hc: HealthCheck) => hc.title === name) || { score: null, weight: 0 };
    },
    getDigits(n: number): number {
      return Math.max(Math.floor(Math.log10(Math.abs(n))), 0) + 1;
    },
    getTextLabelWidth(level: number): number {
      return ((this.getDigits(level) + 1) * 5.5) + 4;
    },
    makeAxis(index: number): Record<string, string | number | Record<string, string | number>> {
      return {
        x1: this.cx,
        y1: this.cy,
        style: {
          opacity: this.axes[index]?.opacity || 0
        },
        x2: this.axes[index] ? this.getPointCoordinate(index, this.axes[index].arrow * this.chartScale) : this.cx,
        y2: this.axes[index] ? this.getPointCoordinate(index, this.axes[index].arrow * this.chartScale, false) : this.cy
      };
    },
    makeAxisLabel(index: number, xOffset = 0, yOffset = 0): Record<string, number> {
      return {
        x: this.getPointCoordinate(index, 230) - xOffset,
        y: this.getPointCoordinate(index, 230, false) - yOffset
      };
    },
    makeValueDot(index: number): Record<string, string | number> {
      return {
        cx: this.getPointCoordinate(index, this.axes[index].value * this.chartScale),
        cy: this.getPointCoordinate(index, this.axes[index].value * this.chartScale, false)
      };
    },
    animateArrows(): void {
      const subject = Object.assign({}, ...this.axesValues.map(axis => ({
        [axis.name]: axis.arrow
      })));
      setTimeout(() => {
        TweenMax.to(subject, 1, {
          ...Object.assign({}, ...this.axesValues.map(axis => ({
            [axis.name]: 100
          }))),
          ease: Power2.easeOut,
          roundProps: Object.assign({}, ...this.axesValues.map(axis => ({
            [axis.name]: 1
          }))),
          onUpdate: () => {
            this.axes = [...this.axesValues.map(axis => ({
              ...axis,
              arrow: subject[axis.name]
            }))];
          }
        });
      }, 234);

      this.axes.forEach((_, key) => {
        setTimeout(() => {
          this.axes[key].opacity = 1;
        }, (key * 100));
      });
      setTimeout(() => {
        this.hasValues = true;
        this.updateValues();
      }, 667);
    },
    updateValues(): void {
      if (this.hasValues) {
        const s = Object.assign({}, ...this.axesValues.map(axis => ({
          [axis.name]: axis.value
        })));
        TweenMax.to(s, 1, {
          ...Object.assign({}, ...this.axesValues.map(axis => ({
            [axis.name]: Number(this.getHealthCheck(axis.name as string).score) * 100
          }))),
          ease: Power2.easeOut,
          roundProps: Object.assign({}, ...this.axesValues.map(axis => ({
            [axis.name]: 1
          }))),
          onUpdate: () => {
            this.axes = [...this.axesValues.map(axis => ({
              ...axis,
              value: s[axis.name]
            }))];
          }
        });
      }
    },
    getPointCoordinate(index: number, value: number, h = true): number {
      const l = this.axes.length;
      return l
          ? (value + this.originOffset) * Math[h ? 'cos' : 'sin'](
          (360 / l * index + (l === 6
              ? 300
              : 270)) * Math.PI / 180) +
          this[`c${h ? 'x' : 'y'}` as 'cx' | 'cy']
          : this[`c${h ? 'x' : 'y'}` as 'cx' | 'cy'];
    },
    showWarnings(axis: any): void {
      this.$store.commit('layout/setFocusedHC', null);
      this.$nextTick(() => {
        this.$store.commit('layout/setFocusedHC', axis.id);
      });
    }
  }
});
