import Vue from "vue";
import Vuex from "vuex";
import LayoutStore from "@/store/layout.store";
import TradeStore from "@/store/trade.store";
import AuthStore from "@/store/auth.store";
import DocsStore from "@/store/docs.store";
import OverviewStore from "@/store/overview.store";
import VuexPersistence from "vuex-persistedstate";
import { getModule } from "vuex-module-decorators";
import {
  getPagePath,
  getRouteName,
  payloads,
  stateMapper,
  storeReducer,
} from "@/helpers";
import { IHealthCheckPayload, ApiPayload } from "@/types";
import { debounce, isEqual } from "lodash-es";
import { $http, $router } from "@/main";
import SecureLS from "secure-ls";

Vue.use(Vuex);
export const ls = new SecureLS({ isCompression: false });

const vuexLocal = VuexPersistence<any>({
  key: "raven",
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
  reducer: storeReducer,
  rehydrated: stateMapper,
});

const store = new Vuex.Store({
  modules: {
    docs: DocsStore,
    layout: LayoutStore,
    trade: TradeStore,
    auth: AuthStore,
    overview: OverviewStore,
  },
  state: {},
  actions: {
    setState({ commit }, update) {
      Object.entries(update).forEach(([key, value]) => {
        commit(`${key}/setState`, value);
      });
    },
  },
  plugins: [vuexLocal],
});

getModule(AuthStore, store);
getModule(DocsStore, store);
getModule(LayoutStore, store);
getModule(TradeStore, store);
getModule(OverviewStore, store);

const healthCheckWatcher = (type: string) => (state: State, getters: Getters) =>
  !state.trade.isLoadingData &&
  ["trade", "overview", "landing"].includes(state.layout.page) &&
  getters["trade/should_load"](type) &&
  Boolean(state.trade.currentPairs.length) &&
  getters["trade/payload"](type);

const healthCheckCallback = (payload: ApiPayload, prev: ApiPayload) =>
  payload.prefix &&
  !isEqual(payload, prev) &&
  store.dispatch("trade/healthCheck", payload);

const debounced = {
  allocations: debounce(
    () => store.dispatch("trade/loadPortfolioAllocationsAndStopLossDistances"),
    420
  ),
  correlations: debounce(() => store.dispatch("trade/loadCorrelations"), 420, {
    leading: false,
    trailing: true,
  }),
};

payloads.forEach(({ prefix }: IHealthCheckPayload) => {
  store.watch(healthCheckWatcher(prefix), healthCheckCallback);
});

store.watch(
  (state: State) => state.layout.page,
  (page) => {
    const currentPathName = getRouteName(
      ($router?.currentRoute.path || "/").substring(1)
    );
    if ($router?.currentRoute) {
      if (getRouteName(page) !== currentPathName) {
        const path = getPagePath(page);
        $router.push({ path }).then();
      }
    }
  }
);

store.watch(
  (state: State, getters: Getters) =>
    !state.trade.isLoadingData &&
    ["trade", "overview", "landing"].includes(state.layout.page) &&
    getters["trade/portfolio_allocations_and_stop_loss_distances_payload"],
  debounced.allocations
);
store.watch(
  (state: State, getters: Getters) =>
    !state.trade.isLoadingData &&
    ["trade", "overview", "landing"].includes(state.layout.page) &&
    state.trade.currentPairs.length > 2 &&
    getters["trade/currency_pairs"],
  debounced.correlations
);
store.watch(
  (state: State, getters: Getters) =>
    !state.trade.isLoadingData &&
    !state.trade.currentPairs.length &&
    getters["auth/isLoggedIn"],
  () => store.commit("trade/resetHealthChecks")
);

store.watch(
  (state: State) => state.auth.isReady,
  (val) => {
    setTimeout(
      () => {
        store.commit("trade/setIsLoadingData", !val);
      },
      val ? 2e3 : 0
    );
  }
);

// store.watch(
//   (state: State) => !state.auth.isReady && state.auth.userAccountMessage,
//   (val) => {
//     setTimeout(() => {
//     store.commit('trade/setIsLoadingData', !val);
//   }, val ? 2e3 : 0); }
// );

store.watch(
  (state: State) => state.trade.accountCurrency,
  (val) => {
    if (val) {
      const symbol = val.symbol;
      $http
        .get("api/parameter_text")
        .then(({ data }) => {
          const value = { value: data[0], symbol: symbol };
          store.commit("trade/setDefaultValues", value);
          return Promise.resolve();
        })
        .catch(() => Promise.resolve());
    }
  }
);

store.watch(
  (state: State) => state.trade.isLoadingData,
  (val) => {
    if (!val) {
      if ($router?.currentRoute.path === "/yourtrading") {
        store.commit("layout/setPage", "landing");
        return;
      }
      if ($router?.currentRoute.path === "/dashboard") {
        store.commit("layout/setPage", "trade");
        return;
      }

      if ($router?.currentRoute.path === "/") {
        store.commit("layout/setPage", "home");
        return;
      }
      if ($router?.currentRoute.path === "/summary") {
        store.commit("layout/setPage", "overview");
        return;
      }
    }
  }
);

store.watch(
  (state, getters: Getters) => getters["auth/username"],
  (username: string | undefined) => {
    if (username && username !== "noreply@raventrading.io") {
      $http.put(`api/login/${username}`).catch(() => Promise.resolve());
    }
  }
);

store.watch(
  (state: State) => state.auth.selectedUser,
  (val: string) => {
    store.commit("trade/setProp", { prop: "isLoadingData", value: true });
    store
      .dispatch("auth/loadUserSettings", val)
      .then(() =>
        store.commit("trade/setProp", { prop: "isLoadingData", value: false })
      );
  }
);

export type State = typeof store.state;
export type Getters = typeof store.getters;
export default store;
