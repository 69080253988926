























import Vue from 'vue';

export default Vue.extend({
  name: "CardScroller",
  props: {
    hasScrollbar: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ''
    },
    isModalContent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isOverflown: false
  }),
  watch: {
    content: {
      handler() {
        this.checkForMore();
      }
    }
  },
  mounted() {
    this.checkForMore();
  },
  methods: {
    checkForMore() {
      if (window.outerWidth > 992 && this.$el && !this.isModalContent && !this.$el.classList.contains('ps')) {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.isOverflown = this.$el.scrollHeight > this.$el.clientHeight;
          });
        });
      } else {
        this.isOverflown = false;
      }
    }
  }
});
