


























import { responsiveness } from "@/responsiveness";
import { dynamicModal } from "@/helpers";

export default dynamicModal.extend({
  name: "RavenLogo",
  computed: {
    responsiveness: () => responsiveness,
  },
});
