import { Module, Mutation, Action, VuexModule, MutationAction } from "vuex-module-decorators";
import { sortBy, pick, isEqual } from "lodash-es";
import {
  ApiPayload,
  ICorrelationRange,
  ICorrelationRecord,
  IHealthCheckPayload,
  CurrencyPair,
  ICurrencyPair,
  HealthCheck,
  LockTexts,
  Parameters,
  Currency
} from "@/types";
import { $store, $http } from "@/main";
import { ActionContext } from "vuex";
import { State } from "@/store/index";
import { correlationColors, payloads } from "@/helpers";
import { v4 } from "uuid";
import Vue from "vue";



export interface DragResult {
  addedIndex: number;
  removedIndex: number;
  payload: CurrencyPair;
}

export type TradeProp =
  'tradeDate'
  | 'startTradeDate'
  | 'endTradeDate'
  | 'accountCurrency'
  | 'accountCash'
  | 'portfolioTradeAmount'
  | 'portfolioLossTolerancePercentage'
  | 'reactivenessLevel'
  | 'lotSize'
  | 'stopLossApproach'
  | 'positionSizingApproach'
  | 'leverageApproach'
  | 'severityLongTermCorrelation'
  | 'severityLiquidity'
  | 'rightSidebarAnimation';

interface PropPayload {
  prop: TradeProp;
  value: any;
}



@Module({ namespaced: true, store: $store, name: 'trade' })
class TradeStore extends VuexModule {
  currentPairs: CurrencyPair[] = [];
  allPairs: CurrencyPair[] = [];
  apiCurrencies: Currency[] = [];
  editingPairId = '';
  correlationPair = {} as { base: string; quote: string };
  correlationRanges: ICorrelationRange[] = [];
  severityRanges: ICorrelationRange[] = [];
  healthChecks: HealthCheck[] = [];
  longTermCorrelations: ICorrelationRecord[] = [];
  isLoadingData = true;
  lockTexts = {} as LockTexts;
  lockTicks: string[] = [];

  isTradingLocked = false;
  positionSizingApproach = undefined;
  leverageApproach = undefined;
  stopLossApproach = undefined;
  tradeDate = undefined;
  startTradeDate = undefined;
  endTradeDate = undefined;
  accountCurrency = undefined;
  accountCash = undefined;
  leverage = undefined;
  portfolioTradeAmount = undefined;
  portfolioLossTolerancePercentage = undefined;
  reactivenessLevel = undefined;
  lotSize = undefined;
  defaultValues = {};
  defaultControls = {};
  selectedValue = '';
  highlightedPairs: string[] = [];
  highlightedSeverity: number | null = null;
  severityLongTermCorrelation = undefined;
  severityLiquidity  = undefined;
  rightSidebarAnimation =  false;

  get severityColor(): (n: number | null) => string {
    (risk: number | null) => {};
    return (risk: number | null) => typeof risk === 'number' ?
    this.severityRanges.find(
        ({ from, to }) => from <= Math.round(risk * 100) / 100 && (risk === 1 ? to >= Math.round(risk * 100) / 100 : to > Math.round(risk * 100) / 100 )
      )?.color || 'var(--muted)'
      : 'var(--muted)';
  }

  get allCurrencies(): Currency[] {
    return sortBy(this.apiCurrencies, 'category_id');
  }

  get orderedCurrencies(): string[] {  
    return this.allCurrencies.map(({ code }) => code);
  }

  get overallLiquidityScore(): number {
    return Math.round(100 * (this.currentPairs.length
      ? this.currentPairs
      .map(({ rank }) => 100 - rank)
      .reduce((a, b) => a + b, 0) / this.currentPairs.length
      : 100)) / 100;
  }

  get apiProp(): any {
    return (prop: TradeProp) => this[prop] !== null
      ? this[prop]
      : undefined;
  }

  get radarHealthChecks(): HealthCheck[] {
    return sortBy(this.healthChecks, 'index');
  }

  get orderedHealthChecks(): HealthCheck[] {
    return sortBy(this.healthChecks, 'position');
  }

  get overviewHealthChecks(): (HealthCheck | string)[] {
    return sortBy(payloads, 'overviewIndex').map(({ prefix, title }) =>
      this.healthChecks.find(({ id }) => id === prefix) || title
    );
  }

  get weightedScore(): number {
    return this.healthChecks.length
      ? this.healthChecks.length === 6
        ? Math.round(this.healthChecks.map(({ weightedScore }) => weightedScore).reduce((a, b) => a + b, 0) * 1e2)
        : Math.round(this.healthChecks.map(({ score, weight }) => Number(score) * weight).reduce((a, b) => a + b, 0) * 1e2
          / this.healthChecks.map(({ weight }) => weight).reduce((a, b) => a + b, 0))
      : 0;
  }

  // API params:

  get trade_date(): string | undefined {
    return this.tradeDate || undefined;
  }

  get start_trade_date(): Date | undefined {
    return undefined;
    // return this.apiProp('startTradeDate').toJSON().substring(0,10);
  }

  get end_trade_date(): Date | undefined {
    return undefined;
    // return this.apiProp('endTradeDate').toJSON().substring(0,10);
  }

  get account_currency():  string | undefined {
    return this.apiProp('accountCurrency') ? typeof this.apiProp('accountCurrency') === 'object' ? this.apiProp('accountCurrency').code : this.apiProp('accountCurrency') : (this.defaultControls as any).accountCurrency.code;
  }

  get cash_in_account_currency(): number {
    return this.apiProp('accountCash') >= 0  ? +this.apiProp('accountCash') : +(this.defaultControls as any).accountCash;
  }

  get total_portfolio_trade_amount_in_account_currency(): number | undefined {
    return  this.accountCash! >= 0 && this.leverage! >= 0 ? Math.max(Math.round(this.accountCash! * parseFloat(this.leverage + '')), 0) : (this.defaultControls! as any).leverage >= 0 ? Math.max(Math.round((this.defaultControls as any).accountCash * parseFloat((this.defaultControls as any).leverage + '')), 0) : 0;
  }

  get pltPercentage(): number {
    return this.portfolioLossTolerancePercentage! >= 0  ? +this.portfolioLossTolerancePercentage! : +(this.defaultControls as any).portfolioLossTolerancePercentage;
  }

  get maximum_portfolio_loss_tolerance_in_account_currency(): number | undefined {
    return this.accountCash! >= 0 && this.pltPercentage >= 0  ? this.accountCash! * this.pltPercentage / 100 : (this.defaultControls as any).accountCash * this.pltPercentage / 100; 
  }

  get reactiveness_level(): number | undefined {
    return this.apiProp('reactivenessLevel') ? this.apiProp('reactivenessLevel') : (this.defaultControls as any).reactivenessLevel  ;
  }

  get lot_size(): number | undefined {
    return this.apiProp('lotSize') ? this.apiProp('lotSize') : (this.defaultControls as any).lotSize;
  }

  get stop_loss_approach(): number | undefined {
    return this.context.rootState.layout.page === 'trade'
      ? undefined
      : this.apiProp('stopLossApproach');
  }

  get position_sizing_approach(): number | undefined {
    return this.context.rootState.layout.page === 'trade'
      ? undefined
      : this.apiProp('positionSizingApproach');
  }

  get leverage_approach(): number | undefined {
    return this.context.rootState.layout.page === 'trade'
      ? undefined
      : this.apiProp('leverageApproach');
  }

  get currency_pairs(): string | undefined {
    return this.sortedPairs.length
      ? `[${this.sortedPairs}]`
      : undefined;
  }

  get sortedPairs(): string {
    return sortBy(this.currentPairs
      .filter(pair => pair.isVisible)
      .map(({ base, quote }) => `${base}${quote}`)
    ).join(',');
  }

  get healthWarnings(): any[] {
    return [...this.healthChecks.map(({ warnings }) => warnings)].flat();
  }

  get liquidity_payload(): IHealthCheckPayload {
    return {
      ...payloads.find(({ prefix }) => prefix === 'liquidity') as IHealthCheckPayload,
      params: {
        currency_pairs: this.currency_pairs
      }
    };
  }

  get liquidity_condition(): boolean {
    return Boolean(this.currentPairs.length) && ['landing','overview', 'trade']
        .includes(this.context.rootState.layout.page);
  }

  get long_term_correlation_payload(): IHealthCheckPayload {
    return {
      ...payloads.find(({ prefix }) => prefix === 'long_term_correlation') as IHealthCheckPayload,
      params: {
        currency_pairs: this.currency_pairs
      }
    };
  }

  get long_term_correlation_condition(): boolean {
    return Boolean(this.currentPairs.length) && ['landing', 'overview', 'trade']
        .includes(this.context.rootState.layout.page);
  }

  get position_sizing_approach_payload(): IHealthCheckPayload {
    return {
      ...payloads.find(p => p.prefix === 'position_sizing_approach') as IHealthCheckPayload,
      params: {
        leverage_approach: this.leverage_approach,
        relative_position_sizing_approach: this.position_sizing_approach
      }
    };
  }

  get position_sizing_approach_condition(): boolean {
    return (['overview', 'trade']
        .includes(this.context.rootState.layout.page) ||
        (
          this.context.rootState.layout.page === 'landing' &&
          (this.context.rootState.layout.stepIndex > 0) &&
          typeof this.positionSizingApproach === 'number'
        ));
  }

  get stop_loss_approach_payload(): IHealthCheckPayload {
    return {
      ...payloads.find(p => p.prefix === 'stop_loss_approach') as IHealthCheckPayload,
      params: {
        stop_loss_approach: this.stop_loss_approach
      }
    };
  }

  get stop_loss_approach_condition(): boolean {
    return (['overview', 'trade'].includes(this.context.rootState.layout.page) ||
        (
          this.context.rootState.layout.page === 'landing' &&
          (this.context.rootState.layout.stepIndex > 1) &&
          typeof this.stopLossApproach === 'number'
        ));
  }

  get diversification_payload(): IHealthCheckPayload {
    return {
      ...payloads.find(p => p.prefix === 'diversification') as IHealthCheckPayload,
      params: {
        currency_pairs: this.currency_pairs
      }
    };
  }

  get diversification_condition(): boolean {
    return Boolean(this.currentPairs.length) &&
      ['landing', 'overview', 'trade'].includes(this.context.rootState.layout.page);
  }

  get over_trading_payload(): Record<string, any> {
    return {
      ...payloads.find(p => p.prefix === 'over_trading') as IHealthCheckPayload,
      params: pick(this, [
        'trade_date',
        'account_currency',
        'cash_in_account_currency',
        'total_portfolio_trade_amount_in_account_currency',
        'maximum_portfolio_loss_tolerance_in_account_currency',
        'reactiveness_level',
        'lot_size',
        'currency_pairs'
      ])
    };
  }

  get over_trading_condition(): boolean {
    return ['overview', 'trade'].includes(this.context.rootState.layout.page) ||
      (
        (this.context.rootState.layout.page === 'landing') &&
        (this.context.rootState.layout.stepIndex > 2)
      );
  }

  get portfolio_allocations_and_stop_loss_distances_payload(): Record<string, any> {
    return {
      params: pick(this, [
        'trade_date',
        'account_currency',
        'total_portfolio_trade_amount_in_account_currency',
        'maximum_portfolio_loss_tolerance_in_account_currency',
        'reactiveness_level',
        'lot_size',
        'currency_pairs',
      ])
    };
  }

  get payload(): (id: string) => ApiPayload {
    return (id: string) => this.context.getters[`${id}_payload`];
  }

  get should_load(): (id: string) => boolean {
    return (id: string) => this.context.getters[`${id}_condition`];
  }

  @Action
  loadPortfolioAllocationsAndStopLossDistances(): Promise<void> {
    return this.context.rootState.auth.isReady
      ? $http.get(
        'api/portfolio_allocations_and_stop_loss_distances',
        this.portfolio_allocations_and_stop_loss_distances_payload
      ).then(r => {
        if (r.data) {
          this.updatePositionSizes(r.data);
        }
      }).catch(() => Promise.resolve())
      : Promise.resolve();
  }

  @Action
  healthCheck({ prefix, title, position, params }: Record<string, any>): Promise<any> {
    return this.context.getters['should_load'](prefix)
      ? $http.get(`api/${prefix}_health_check`, {
        params: params
      }).then(({ data }) => {
        const d = typeof data === 'string'
          ? JSON.parse(data.replace('NaN', 'null'))
          : data;
        this.setHealthCheck(new HealthCheck({
          ...d[0],
          id: prefix,
          prefix: prefix,
          title: title,
          position: position
        }));
        return d;
      }).catch(() => Promise.resolve())
      : Promise.resolve();
  }

  @Action
  checkPortfolio(): Promise<void | any[]> {
    return this.currentPairs.length
      ? Promise.all(
        payloads.map((q: IHealthCheckPayload) => this.healthCheck(this.payload(q.prefix)))
      )
      : Promise.resolve();
  }

  @MutationAction({ mutate: ['longTermCorrelations'] })
  async loadCorrelations(): Promise<{ longTermCorrelations: ICorrelationRecord[]}> {
    const { state, getters, rootState } = this as unknown as ActionContext<TradeStore, State>;
    if (state.currentPairs.length >= 2) {
      return $http.get('api/long_term_correlations', {
        params: {
          currency_pairs: getters.currency_pairs
        }
      }).then(r => ({
        longTermCorrelations: r.data
      }))
        .catch(() => Promise.resolve({ longTermCorrelations: [] }));
    } else {
      return Promise.resolve({
        longTermCorrelations: []
      });
    }
  }

  @Mutation
  addCurrencyPair(pair: ICurrencyPair): void {
    if (!this.isTradingLocked) {
      const cp = this.allPairs.find(({ base, quote }) => base === pair.base && quote === pair.quote);
      if (cp && !this.currentPairs.includes(cp)) {
        this.currentPairs.push(cp);
      }
    }
  }

  @Mutation
  removeCurrencyPair(_id: string): void {
    if (!this.isTradingLocked) {
      this.currentPairs = this.currentPairs.filter(({ id }) => id !== _id);
    }
  }

  @Mutation
  setHealthCheck(check: HealthCheck): void {
    const index = this.healthChecks.findIndex(({ id }) => id === check.id);
    if (index > -1) {
      this.healthChecks.splice(index, 1, check);
    } else {
      this.healthChecks.push(check);
    }
  }

  @Mutation
  resetHealthChecks(): void {
    this.healthChecks = [];
  }

  @Mutation
  setLeverageApproach(val: number): void {
    (this.leverageApproach as any)= val;
  }

  @Mutation
  setPositionSizing(val: number | null): void {
    (this.positionSizingApproach as any)= val;
  }

  @Mutation
  setStopLosses(val: number ): void {
    (this.stopLossApproach as any) = val;
  }

  @Mutation
  setCorrelationRanges(val: ICorrelationRange[]): void {
    this.correlationRanges = [...val].reverse().map((c, index) => ({
      ...c,
      from: -c.to || -1,
      to: -c.from || 0,
      color: correlationColors[correlationColors.length - index - 1]
    })).concat([{
      from: 0,
      to: 0,
      name: 'None',
      color: 'transparent'
    }, ...val.map((c, i) => ({
      ...c,
      from: c.from || 0,
      color: correlationColors[i]
    }))]);
  }

  @Mutation
  setSeverityRanges(val: ICorrelationRange[]): void {
    this.severityRanges = [...val].map((c, i) => ({
      ...c,
      color: correlationColors[correlationColors.length - i - 1]
    }));
  }
  @Mutation
  setState(data: Partial<typeof TradeStore>): void {
    Object.assign(this, data);
  }

  @Mutation
  setProp({ prop, value }: PropPayload): void {
    Vue.set(this, prop, value);
  }
  @Mutation
  async setDefaultValues(val: Parameters): Promise<void> {
    if(!this.accountCurrency && this.apiCurrencies){
      this.accountCurrency = await this.apiCurrencies.filter((o:any )=> o.code === (val.value ? val.value.parameters[0].parameter_default : val.parameters[0].parameter_default)).map((o: any)=> o)[0];
    }
    if(this.accountCurrency && this.apiCurrencies && val){
      const symbolValue = val.symbol !== undefined  ? val.symbol : await this.apiCurrencies.filter((o:any )=> o.code === (this.accountCurrency as any ).code ).map((o: any)=> o)[0].symbol;
      const controls =  [
        {
          heading_text: val.value ? val.value.heading_text : val.heading_text,
          heading_tool_tip_text : val.value ? val.value.heading_tool_tip_text : val.heading_tool_tip_text,
          exclude: true,
        },
        {
          type: 'currency-selector',
          stateProp: 'accountCurrency',
          id: val.value ? val.value.parameters[0].parameter_sequence : val.parameters[0].parameter_sequence,
          label: val.value ? val.value.parameters[0].parameter_name  : val.parameters[0].parameter_name,
          tooltip: val.value ? val.value.parameters[0].parameter_tool_tip_text : val.parameters[0].parameter_tool_tip_text,
          defaultValues: await this.apiCurrencies.filter((o:any )=> o.code === (val.value ? val.value.parameters[0].parameter_default : val.parameters[0].parameter_default)).map((o: any)=> o)[0],
          exclude: false,
        }, {
          stateProp: 'accountCash',
          type: 'currency',
          id: val.value ? val.value.parameters[1].parameter_sequence : val.parameters[1].parameter_sequence,
          label: val.value ? val.value.parameters[1].parameter_name : val.parameters[1].parameter_name,
          tooltip: val.value ? val.value.parameters[1].parameter_tool_tip_text : val.parameters[1].parameter_tool_tip_text,
          mask: {
            prefix: symbolValue + ' ',
            precision: 0
          },
          defaultValues: val.value ? val.value.parameters[1].parameter_default : val.parameters[1].parameter_default,
          exclude: false,
        }, {
          stateProp: 'leverage',
          type: 'numeric',
          id: val.value ? val.value.parameters[2].parameter_sequence : val.parameters[2].parameter_sequence,
          label: val.value ? val.value.parameters[2].parameter_name : val.parameters[2].parameter_name,
          tooltip: val.value ? val.value.parameters[2].parameter_tool_tip_text : val.parameters[2].parameter_tool_tip_text,
          debounce: 300,
          prepend: '1:',
          mask: {
            validation: (v: unknown) => typeof v === 'number',
            errorMessage: 'Please enter a number'
          },
          defaultValues: val.value ? val.value.parameters[2].parameter_default :  val.parameters[2].parameter_default,
          exclude: false,
        }, {
          stateGetter: 'total_portfolio_trade_amount_in_account_currency',
          type: 'currency',
          id: val.value ? val.value.parameters[3].parameter_sequence : val.parameters[3].parameter_sequence,
          label: val.value ? val.value.parameters[3].parameter_name : val.parameters[3].parameter_name,
          tooltip: val.value ? val.value.parameters[3].parameter_tool_tip_text : val.parameters[3].parameter_tool_tip_text,
          mask: {
            prefix: symbolValue + ' ' ,
            precision: 0
          },
          defaultValues: val.value ? val.value.parameters[3].parameter_default :  val.parameters[3].parameter_default,
          exclude: false,
        }, {
          stateProp: 'portfolioLossTolerancePercentage',
          type: 'numeric',
          id: val.value ? val.value.parameters[4].parameter_sequence : val.parameters[4].parameter_sequence,
          label: val.value ? val.value.parameters[4].parameter_name : val.parameters[4].parameter_name,
          tooltip: val.value ? val.value.parameters[4].parameter_tool_tip_text : val.parameters[4].parameter_tool_tip_text,
          debounce: 300,
          config: {
            min: 0,
            tooltipFormatter: (v: number) => v.toFixed(2) + '%'
          },
          mask: {
            validation: (v: number) => v >= 0,
            errorMessage: 'Min: 0'
          },
          append: '%',
          defaultValues: val.value ? val.value.parameters[4].parameter_default : val.parameters[4].parameter_default,
          exclude: false,
        }, {
          stateGetter: 'maximum_portfolio_loss_tolerance_in_account_currency',
          type: 'currency',
          value: 20,
          id: val.value ? val.value.parameters[5].parameter_sequence : val.parameters[5].parameter_sequence,
          label: val.value ? val.value.parameters[5].parameter_name : val.parameters[5].parameter_name,
          tooltip: val.value ? val.value.parameters[5].parameter_tool_tip_text :  val.parameters[5].parameter_tool_tip_text,
          mask: {
            prefix: symbolValue + ' ',
            precision: 0
          },
          defaultValues: val.value ? val.value.parameters[5].parameter_default :  val.parameters[5].parameter_default,
          exclude: false,
        }, {
          stateProp: 'lotSize',
          type: 'options-slider',
          id: val.value ? val.value.parameters[6].parameter_sequence : val.parameters[6].parameter_sequence,
          label: val.value ? val.value.parameters[6].parameter_name : val.parameters[6].parameter_name,
          tooltip: val.value ? val.value.parameters[6].parameter_tool_tip_text : val.parameters[6].parameter_tool_tip_text,
          options: [{
            value: 1,
            label: 'Not using lots'
          }, {
            value: 100,
            label: 'Nano lots'
          }, {
            value: 1000,
            label: 'Micro lots'
          }, {
            value: 10000,
            label: 'Mini lots'
          }, {
            value: 100000,
            label: 'Standard lots'
          }],
          advanced: true,
          config: {
            formatter: (value: number) => new Intl.NumberFormat().format(value)
          },
          defaultValues: val.value ? val.value.parameters[6].parameter_default :  val.parameters[6].parameter_default,
          exclude: false,
        }, {
          stateProp: 'reactivenessLevel',
          type: 'numeric-slider',
          id: val.value ? val.value.parameters[7].parameter_sequence : val.parameters[7].parameter_sequence,
          label: val.value ? val.value.parameters[7].parameter_name : val.parameters[7].parameter_name ,
          tooltip: val.value ? val.value.parameters[7].parameter_tool_tip_text :  val.parameters[7].parameter_tool_tip_text,
          config: {
            min: 1,
            max: 10,
            interval: 1
          },
          advanced: true,
          defaultValues: val.value ? val.value.parameters[7].parameter_default :  val.parameters[7].parameter_default,
          exclude: false,
      }];
      this.defaultValues = controls;
    }
      this.defaultControls = {
        accountCurrency : await this.apiCurrencies.filter((o:any )=> o.code === (val.value ? val.value.parameters[0].parameter_default : val.parameters[0].parameter_default)).map((o: any)=> o)[0],
        accountCash : val.value ? val.value.parameters[1].parameter_default :  val.parameters[1].parameter_default,
        leverage : val.value ? val.value.parameters[2].parameter_default :  val.parameters[2].parameter_default,
        total_portfolio_trade_amount_in_account_currency : val.value ? val.value.parameters[3].parameter_default :  val.parameters[3].parameter_default,
        portfolioLossTolerancePercentage : val.value ? val.value.parameters[4].parameter_default :  val.parameters[4].parameter_default,
        maximum_portfolio_loss_tolerance_in_account_currency : val.value ? val.value.parameters[5].parameter_default :  val.parameters[5].parameter_default,
        lotSize : val.value ? val.value.parameters[6].parameter_default :  val.parameters[6].parameter_default,
        reactivenessLevel: val.value ? val.value.parameters[7].parameter_default :  val.parameters[7].parameter_default
      };
  }

  @Mutation
  setAllPairs(val: CurrencyPair[]): void {
    this.allPairs = val;

  }

  @Mutation
  setAllCurrencies(val: Currency[]): void {
    this.apiCurrencies = val;
  }

  @Mutation
  applyDrag({ removedIndex, addedIndex, payload }: DragResult): void {
    if (removedIndex === null && addedIndex === null) return;
    const result = [...this.currentPairs];
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    this.currentPairs = result;
  }

  @Mutation
  setCorrelationPair(val: { base: string; quote: string }): void {
    this.correlationPair = val;
  }

  @Mutation
  updatePositionSizes(data: Record<string, any>): void {
    data.forEach((item: any) => {
      const pair = this.currentPairs.find(({ id }) => id === item.currency_pair);
      if (pair) {
        pair.positionSize = item.allocation_in_base_currency_lots;
        pair.stopLossDistance = item.stop_loss_distance;
      }
    });
    $store.dispatch('auth/saveUserState'); 
  }

  @Mutation
  setHighlightedPairs({ pairs, severity }: { pairs: string[]; severity: number }): void {
    if (isEqual(pairs, this.highlightedPairs)) {
      this.highlightedPairs = [];
      this.highlightedSeverity = null;
    } else {
      this.highlightedPairs = pairs;
      this.highlightedSeverity = severity;
    }
  }

  @Mutation
  setIsLoadingData(value: boolean): void {
    this.isLoadingData = value;
  }

  @Mutation
  toggleLock(value: boolean): void {
    this.isTradingLocked = value;
    if (!value) {
      this.currentPairs
        .filter(p => p.entryPrice)
        .forEach(p => p.unlock());
    }
  }

  @Action
  async lockTick(): Promise<void> {
    const tick = v4();
    this.lockTicks.push(tick);
    setTimeout(() => {
      const index = this.lockTicks.findIndex(t => t === tick);
      this.lockTicks.splice(index, 1);
    }, 1500);
  }


}

export default TradeStore;
